.update-mode-modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1111;
  background: #dddddd70;
  display: flex;
  justify-content: center;
  align-items: center;
}

.update-mode-modal {
  background: white;
  padding: 0px 45px;
  border-radius: 15px;
}
@media only screen and (width <=800px) {
  .update-mode-modal {
    background: white;
    padding: 0px 0px;
    border-radius: 15px;
  }
}
