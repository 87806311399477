.main1{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper_1{
    width: 902px;
    height: 540px !important;
    background-color: #F8F9FA;
    display: flex;
    justify-content: center;
}

.content_div{
    background-color: #FFFFFF;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    /* max-height: 351px; */
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.content_div h1{
    color: #000000;
    font-weight: 700;
    font-size: 24px;
}

.content_div p {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
}

.content_div h2{
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}

.content_div h3{
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}

.btn_cls{
    position: absolute;
    bottom: 0;
    right: 45%;
  
    
}

.btn_cls button{
    width: 104px;
    height: 48px;
    background-color: #283891;
    font-weight: 600;
    color: #FFFFFF;
    font-size: 16px;
    border-radius: 8px;
    border: none;
   

}