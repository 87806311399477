.card-container {
  display: flex;
  align-items: flex-end;
}
.card-container img {
  max-width: 100%;
}
.card-container .heading {
  width: 210px;
  height: 48px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #08090a;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 1.5rem auto 0.6rem;
}
.card-container .description {
  width: 210px;
  height: 33px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 10.5px;
  line-height: 16px;
  text-align: center;
  color: #6d747a;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.card-container .line-holder {
  align-self: center;
  margin-top: -100px;
}
.line-holder img {
  width: 104.49px;
  height: 15px;
}

@media only screen and (width < 1300px) {
  .card-container .line-holder {
    display: none;
  }
}
