#investHeader {
  margin-top: 58px;
  margin-bottom: 24px;
  margin-left: 68px;
}

#investHeader h1 {
  font-size: 48px;
  font-weight: 600;
}

#investHeader h6 {
  font-size: 18px;
  font-weight: 500;
  color: #6d747a;
  line-height: 26px;
}
.Desc {
  width: 29vw;
}

@media only screen and (max-width: 1203px) {
  .Desc {
    width: 45vw;
  }
}
@media only screen and (max-width: 816px) {
  .Desc {
    width: 65vw;
  }
}
@media only screen and (max-width: 600px) {
  #investHeader h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 26px;
  }
  #investHeader h6 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    width: 90%;
    padding-top: 10px;
  }
  #investHeader {
    width: 91vw;
    margin: 58px auto;
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 445px) {
  #investHeader {
    margin-top: 32px;
    margin-bottom: 16px;
    margin-left: 13px;
  }
  #investHeader h6 {
    font-size: 14px;
    padding-top: 8px;
  }
}
