.competitors-wrapper {
  padding: 15px;
}

.competitor {
  padding: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.competitor-header {
  display: flex;
  align-items: center;
  gap: 20px;
}
.competitor-header img {
  width: 48px;
  height: 48px;
  border-radius: 30px;
}
.competitor-header p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.2px;

  color: #000000;
}
.competitor-detail {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}
.competitor-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  color: #08090A;
}
.competitor-tags span {
  padding: 4px 8px;
  background: #ced4da;
  text-transform: uppercase;
  border-radius: 4px;
  white-space: nowrap;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Blacks/Black */

  color: #08090a;
}

@media screen and (min-width: 700px) {
  #competitorsContainer h2 {
    font-size: 16px;
    font-weight: 400;
    margin: 16px 0;
  }
  #competitorsContainer b {
    font-weight: 500;
  }

  #competitorsContainer img {
    width: 48px;
    height: 48px;
  }

  .competitorName {
    display: flex;
    align-items: center;
    gap: 21px;
  }

  .competitorName h1 {
    letter-spacing: -0.2px;
    font-family: "Roboto", sans-serif;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 24px;
  }
  .competitorName img {
    border-radius: 8px;
    max-width: 48px;
  }

  .competitorDetails {
    font-size: 16px;
    font-weight: 400;
    margin-top: 8px;
  }

  .competitorDetails b {
    font-weight: 500;
  }

  .competitorTag {
    display: flex;
    margin-top: 8px;
    gap: 12px;
  }

  .tag {
    background-color: #ced4da;
    padding: 4px 8px;
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
  }

  #competitorsContainer hr {
    background-color: #ced4da;
    height: 2px;
    width: 100%;
    margin-left: 0;
  }
}
