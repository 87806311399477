/* live */

.livedeals {
  /* width: 80vw; */
  margin-left: 64px;
  padding-top: 32px;
}
.title {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
}

.sub {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #6d747a;
  padding-bottom: 24px;
}

.deals-contaniner {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  /* justify-content: space-between; */
}
@media only screen and (max-width: 708px) {
  .livedeals {
    width: 90vw;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 600px) {
  .livedeals {
    width: 78vw;
    margin: 0 auto;
  }
  .title {
    font-weight: 600;
    font-size: 24px;
  }
  .sub {
    font-weight: 500;
    font-size: 16px;
  }
}
@media only screen and (max-width: 445px) {
  .livedeals {
    width: 91vw;
    position: relative;
    /* z-index: -1; */
  }
}
