#options {
  padding: 24px;
  background-color: #f8f9fa;
  border-radius: 12px;
  padding-right: 60px;
  /* z-index: 10000; */
  position: relative;
}

#search {
  position: relative;
}

#search img {
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 1;
  left: 18px;
  top: 0;
  bottom: 0;
  margin: auto;
}

#search input {
  /* position: relative; */
  border-radius: 50px;
  padding: 10px 40px;
  /* z-index: 0; */
  border: 0;
  width: 20rem;
  height: 100%;
  padding-right: 0;
}

#search input:focus {
  border: 0;
}

#sector,
#tech,
#revenue {
  /* width: 115px; */
  background-color: #fff;
  border: none;
  border-radius: 50px;
  padding: 10px 17px;
  margin: 0 16px;
  overflow: hidden;
  /* background-image: url(./../../Assets/icons/toggle.png);
    background-repeat: no-repeat;
    background-position-x: 61%;
    background-position-y: center;
    background-size: 10px; */
  transition: all 0.4s;
}
/* 
#sector[aria-expanded="true"],
#tech[aria-expanded="true"],
#revenue[aria-expanded="true"] {
    background-image: url(./../../Assets/icons/expanded-toggle.png);
} */

#sector::after,
#tech::after,
#revenue::after {
  display: none;
}

/* #sector::after {
    display: none;
} */

#sector:focus,
#tech:focus,
#revenue:focus {
  border: none;
}

#sector option,
#tech option,
#revenue option {
  background-color: #fff;
  border: none;
}

#options option:selected {
  color: #fff;
}

.dropdown {
  box-shadow: 0px 0px 11px rgba(198, 198, 198, 0.25);
  border-radius: 8px;
  border: 0;
  margin-top: 9px !important;
}

.dropdown li {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown li:focus {
  background-color: #fff !important;
  /* color: ; */
}

#searchList {
  position: absolute;
  list-style-type: none;
  background: white;
  width: 250px;
  margin: 0 auto;
  box-shadow: 0px 0px 11px rgba(198, 198, 198, 0.25);
  border-radius: 8px;
  z-index: 1111;
}

#searchList li {
  padding: 8px;
  cursor: pointer;
}

#options button:hover {
  color: black;
}

.toggle {
  width: 10px;
  height: 6px;
  margin-left: 7px;
}

.active {
  background-color: #18a62b !important;
  color: white !important;
}

/* Dropdown mobile */
.mob_opt #drop {
  margin-top: 18px;
  justify-content: center;
}
.dropdown_mob {
  width: 88px;
  height: 40px;
}
.dropdown_mob button {
  gap: 7px;
  display: flex;
  color: #6d747a;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  align-items: center;
  padding: 8.3px 24px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border: none;
}
.dropdown_mob img {
  width: 10px;
}
.dropdown_mob_add {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
}
.mob_view {
  /* display: none; */
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
}
.mob_view .dropdown_mob_opt {
  justify-content: space-between;
}
.search_box {
  display: flex;
  align-items: center;
}
.search_box img {
  width: 14px;
  height: 14px;
  transform: translateX(28px);
}
.search_box input {
  width: 100%;
  padding: 12px 36px;
  border: none;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.dropdown_dex {
  display: flex;
}
@media only screen and (max-width: 1000px) {
  .dropdown_mob_add {
    display: flex;
    width: 100%;
    transform: translateX(-10px);
  }
  .mob_opt button {
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #6d747a;
  }
  #options {
    width: 76vw;
    padding: 24px 0;
    margin-left: 0px;
    background-color: transparent;
  }
  .search_box {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  #options {
    width: 100%;
    padding: 0;
    padding-bottom: 7px;

    margin-left: 0px;
    max-width: 100%;
    background-color: transparent;
    transform: translateX(-10px);
  }
  .mob_view {
    align-items: center;
    justify-content: center;
  }
  .mob_opt button {
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* #drop {
    display: flex !important;
    flex-direction: column !important;
  } */
}
@media only screen and (max-width: 445px) {
  #options {
    width: 100vw;
    max-width: 100%;
    margin: auto;
    display: flex !important;
    justify-content: center;
    align-items: center;
    transform: translateX(-10px);
    z-index: 999;
  }
  .dropdown_mob_add {
    gap: 20px;
    justify-content: space-between;
  }
  .mob_opt button {
    width: 107px;
    margin: 0 !important;
  }
  .mob_opt #drop {
    justify-content: space-between;
  }
}
