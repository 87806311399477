#content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  height: 80vh;
  width: 100%;
  margin: auto;
  position: relative;
  padding-right: 166px;
}

#content h1 {
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
}

#content p {
  font-size: 20px;
  font-weight: 400;
  margin-top: 12px;
  text-align: center;
}

#content a {
  font-size: 14px;
  width: 138px;
  height: 48px;
  background-color: #18a62b;
  border-radius: 8px;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
}

@media only screen and (max-width: 999px) {
  #content {
    padding-right: 0;
  }
}
