.video-pitch-wrapper {
  padding: 15px;
}

.video-container {
  height: max-content;
  display: flex;
  position: relative;
  padding: 0;
  width: 100%;
  margin-top: 10px;
}

.startup-video-main {
  cursor: pointer;
  border-radius: 11.687px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-color: #bbbebe;
  /* animation: skeletonLoading 1.5s linear infinite alternate; */
}

.play-btn-bg {
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.7s;
  background-color: rgba(0, 0, 0, 0.45);
}
