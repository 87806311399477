.fs-wrapper {
  padding: 15px;
}

.sub-heading {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
.sub-heading img {
  height: 20px;
}
.sub-heading h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: #000000;
  margin: 0;
}
.fs-image {
  width: 100%;
  margin-bottom: 7px;
}
.fs-desc {
  margin-top: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: #000000;
}
@media only screen and (width > 1000px) {
  .fs-desc {
    margin-top: 20px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
  
    color: #000000;
  } 
}
@media screen and (min-width: 700px) {
  #FinancialSynopsis * {
    font-family: "Roboto", sans-serif;
  }

  #FinancialSynopsis h5 {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 0;
    color: #0f1f78;
  }

  #icon {
    width: 22.5px;
    height: auto;
  }

  #subHeader {
    display: flex;
    align-items: center;
    gap: 16.25px;
    margin-top: 16px;
  }

  #stat {
    background: #ebf8ed;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.200691);
    border-radius: 24px;
    padding: 30px 24px;
    margin-top: 24px;
    position: relative;
    overflow: hidden;
    margin-right: -260px;
  }

  .highlight {
    background: #ffffff;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.13);
    border-radius: 12px;
    padding: 17.2px 23.4px;
    margin: 18px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .highlight > div {
    gap: 12.4px;
  }

  .highlight h4 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .icon {
    width: 19.2px;
    height: auto;
  }

  #FinancialSynopsisRight {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 150px;
  }

  #funding {
    margin-top: 32px;
  }

  #funding h5 {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }

  #fundingContent {
    margin-top: 24px;
  }

  #fundingContent p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }

  #fundingContent b {
    font-weight: 700;
  }

  #funding h5 {
    color: black;
  }

  .image {
    width: 100%;
    border-radius: 12px;
    margin-top: 20px;
  }
}
