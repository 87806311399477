.section1{
    padding-left: 100px;
    padding-right: 100px;
    margin-top: 100px;
    max-width: 1440px;
    margin-bottom: 300px;
}

.header_part{
    width: 100%;
}

.header_part h1{
    color: var(--Grey-Darkest, #08090A);
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.header_part p{
    color: var(--Blacks-Darker-grey, #6D747A);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.option{
    width: 100%;
    display: flex;
    gap: 10;
    align-items: center;
}
.option h3{
    margin-right: 20px;
    color: #000;

font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.options button{
    border-radius: 16px;
background: var(--Primary-10, #E9EBF4);
color: #6D747A;
border: none;

font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding: 10px;
margin-left: 10px;
}


.main_comment{
    margin-top: 25px;
}
.comment{
    display: flex;
    /* align-items: center; */
}

.comment img{
    border-radius: 50%;
    width: 42px;
    height: 42px;
}

.comment textarea{
    border-radius: 10px;
border: 1px solid var(--Primary-20, #D4D7E9);
padding: 10px;
color: var(--Blacks-Dark-grey, #939CA3);
outline: none;
resize: none;
}

.btn_pots{
    display: flex;
    justify-content: end;
    margin-top: 20px;
}

.post_btn{
    padding: 10px 32px;
    border: none;
    outline: none;
    color: var(--Primary-10, #E9EBF4);
    background: var(--Blacks-Dark-grey, #939CA3);
    border-radius: 8px;
}

.post_btn_active{
    padding: 10px 32px;
    border: none;
    outline: none;
    color: var(--Primary-10, #E9EBF4);
    font-style: normal;
font-weight: 600;
    border-radius: 8px;
background: var(--Primary-100, #283891);
}

.title{
    width: 100%;
}

.title input{
    width: 100%;
    padding: 10px;
    border-radius: 8px;
border: 1px solid var(--lighter-lighter-grey, #CED4DA);
background: #FFF;
}