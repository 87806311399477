/* Mobile first css */
.main-about-card-container {
  width: 100%;
  max-width: 23rem;
  min-height: 10rem;
}
.about-icon {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-holder {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 50%;
  background: #e9ebf4;
}
.card-heading p {
  padding-top: 1.2rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #08090a;
}
.card-about p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-feature-settings: "salt" on;
  color: #6d747a;
}
@media only screen and (width >=1000px) {
  .card-heading p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #08090a;
  }
  .card-about p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    text-align: center;
    font-feature-settings: "salt" on;

    /* Grey/Darker */

    color: #6d747a;
  }
}
