/* Desktop First Css */
.main-newsletter-container {
  padding: 3rem 5rem;
  overflow-y: hidden;
}
.main-modal-container {
  position: fixed;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  z-index: 10000;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  opacity: 1;
  transition: opacity 300ms, transform 500ms;
  transform: translateY(0px);
}
.main-modal-container_closed {
  position: fixed;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  z-index: -1;
  pointer-events: none;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 500ms, transform 500ms;
  transform: translateY(-400px);
}
.modal-message-container {
  width: 858px;
  height: 436px;
  background: #ffffff;
  border-radius: 16px;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  flex-direction: column;
  position: relative;
  --position-top: 2rem;
  --position-horizontal: 2.5rem;
}
.button-container{
  position: absolute;
  width: 100%;
  height: fit-content;
  padding: 2rem 2.5rem;
  inset: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.arrow-btn {
  display: block;
  transform: scale(1.5);
  cursor: pointer;
  object-fit: contain;
}
.cross-btn {
  transform: scale(1.5) rotate(45deg);
  object-fit: contain;
  cursor: pointer;
}
.modal-message-container h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  width: 492px;
  margin: 0px;
  color: #283891;
}
.modal-message-container p {
  width: 475px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  margin: 0px;
  margin-bottom: 1.4rem;
  text-align: center;

  /* Blacks/Darker grey */

  color: #6d747a;
}
.modal-message-container button {
  all: unset;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 48px;
  gap: 10px;
  width: 147px;
  height: 56px;
  background: #283891;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: "salt" on;
  color: #ffffff;
  cursor: pointer;
  outline: 2px solid #283891;
  transition: all 200ms;
}
.modal-message-container button:hover {
  background-color: #ffffff;
  color: #283891;
}
.heading {
  margin-bottom: 2rem;
}
.heading h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 46px;
  color: #283891;
  text-align: center;
}
.blog-container {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}
.highlited-blog {
  max-width: 592px;
}
.blog-card-container {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 18px;
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 18px;
}
/* .blog-card-container:last-child{
  border-bottom: 1px solid #e5e7eb;
} */
.blog-card-container_end{
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 18px;
  border-bottom: none;
  padding-bottom: 18px;

}
.card-image {
  display: block;
  width: 176px;
  min-height: 84px;
  max-height: 112px;
  border-radius: 6px;
}
.card-content h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  /* Light / 02 Greys fill/ Dark */

  color: #323338;
}
.card-content p {
  margin: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  font-feature-settings: "salt" on;

  /* Neutral/500 */

  color: #6b7280;
}
.subscribe-container {
  margin-top: 2rem;
}
.subscribe-container > h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height */

  text-align: center;
  margin-bottom: 0px;
  color: #283891;
}
.subscribe-container > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin: auto;
  margin-bottom: 1.3rem;

  /* Blacks/Darker grey */
  width: 475px;
  color: #6d747a;
}
.subscribe-container > button {
  all: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 48px;
  gap: 10px;
  /* Primary/100 */

  background: #283891;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
  text-align: center;
  font-feature-settings: "salt" on;

  /* Blacks/White */

  color: #ffffff;
  cursor: pointer;
  margin: auto;
  outline: 2px solid #283891;
  transition: all 200ms;
}
.subscribe-container > button:hover {
  background-color: #ffffff;
  color: #283891;
}
@media only screen and (width <= 1000px) {
  .main-newsletter-container {
    padding: 0rem 1rem;
  }
  .heading {
    margin: 1.5rem 0px;
  }
  .heading h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 46px;
    color: #283891;
    text-align: start;
  }
  .blog-card-container {
    width: 100%;
    height: fit-content;
  }
  .blog-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;
  }
  .card-image {
    display: block;
    width: 94px;
    height: 100%;
    /* object-fit: contain; */
    border-radius: 6px;
  }
  .blog-card-container {
    border: none;
  }
  .card-content h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* Light / 02 Greys fill/ Dark */

    color: #323338;
  }
  .card-content p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* or 183% */

    font-feature-settings: "salt" on;

    /* Neutral/500 */

    color: #6b7280;
  }
  .subscribe-container > h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;

    color: #283891;
    margin-bottom: 0px;
  }
  .subscribe-container > p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    margin: 1.3rem 0px;
    /* Blacks/Darker grey */
    width: 100%;
    color: #6d747a;
  }
  .subscribe-container > button {
    all: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 48px;
    gap: 10px;
    /* Primary/100 */

    background: #283891;
    border-radius: 6px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    height: 38px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: "salt" on;

    /* Blacks/White */

    color: #ffffff;

    margin: auto;
    outline: 2px solid #283891;
    transition: all 200ms;
  }
  .subscribe-container > button:hover {
    background-color: #ffffff;
    outline: 2px solid #283891;
    color: #283891;
  }
  .subscribe-container {
    margin-top: 0rem;
    margin-bottom: 2.5rem;
  }
  .modal-message-container {
    min-width: 334px;
    margin: 0px 12px;
    height: 261px;
    background: #ffffff;
    border-radius: 16px;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    flex-direction: column;
    gap: 12px;
    position: relative;
    --position-top: 1.5rem;
    --position-horizontal: 1.5rem;
  }
  .button-container{
    padding: 1.5rem;
  }
  .arrow-btn {
    display: block;
    transform: scale(1);
    cursor: pointer;
    object-fit: contain;
  }
  .cross-btn {
    transform: scale(1.5) rotate(45deg);
    object-fit: contain;
    cursor: pointer;
  }
  .modal-message-container h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    width: 284px;
    margin: 0px;
    margin-top: 2rem;
    color: #283891;
  }
  .modal-message-container p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    text-align: center;

    /* Blacks/Darker grey */
    width: 334px;
    margin: 0px;
    color: #6d747a;
  }
  .modal-message-container button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 48px;
    gap: 10px;

    width: 111px;
    height: 34px;

    /* Primary/100 */

    background: #283891;
    border-radius: 6px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 6px;
    color: #ffffff;
  }
}
