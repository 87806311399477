.main1{
    width: 100%;
    height: 100%;
    margin-top: 50px;
    position: relative;
    overflow: hidden;
}

.header_wrapper{
    width: 100%;
}

.header_wrapper h1{
    color: #08090A;
    font-size: 48px;
    font-weight: 500;
    text-align: center;
}

.header_wrapper h2{
    text-align: center;
    color: #6D747A;
    font-size: 24px;
    font-weight: 400;
    padding-top: 5px;
}

.green_bg{
    transform: translateX(-1550px);
    margin-top: -130px;
    opacity: 60%;
}

.image_wapper{
    width: 100%;
    position: absolute;
    bottom: 28%;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: auto;
}

.image_wapper button{
    width: 138px;
    height: 48px;
    background-color: #E9EBF4;
    color: #283891;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    border: none;

}

.main2{
    margin-top: -230px;
}

.company_wrapper{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.main_company{
    display: flex;
    gap: 50px;
    margin-top: 30px;
}
.main6{
    margin-bottom: 100px;
    margin-top: 10% !important;
}

.main8{
    display: block;
}
.main08{
    display: none;
}
.mobile_view_client{
    display: none;
}
.company_main{
    display: flex;
    justify-content: space-around;
    padding-top: 40px;
    gap: 32px;
}


@media (max-width:769px) {   

    .header_wrapper{
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .header_wrapper h1{
        color: #08090A;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
    }
    
    .header_wrapper h2{
        text-align: center;
        color: #6D747A;
        font-size: 16px;
        font-weight: 400;
        padding-top: 5px;
    }
    .image_wapper{
        width: 100%;
         position: relative;
         padding-top: 50px;
    }
    .image_wapper img{
        width: 200px;
        height: 200px;
    }
    
   

    .green_bg{
        display: none;
        min-height: auto;
    }
    .main2{
        margin-top: 10%;
    }
    .main6{
        margin-top: 40% !important;
    }
    .main7{
        height: 10%;
        margin-top: -55%;
    }
    .main8{
        display: none;
    }
    .main08{
        overflow: hidden;
        margin-top: -60%;
        display: block;
    }
    .main9{
        margin-top: -20%;
    }
    .company_wrapper{
        width: 100% !important;
        display: flex !important;
        overflow-x: scroll !important;
    }
    
    .company_main {
        flex-wrap: nowrap !important; 
    }
    .mobile_view_client{
        display: none;
    }
}