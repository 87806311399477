.data {
  padding-left: 81px;
  border-left: 1px solid #ced4da;
  padding-top: 37px;
  padding-bottom: 130px;
}
#last-update {
  border-left: 0px !important;
}
.date {
  margin-top: 37px;
  background-color: #fff;
  margin-right: -5px;
  text-align: right;
  height: fit-content;
  z-index: 2;
  font-family: 'Roboto';

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}

.date span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.2px;
  color: #000000;
}

.para {
  width: 595px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6d747a;
  margin: 8px 0px 16px 0px;
}

.custbtn {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  width: 105px;
  height: 24px;
  border: 0;
  background: #6974b2;
  border-radius: 4px;
}
.image {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}
#update {
  margin-top: 0px;
  margin-bottom: 35px;
  height: fit-content;
  position: relative;
  overflow: auto;
  margin-top: 0px;
}
#update::-webkit-scrollbar {
  display: none;
}
.team-container {
  display: flex;
  align-items: center;
}
@media only screen and (width < 800px) {
  #update {
    margin: 0px !important;
    padding: 0px 10px !important;
  }
  .data {
    min-width: 85%;
    padding-left: 30px;
    border-left: 1px solid #ced4da;
    padding-top: 37px;
    padding-bottom: 35px;
    padding-right: 0px;
  }
  .para {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.5rem;
    color: #6d747a;
    margin: 8px 0px 16px 0px;
    width: auto;
  }
  .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #000000;
  }
  .date {
    width: 14rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    color: #000000;
  }
  .date span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    color: #000000;
  }
  .team-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
  }
  .custbtn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 16px;
    width: 75px;
    height: 20px;
    padding: 2px 8px;
    background: #6974b2;
    border-radius: 4px;

    color: #ffffff;
  }
}
