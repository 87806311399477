.whole_wrapper_plan{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.main_container_plan{
    width: 1240px;
    border: 1px solid #CED4DA;
    border-radius: 24px;
    padding-top: 40px;
    padding-bottom: 20px;
}
.country_map_wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.country_list{
    display: flex;
    gap: 75px;
}
.country_list p{
    font-size: 24px;
    color: #283891;
    font: 400px;
    z-index: 999;
}
.active_list{
    font-size: 24px !important; 
    font: 600 !important;
    color: #39B54A !important;
}

.country_policy{
    display: flex;
    border: 1px solid #74CB80;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    justify-content: space-around;
    width: 38%;
    box-shadow: 
    0 2px 2px 0 rgba(57, 181, 74, 0.4), 
    0 -1px 2px 0 rgba(57, 181, 74, 0.4);
    border-radius: 12px;
    margin-top: 10px;
}

.lits_wrapper{
    display: flex;
    align-items: center;
    gap: 5px;
}

.lits_wrapper p{
 color: #0F1F78;
 font-weight: 600;
 font-size: 16px;
 padding-top: 14px;
}

.country_contain{
    width: 100%;
    margin-left: 70px;
    margin-right: 40px;
    margin-top: 50px;
}

.country_contain h1{
    font-size: 24px;
    font-weight: 600;
    color: #0F1F78;
}

.question_wrapper{
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: start;
}
.question_div{
    border-left: 2px solid #D4D7E9;
    padding-left: 10px;
    color: #283891;
    font-size: 18px;
    font-weight: 400;
    padding-top: 5px;
    padding-bottom: 5px;
    max-width: 90%;
}
.question_div span{
    font-weight: 600;
}


.plain_wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 70px;

}
.plan_card{
    width: 350px;
    border: 1px solid #D4D7E9;
    border-radius: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
}

.plan_card h1{
    color: #39B54A;
    font-size: 24px;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 10px;
}

.plan_card h2{
    color: #0F1F78;
    font-size: 28px;
    font-weight: 600;
    padding-bottom: 15px;
} 

.plan_card h3{
    font-size: 16px;
    font-weight: 500;
    color: #0F1F78;
    text-align: left !important;
    margin-right: 140px;
    padding-top: 35px;
}
.plan_card ul{
    gap: 5px;
    display: flex;
    flex-direction: column;
}

.plan_card li{
    color: #283891;
    font-size: 16px;
    font-weight: 500;
}

.plan_card button{
    border: none;
    width: 110px;
    height: 48px;
    border-radius: 8px;
    background-color: #283891;
    color: white;
    margin-bottom: 25px;
}

.faq_wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}

.faqs_main{
    width: 120%;
    /* padding-left: 30px; */
    padding-right: 60px;
}
.blogs_wrapper{
    width: 100%;
    display: flex;
    justify-content: center;


}
.blogs_main{
    width: 85%;
    display: flex;
    flex-direction: column;
}

.blogs_card{
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;

}

.blogs_detail{
    padding-left: 25px;
}

.blogs_detail h1{
    font-size: 20px;
    font-weight: 600;
}
.blogs_detail h2{
    color: #6B7280;
    font-size: 18px;
    font-weight: 400;
}

.blogs_detail h3{
    color: #6B7280;
    font-size: 18px;
    font-weight: 400;
}
.time_date{
    margin-top: 20px;
    margin-bottom: 20px;
}

.blogs_detail button{
    background-color: white;
    border: none;
    color: #0F1F78;
    font-weight: 600;
    font-size: 16px;
    padding-right: 30px;
}


.blogs_heading{
    font-weight: 600;
    font-size: 24px;
    color: #283891;
    padding-left: 110px;
    margin-top: 30px;
}
.blogs_wrapper01{
    display: none;
}

.country_map_wrapper01{
    display: none;
}

.country_policy2{
    display: none;
}
.faq_wrapper2{
    display: none;
}


@media (max-width:769px) {
    .main_container_plan{
        width: 100%;
        border: 1px solid #CED4DA;
        border-radius: 24px;
        padding-top: 40px;
        padding-bottom: 20px;
        overflow: hidden;
      
    }    
    .blogs_wrapper{
       display: none;
    }
    .blogs_wrapper01{
        display: block;
    }
    .blogs_heading{
        font-weight: 600;
        font-size: 24px;
        color: #283891;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: -20px;
    }
    .country_contain{
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 50px;
    }
    .country_map_wrapper{
       display: none;
    }
    .country_wrapper_01{
        width: 100%;
        overflow-x: scroll;
    }
    .country_map_wrapper01{
        display: block;
        width: 150%;
        overflow-x: scroll;
    }
    .country_list{
        gap: 35px;
        padding-left: 30px;
    }
    .country_policy2 {
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 90% !important;
        display: flex;
        border: 1px solid #74CB80;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        align-items: center;
        justify-content: space-around;
        width: 38%;
        box-shadow: 
        0 2px 2px 0 rgba(57, 181, 74, 0.4), 
        0 -1px 2px 0 rgba(57, 181, 74, 0.4);
        border-radius: 12px;
        margin-top: 10px;
    }

    .detail_list {
        flex: 1 0 50%; /* Each card takes up 50% of the width on mobile */
        max-width: 50%;
    }
    .main_wrapper_policy_center{
        display: flex;
        justify-content: center;
    }
    .lits_wrapper{
        padding-left: 30px;
    }
    .faq_wrapper{
        display: none;
    }
    .faq_wrapper2{
        display: block;
        width: 100%;
    }
}