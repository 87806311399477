#investmentPortal {
  transition: all 0.5s;
  padding-right: 28px;
  height: fit-content;
  margin-left: 1rem;
  width: 100%;
  overflow: hidden;
}
#investmentPortal h1 {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 0;
  color: #000000;
}
.navigation-heading {
  font-size: 18px;
  font-weight: 500;
  margin-top: 4px;
  color: #6d747a;
  cursor: pointer;
}

#portalHeader {
  background-color: #e9ebf4;
  border-radius: 12px;
  padding: 30px 43px;
  margin-top: 44px;
  margin-left: 2px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.startup-name-img {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}
/* .startup-left-container{
  
} */
.startup-text {
  width: 70%;
}
.startup-name {
  font-family: 'Poppins';
  font-style: normal;
  font-size: 48px !important;
  line-height: 56px !important;
  /* or 117% */

  color: #000000;
}
#portalHeader h1 {
  font-size: 32px;
  font-weight: 700;
}
#portalHeader h3 {
  color: #6d747a;
  font-size: 18px;
  font-weight: 500;
}
#portalHeader p {
  font-size: 16px;
  font-weight: 500;
  color: #6d747a;
}
.tags {
  margin-top: 6rem;
  width: 70%;
}
.tags button {
  padding: 4px 8px;
  background: #ced4da;
  border-radius: 4px;
  border: 0;
  margin-right: 12px;
  font-size: 12px;
  font-weight: 500;
  color: #08090a;
  margin-bottom: 10px;
}
.startup-right-container {
  width: 40%;
}
/* ================================ */

#committedAmount h1 {
  font-size: 32px;
  font-weight: 600;
  color: #283891;
  font-family: 'Poppins';
  font-style: normal;
}

#committedAmount img {
  width: 18px;
}

#committed {
  display: flex;
  align-items: center;
  gap: 14px;
}

#committed h3 {
  color: #6d747a;
  margin-bottom: 0;
}

#subhead {
  display: flex;
  align-items: center;
  gap: 5.5px;
}
.tooltip-text {
  font-weight: 500;
  font-size: 18px;
  color: #6d747a;
}

#subhead img {
  width: 15px;
  height: 15px;
}

#chartCont {
  width: 100%;
  height: 17px;
  background-color: #d9d9d9;
  border-radius: 10px;
  position: relative;
}

#blue {
  height: 100%;
  width: 0%;
  background-color: #3e4c9c;
  border-radius: 50px;
  position: absolute;
  top: 0;
  z-index: 1;
  transition: 450ms;
}

#green {
  height: 100%;
  width: 0%;
  transition: 450ms;
  background-color: #4dbc5c;
  border-radius: 50px;
  position: absolute;
  top: 0;
  z-index: 0;
}
.raised-percentage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

#rocket {
  width: 36px;
  height: 39.39px;
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 95%;
}

#portalHeadingText h3 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
  color: #6d747a;
  margin-top: 19.6px;
}

#percentage {
  color: #08090a;
}

.hr {
  width: 100%;
  height: 2px;
  background-color: #ced4da;
  margin-top: 21px;
  margin-bottom: 24px;
}

.investorText {
  display: flex;
  align-items: center;
  gap: 5.5px;
  font-size: 18px;
  font-weight: 500;
  color: #6d747a;
}

.investorText img {
  width: 15px;
  height: 15px;
}

#alert {
  width: 35px;
  height: 35px;
}

#alertSection {
  display: flex;
  align-items: flex-start;
  gap: 7px;
}

#portalHeadingText button {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 600;
}

#portalHeadingText small {
  color: #0f1f78;
  font-weight: 700;
  display: block;
  margin-top: 12px;
  margin-bottom: 8px;
}

#investorNumber,
#leftForInvestNumber {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}

#portalHeader hr {
  background-color: #ced4da;
  border: 1px solid #ced4da;
  width: 100%;
  margin-top: 50px;
  margin: auto;
}

#myInvestment {
  display: flex;
  align-items: center;
  gap: 24px;
  margin: 0.5rem 0rem;
}

#myinvestment h3 {
  font-weight: 500;
  font-size: 18px;
  color: #6d747a;
}
#myinvestment h1 {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #08090a;
}
.download-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 5rem;
}
.invoice-download-btn {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  width: 12rem;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  background: #0085ff;
  border-radius: 4px;
  margin-right: 0rem;
}
.startup-right-container {
  min-width: 40%;
}
.loader-container {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.last-horizontal-line {
  display: none;
}
.colon {
  display: inline-block;
  flex-grow: 0;
}
@media only screen and (width <=1000px) and (width >=836px) {
  #investmentPortal {
    transition: all 0.5s;
    padding: 10px;
    height: fit-content;
  }
}
@media only screen and (width < 836px) {
  .last-horizontal-line {
    display: block;
    margin: auto;
  }
  .colon {
    display: none;
  }
  .heading h1 {
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 28px !important;
    line-height: 33px !important;
    color: #08090a;
  }
  .navigation-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
  .navigation-heading > span {
    margin-left: 0.8rem;
  }
  #investmentPortal {
    padding: 0px;
    margin: 0px;
  }
  .startup-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 28px !important;
    line-height: 36px !important;
    /* identical to box height, or 128% */

    color: #000000;
  }
  .startup-name-img img {
    width: 25px;
    height: 25px;
  }
  #portalHeader {
    background-color: #e9ebf4;
    margin: 2rem 0px;
    padding: 30px 12px;
    border-radius: 0px;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .startup-left-container {
    width: 100%;
    margin-bottom: 5rem;
  }
  .tags {
    margin: 0px;
  }
  .startup-text {
    width: 100%;
  }
  .tags {
    width: 100%;
  }
  .startup-right-container {
    width: 100%;
    position: relative;
  }
  .heading {
    margin-top: 1rem;
    padding: 0px 12px;
  }
  #chartCont {
    width: 100%;
    height: 28px;
    background-color: #d9d9d9;
    border-radius: 10px;
    position: absolute;
    top: -45px;
  }
  #committed {
    width: 50%;
    display: inline-flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: -3rem;
  }
  .raised-percentage {
    width: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    margin-bottom: 0rem;
  }
  .raised-percentage h3 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1px;
  }
  #portalHeader hr {
    background-color: #ced4da;
    border: 1px solid #ced4da;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  #myInvestment {
    margin-top: 1rem;
    width: fit-content;
    display: inline-flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 4px;
  }
  .download-btn {
    float: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .invoice-download-btn {
    width: 11rem;
    padding: 12px 12px;
    margin: 0px;
  }
  #percentage {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #08090a;
  }
  #committed {
    gap: 4px;
  }
  .navigation-heading span {
    margin: 0px;
  }
  .navigation-heading span:first-child {
    margin-right: 10px;
  }
}
