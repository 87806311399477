.main-manager-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5rem 0px;
  width: 650px;
}
.main-manager-card-reverse {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 5rem 0px;
  width: 650px;
}
.main-manager-card-reverse .text-container {
  width: 45%;
}
.text-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 50%;
}
.index {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  /* or 134px */

  /* Primary/100 */
  margin: 0px;
  margin-bottom: -1.4rem;
  color: #283891;
}
.heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 130%;
  /* or 36px */

  /* Blacks/Black */

  color: #08090a;
  position: relative;
  margin: 0px;
  display: inline-block;
}
.heading::after {
  content: '';
  position: absolute;
  width: 5rem;
  height: 2px;
  background: #7e88bd;
  opacity: 0.6;
  margin: 0px;
  bottom: -10px;
  left: 0px;
}
.sub-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* or 150% */

  /* Blacks/Dark grey */
  margin: 0px;
  color: #939ca3;
}
.image-container {
  position: relative;
  isolation: isolate;
}
.image-container::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  inset: 0;
  background: #9fdfbe;
  filter: blur(128.5px);
}
@media only screen and (width <=800px) {
  .main-manager-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0px;
    flex-direction: column;
    width: fit-content;
  }
  .main-manager-card-reverse {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0px;
    flex-direction: column;
    width: fit-content;
  }
  .text-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 90%;
  }
  .main-manager-card-reverse .text-container {
    width: 90%;
  }
  .index {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    /* identical to box height */

    text-align: center;

    /* Primary/100 */

    color: #283891;
  }
  .heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */

    text-align: center;

    /* Blacks/Black */

    color: #08090a;
  }
  .sub-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    text-align: center;

    /* Blacks/Dark grey */

    color: #939ca3;
  }
  .heading::after {
    content: '';
    position: absolute;
    width: 5rem;
    height: 2px;
    background: #7e88bd;
    opacity: 0.6;
    margin: 0px;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  #hide-background-color::after {
    display: none;
  }
}
