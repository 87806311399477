@keyframes button-animation {
  0% {
    box-shadow: 0 0 0 1px #39b54aca;
  }
  100% {
    box-shadow: 0 0 0 14px #39b54a04;
  }
}

#avatar {
  cursor: pointer;
}
.navbar {
  box-shadow: none;
}
#logo {
  left: 0;
  right: 0;
  margin: auto;
}


#logo img {
  /* width: 100%; */
}

#animatedButton {
  position: relative;
  background-color: #39b54a;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: button-animation 1s infinite ease-in-out;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid transparent;
}

.liveDeals {
  color: #e9ebf4;
  text-decoration: none;
  width: 100%;
  font-weight: 600;
  }

#arrowIcon {
  width: 30%;
  height: auto;
}

#animatedButton:hover {
  /* animation: none; */
  background: transparent !important;
  border: 2px solid #39b54a;
}
#animatedButton:hover .liveDeals {
  cursor: pointer;
  color: #39b54a;
}

#hello {
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 1px;
  font-weight: 600;
  padding: 30px 0;
  display: block;
}
.x{
  display: none;
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 991px) {
  #navbar {
    padding: 24px 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
  }

  #logo {
    position: absolute;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
  #navbar {
    padding: 16px 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
  #logo {
    position: absolute;
  }
} */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #navbar {
    padding: 16px 68px;
  }

  #logo {
    position: static;
  }

}

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {
  #navbar {
    padding: 16px 68px;
  }
} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {
  #navbar {
    padding: 16px 68px;
  }
} */

.search_bar_1{
  display: flex;
  background-color: red;
}

@media (min-width: 992px) and (max-width: 1048px) {
  #navbar {
    padding-left: 20px !important;
    padding-right: 2px !important;
  }
}

@media (max-width: 991px) {
  #navbar {
    padding: 24px 0;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.1) !important;
  }
  #logo img {
    width: 120px;
  }
  #logo {
    top: 10px;
    position: absolute;
    margin-left: 43% !important;
  }
}

@media (max-width: 595px) {
  #logo {
    top: 5px !important;
  }
}

/* For mobile screens */
@media only screen and (width < 700px) {
  #hello {
    font-size: 12px;
    /* text-align: left !important; */
    padding: 10px;
    font-weight: 600 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .hellotxt{
    width: 95%;
    text-align:left ;
  }
  .x{
    display: block;
    width: fit-content;
    font-size: 14px;
  }
  #logo {
    top: 10px;
    position: absolute;
    margin-left: 35% !important;
  }
  .mobile_nav{
    position: absolute !important;
    right: 20% !important;
    top: 55% !important;
    background-color: white;
    box-shadow: none;
 }
 
}

@media only screen and (width < 365px) {
  #navbar {
    top: -0.5px !important;
  }
}
