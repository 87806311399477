.swot-wrapper {
  padding: 15px;
}
.swot-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.swot-cards-container .card {
  padding: 16px;
  padding-right: 4rem;
  /* width: fit-content; */
  width: 100%;
  /* max-width: 400px; */
  background: #e9ebf4;
  border-radius: 12px;
  position: relative;
  overflow-x: hidden;
}
.swot-cards-container .card::before {
  content: "";
  position: absolute;
  top: 0;
  right: 18%;
  height: 100%;
  width: 1px;
  background-color: rgba(206, 212, 218, 1);
}
.swot-cards-container .card::after {
  content: attr(data-letter);
  position: absolute;
  top: 50%;
  right: -7%;
  transform: translateY(-50%);

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 85.9574px;
  line-height: 101px;
  color: #283891;
  opacity: 0.2;
}

.swot-cards-container .card h4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: #000000;
}
.swot-cards-container .card ul li {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.description {
  margin-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}
@media only screen and (width > 1000px) {
  .swot-cards-container {
    width: 41rem;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  .swot-cards-container .card {
    padding: 16px;
    padding-right: 4rem;
    /* width: fit-content; */
    width: 20rem;
    /* max-width: 400px; */
    background: #e9ebf4;
    border-radius: 12px;
    position: relative;
    overflow-x: hidden;
  }
}