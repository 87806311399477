@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.profile_container {
  width: 97%;
  margin-bottom: 20px;
  font-family:  "Montserrat", sans-serif;
}
.header {
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 17px;
  /* identical to box height, or 175% */
  color: #000000;
}
/* .address input:focus {
} */
.email_box_data input:focus,
.profile_container input:focus {
  border-bottom: 1px solid black;
  outline: none;
}
.input_email,
.profile_container input {
  display: flex;
  width: 100%;

  border: none;
  border-bottom: 1px solid black;
  padding: 5px 10px;
}

.mid1 input,
.mid2 input {
  width: 332px;
}
.profile_wrapper {
  gap: 24px;
  margin: 12px 5px;
  width: 80%;
  display: flex;
  flex-direction: column;
}

.profile_wrapper h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
  color: #1c1f3b;
}
.profile_wrapper p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6d747a;
  margin: 0;
}

.profile_add {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  width: 270.5px;
  /* height: 69px; */
  align-items: center;
  /* border: 1px dashed #6d747a; */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%236D747AFF' stroke-width='2' stroke-dasharray='10%2c 8' stroke-dashoffset='53' stroke-linecap='butt'/%3e%3c/svg%3e");
}
.profile_add button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 10px;
  border: none;
  width: 38px;
  height: 37px;
  background: #5360a7;
}
.first_prop {
  display: flex;
  align-items: end;
  justify-content: space-between;
  flex-direction: row-reverse;
  gap: 30px;
}
.u_name {
  display: flex;
  gap: 30px;
  margin-bottom: 10px;
}
.contact_width input {
  width: 336px;
}
.profile_set {
  display: flex;
  justify-content: space-between;
  gap: 22px;
  align-items: center;
}
.profileimg {
  width: 72px;
  height: 72px;
  background: #d8d8d8;
  padding: 19px 2px 0px;
  border-radius: 50%;
  /* border: 3px solid #939ca3; */
}
.email {
  display: flex;
  justify-content: space-between;
  /* width: 336px; */
}
.mid_prop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .email_box_data input:focus {
  
} */
.add_url {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
  height: 40px;
  border-bottom: 1px solid #000000;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.email_box_data p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6d747a;
}
.add_url input {
  color: #08090a;
  border: none;
  outline: none;
  width: 85%;
}
.add_url input:focus {
  border-bottom: none;
}
.add_url input::placeholder {
  color: #6d747a;
}
.u_link {
  width: 100%;
}

/* .add_url {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  border: 1px dashed #6d747a;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%236D747AFF' stroke-width='2' stroke-dasharray='10%2c 8' stroke-dashoffset='53' stroke-linecap='butt'/%3e%3c/svg%3e");
} */
.add_url .u_btn,
.add_url .active_u_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  /* gap: 10px; */
  width: 30px;
  height: 30px;
  border: none;
  background: #5360a7;
  font-weight: normal;
  cursor: not-allowed;
}
.add_url .active_u_btn {
  cursor: pointer;
  background-color: #283891;
}

.email svg {
  cursor: pointer;
}
.save {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 4% !important;
}
.save .save_btn,
.save .active_save_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  justify-content: center;
  border: none;
  color: #fff;
  width: 89px;
  height: 48px;
  background: #7e88bd;
  border-radius: 8px;
  cursor: not-allowed;
}
.active_save_btn:hover {
  background-color: transparent !important;
  color: #283891;
  outline: 2px solid #283891;
  transition: all 200ms;
}
.save .active_save_btn {
  background: #283891;
  cursor: pointer;
}
.save .cancel_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  justify-content: center;
  color: #283891;
  width: 89px;
  height: 48px;
  background: #fff;
  border: 2px solid #283891;
  border-radius: 8px;
  font-weight: 500;
}

.u_pic img {
  width: 92px;
  height: 92px;
  border-radius: 50%;
  object-fit: cover;
}
.link_list {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 14px;
}
.link_list a li {
  list-style: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  color: #283891;
}
.choose_mob {
  display: none;
}
/* .choose_dex {
  display: block;
} */

.mid1 input::-webkit-outer-spin-button,
.mid1 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.mid1 input[type='number'] {
  -moz-appearance: textfield;
}
@media only screen and (max-width: 999px) {
  .profile_wrapper {
    width: 100%;
  }
  .profile_container {
    width: 90%;

    margin: 0 auto;
    margin-top: 30px;
  }
  .profile_add {
    padding: 13px 22px;
  }
}
@media only screen and (max-width: 699px) {
  .u_name {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0;
  }
  .mid1{
    padding-top: 12%;
  }
  .mid1 input,
  .mid2 input {
    width: 100%;

  }
  .profileimg {
    padding: 10px 2px 0px;
  }
  .profile_wrapper {
    margin: 0px;
  }
  .email {
    width: 92vw;
  }
  .profile_wrapper {
    gap: 15px;
  }
  .mid_prop {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .first_prop {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 15px;
  }
  .header {
    font-size: 24px;
  }
  .profile_wrapper h1 {
    font-size: 20px;
    font-weight: 500;
    /* margin-bottom: 24px; */
  }
  .profile_wrapper p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .profile_add p {
    margin-bottom: 0;
    font-size: 14px;
  }

  .address,
  .mid_prop,
  .email {
    margin-top: 0%;
  }

  .address p,
  .email_box_data p,
  .add_url p {
    margin-bottom: 0;
    font-size: 14px;
  }
  /* .add_url {
    padding: 12px 20px;
  } */
  .profile_add {
    padding: 12px 20px;
  }
  .profile_add button {
    width: 34px;
    height: 30px;
    padding: 4px 12px;
  }
  /* .add_url button svg,
  .profile_add button svg {
    width: 14px;
    height: 29px;
  } */
   
  .save button {
    padding: 12px 34px;
    width: 110px;
    height: 42px;
    margin-bottom: 8%;
    font-weight: 500;
    font-size: 15px;
  }
  .email_box_data {
    padding: 0;
    align-items: center;
  }
  .profile_set {
    gap: 25px;
    align-items: center;
    flex-direction: row-reverse;
    margin-bottom: 10px;
    justify-content: start;
  }
  .profileimg,
  .u_pic img {
    width: 56px;
    height: 56px;
  }
  .link_list {
    margin-top: 8px;
    gap: 0;
  }
  .link_list a li {
    font-size: 12px;
  }
  .choose_mob {
    display: block;
  }
  .header {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 24px;
  }
  /* .choose_dex {
    display: none;
  } */
  .mid1,
  .mid2,
  .u_pic {
    width: 100%;
  }
}

@media only screen and (max-width: 370px) {
  .profile_container {
    width: 94%;
padding-top: 20px;
    margin: 0 auto;
  }
  .profile_set {
    justify-content: space-between;
    gap: 0;
  }
  /* .u_link {
    width: 75%;
  } */
}
