.main1{
    width: 100%;
    height: 470px !important;
    position: relative;
    

}

.wrapper_1{
    width: 100%;
    height: 100% !important;
    background-color: #F8F9FA;
    box-shadow: 0 1px 6px 0 rgba(134, 134, 134, 0.25);
    /* border:1px solid red */
    padding-top: 10px;
   
}

.content_div{
    background-color: #FFFFFF;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    max-height: 351px;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    overflow-y: scroll;
}
.content_div::-webkit-scrollbar{
    display: none;
}

.content_div h1{
    color: #000000;
    font-weight: 700;
    font-size: 24px;
}

.content_div p {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
}

.content_div h2{
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}

.content_div h3{
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}

.btn_cls{
    position: absolute;
    bottom: 15px;
    right: 45%;
  
    
}

.btn_cls button{
    width: 104px;
    height: 48px;
    background-color: #283891;
    font-weight: 600;
    color: #FFFFFF;
    font-size: 16px;
    border-radius: 8px;
    border: none;
   

}