.blog_hero{
    width: 100%;
    height: 100%;
    padding-top: 60px;
    padding-bottom: 100px;
}
.new_sliding::-webkit-scrollbar{
    display: none;
}
.blog_heading{
    width: 100%;
}
.blog_heading h1{
    font-size: 40px;
    font-weight: 600;
    color: #283891;
    text-align: center;
}
.blog_heading p {
    font-size: 16px;
    font-weight: 500;
    color: #939CA3;
    text-align: center;
    padding-top: 5px;
}

.blog_card{
    width: 372px;
    min-height: fit-content;
    min-width: 372px !important;
    border-radius: 8px;
    border: 1px solid #CED4DA;
    padding-top: 15px;
    padding-left: 13px;
    padding-right: 13px;
    padding-bottom: 19px;
    background-color: #FFFFFF;
}
.blog_tags{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.blog_tags p{
    padding-left: 6px;
    padding-right: 6px;
    background-color: #E9EBF4;
    border-radius: 4px;
    color: #2B475F;
    font-size: 14px;
    padding-top: 2px;
    padding-bottom: 2px;
}
.blogs_image{
    width: 100%;
}
.blogs_image img{
    width: 100%;
    border-radius: 6px;
}

.blogs_detail h1{
 color: #2B475F;
 font-weight: 500;
 font-size: 24px;
 margin-top: 25px;

}

.blogs_detail h2{
    color: #2B475F;
    font-weight: 300;
    font-size: 16px;
}

.blogs_detail h3{
    color: #2B475F;
    font-weight: 300;
    font-size: 16px;
}


.blogs_detail button{
    background-color: white;
    border: none;
    outline: none;
    color: #283891;
    font-size: 16px;
    font-weight: 500;
    margin-top: -20% !important;

}
.blogs_detail button img{
    margin-left: 8px;
}


@media (min-width:1539px) {
    .blogs_slideing{
        padding-left: 80px;
        padding-right: 80px;
    }
    
}

@media (max-width:769px) {
    .blog_hero{
        width: 100%;
        height: 100%;
 
    }
    .blog_card{
        width: 96%;
       /* max-width: fit-content; */
        min-height: fit-content;
        border-radius: 12px;
        border: 1px solid #CED4DA;
        padding-top: 15px;
        padding-left: 7px;
        padding-right: 7px;
        padding-bottom: 19px;
        background-color: #FFFFFF;
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 20px;
    }
    .blog_heading h1{
        font-size: 24px;
        font-weight: 600;
        color: #283891;
        text-align: center;
    }
    .blog_heading p {
        font-size: 16px;
        font-weight: 500;
        color: #939CA3;
        text-align: center;
        padding-top: 5px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
}