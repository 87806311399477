.cw_wap h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  color: #000000;
}
.cw_wap {
  width: 502px;
  margin-left: 185px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.cw_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cw_to h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.cw_box h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #08090a;
  margin: 0;
}
.cw_box p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  margin: 0;
  color: #08090a;
}
.cw_amount h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #283891;
  margin: 0;
}
.cw_wap button {
  width: 100%;
  border: none;
  background: #283891;
  border-radius: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  height: 56px;
  color: #ffffff;
  justify-content: center;
  margin-top: 32px;
}
.bank_details {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
  width: 287px;
  height: 80px;
  background: #f8f9fa;
  border-radius: 12px;
}
.bank_details h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #262626;
}
