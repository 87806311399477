:root {
  --border-color: #ced4da;
}

.contact {
  padding: 2rem 6rem 6rem;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.contact_container {
  background: #ffffff;
  height: fit-content;
  padding: 3rem;
  border-radius: 8px;
  border: 1px solid var(--border-color) !important;
  flex: 1;
}
.contact_header {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  letter-spacing: -0.02em;
  line-height: 36px;
  color: #111827;
  text-align: left;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--border-color);
  text-align: center;
}

.who-are-you {
  width: 100%;
  margin: 1.5rem 0;
}
.dropdown_menu {
  box-shadow: 0px 0px 11px rgba(198, 198, 198, 0.25);
  border-radius: 8px;
  border: 0;
  margin-top: 9px !important;
  width: 100%;
  position: absolute;
  z-index: 1000;
  display: none;
  padding: 0.5rem 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
}
.dropdown_menu.show {
  display: block;
}
.dropdown_menu li {
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  transition: all 0.2s ease;
}
.dropdown_menu li:hover {
  color: #1e2125;
  background-color: #e9ecef;
}
.who-label {
  font-size: 20px;
}
.who-are-you-dropdown {
  position: relative;
}
.who-are-you-dropdown button {
  background: #ffffff;
  width: 100%;
  overflow: hidden;
  padding: 14px 16px;
  border: none;
  border: 1px solid var(--border-color);
  border-radius: 8px !important;
  text-align: left !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.4s;
}
.who-are-you-dropdown button:hover,
.who-are-you-dropdown button:focus {
  border-color: #212529;
}

.contact_container .contact_container .form {
  display: block;
  width: 95%;
  margin: 2rem auto;
}
.contact_container .form .input_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  gap: 1rem;
  width: 100%;
}
.contact_container .form label {
  margin-bottom: 0.5rem;
}
.contact_container .form input {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 1.5rem;
  padding: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.contact_container .form input:hover,
.contact_container .form textarea:hover {
  border-color: #212529;
}
.contact_container .form input:focus,
.contact_container .form textarea:focus {
  color: #212529;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.contact_container .form textarea {
  resize: none;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 2rem;
  min-height: calc(1.5em + (0.75rem + 2px));
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.contact_container .form textarea:focus {
  border: 1px solid var(--border-color) !important;
}
.contact_container .form textarea:hover {
  border-color: #212529;
}
.contact_container .form label {
  color: #08090a;
  font-weight: 500;
}

/* For Error */
.contact_container .form-input-err input,
.contact_container .form-input-err textarea {
  color: red !important;
  border: red 2px solid !important;
  margin-bottom: 1px;
}
.contact_container .form-input-err input::placeholder,
.contact_container .form-input-err textarea::placeholder {
  color: red !important;
}
.input-err-msg {
  display: flex;
  align-items: center;
  margin: 5px 2px !important;
  gap: 4px !important;
  font-weight: 400 !important;
  color: red !important;
  font-size: 13px !important;
}

#padding_left_none {
  padding-right: 0px !important;
}

.submitBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  border-radius: 8px;
  width: 150px;
  background-color: #283891;
  color: white !important;
  font-size: 14px;
  border: 2px solid #283891;
  letter-spacing: 1px;
  text-decoration: none;
  font-weight: 600;
}
.submitBtn:hover {
  background-color: transparent;
  color: #283891 !important;
  border: 2px solid #283891 !important;
  transition: all 200ms;
}
.submitBtn:focus {
  /* color: #283891 !important; */
}

@media only screen and (max-width: 993px) {
  .contact {
    width: 100%;
    padding: 1.5rem 1.5rem 3rem;
    height: fit-content;
  }
}
@media only screen and (max-width: 872px) {
  .contact {
    flex-direction: column;
  }

  .submitBtn {
    margin: auto;
  }
}
@media only screen and (max-width: 772px) {
  .contact_container .form {
    width: 100%;
  }
  .contact_container .form .input_container {
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    gap: 0;
  }

  .submitBtn:hover {
    background-color: #283891;
    color: white !important;
  }
}
@media only screen and (max-width: 500px) {
  .contact_header {
    font-size: 24px;
  }
  .contact_container {
    padding: 1.5rem;
  }

  .contact_container .form .input_container {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}
