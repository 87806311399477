#progress {
  background: url("./../../Assets/images/investor-progress-bg.png");
  background-repeat: no-repeat;
  margin-top: 24px;
  margin-bottom: 56px;
  background-position-y: 28%;
  background-size: 83%;
  background-position-x: center;
}

#progress p {
  font-weight: 500;
}

.progressNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 56px;
  height: 56px;
  color: #939ca3;
  border-radius: 50%;
  border: 1px solid #939ca3;
  font-size: 24px;
  font-weight: 600;
  margin: 0 auto;
}

#becomeInvestorContainer p {
  font-size: 14px;
  margin-top: 8px;
}
#becomeInvestorContainer {
  padding: 20px 0;
}

#becomeInvestorForm {
  border: 1px solid #ebedf0;
}

#becomeInvestorTerms {
  padding: 0 12px;
  background-color: #aaa;
}

@media only screen and (max-width: 1082px) {
  #progress {
    width: 52vw !important;
  }
}
@media only screen and (max-width: 769px) {
  #progress {
    width: 65vw !important;
    background: url("./../../Assets/images/investor-progress-bg.png");
    background-repeat: no-repeat;
    margin-top: 24px;
    margin-bottom: 56px;
    background-position-y: 20%;
    background-size: 83%;
    background-position-x: center;
  }
}
@media only screen and (max-width: 606px) {
  #progress {
    width: 65vw !important;
    margin-bottom: 0px;
  }
  .progressNumber {
    width: 38px;
    height: 38px;
    font-size: 15px;
  }
  #becomeInvestorContainer p {
    font-size: 11px;
  }
}
@media only screen and (max-width: 498px) {
  #progress {
    width: 65vw !important;
  }
  .progressNumber {
    width: 24px;
    height: 24px;
    font-size: 10px;
  }
  #becomeInvestorContainer p {
    font-size: 8px;
  }
}

@media only screen and (max-width: 361px) {
  #progress {
    width: 80vw !important;
  }
}
