#FundUsage {
  padding: 15px;
}

#FundUsage svg {
  margin-left: 0;
  transform: rotate(160deg);
  position: absolute;
  top: -71px;
  height: 205%;
  width: 90%;
}

.chartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 32px;
}

#indicators {
  font-family: "Roboto", sans-serif;
}

.indicator {
  margin: 8px 0;
  display: flex;
  align-items: center;
  gap: 12px;
}

.indicator h6 {
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
  font-size: 16px;
  color: #000000;
}

#rd {
  position: absolute;
  top: 83%;
  right: 21%;
  font-size: 16px;
  font-weight: 600;
}

#others {
  position: absolute;
  top: 56%;
  left: -42px;
  font-size: 16px;
  font-weight: 600;
}
#inf {
  position: absolute;
  top: 13%;
  left: -7%;
  font-size: 16px;
  font-weight: 600;
}
#customer {
  position: absolute;
  top: 11%;
  left: 73%;
  font-size: 16px;
  font-weight: 600;
}

@media screen and (min-width: 700px) {
  .chartContainer {
    flex-direction: row;
    gap: 0px;
  }
}
@media screen and (min-width: 1500px) {
  .chartContainer {
    gap: 50px;
  }
}
