.sidebar {
  /* box-sizing: border-box;
  position: fixed;
  height: 100%;
  left: 24px;
  top: 24px; */
  position: fixed;
  top: 0px;
  margin: 24px;
  height: 93vh;
  z-index: 100;
  background: #ffffff;
  border: 1px solid #ebedf0;
  box-shadow: 0px 0px 11px rgba(198, 198, 198, 0.25);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width: 100px;
  transition: width 0.3s ease-in-out;
  overflow: hidden;
}

@media (max-width:1642px) {
  .sidebar{
    overflow-y: scroll;
  }  
}


.profilecont img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
#logo {
  width: 100px;
  height: 34px !important;
  cursor: pointer;
  /* height: auto !important; */
}
#icon {
  cursor: pointer;
  padding-right: 4px;
}

#logoContainer {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}

#logoContainer label {
  cursor: pointer;
}

#logoContainer img {
  transition: all 0.2s;
}

#icon {
  width: auto;
  height: 33px;
}

#arrow {
  width: 48px;
  height: 24px;
}

.logocont {
  padding: 24px 32px;
}

.logocont .logo {
  padding-right: 52.4px;
}

.profilecont {
  display: flex;
  flex-direction: row;
  gap: 9.73px;
  margin: 0px 8px;
  padding: 13.72px;
  background: #f8f9fa;
  border-radius: 14px;
  /* height: 74px; */
  transition: all 0.5s;
  /* border-radius: 14px;
  height: 74px;
  text-decoration: none; */
  align-items: center;
}

.profileimg {
  height: 46.5px;
  width: 46.5px;
  background: #d8d8d8;
  padding: 10px 2px 0px;
  border-radius: 50%;
  /* border: 3px solid #939ca3; */
}

.name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #283891;
}

.subname {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  /* identical to box height, or 146% */

  /* Blacks/Darker grey */

  color: #6d747a;
}

/* .menu { */
.menuText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: hsl(45, 100%, 44%);
  padding: 24px 0px 0px 24px;
}

/* .menu1 {
  margin: 16px auto 32px 32px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 36px;
}

.menu1 .active {
  color: #283891;
} */

hr {
  margin: 0px 8px;

  border-top: 1px solid #ebedf0;
}

/* .menu2 {
  margin: 16px auto 32px 32px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 36px;
  width: 192px; */

.menuItem {
  gap: 12px;
  padding: 13px 0;
  text-decoration: none;
  color: #6d747a;
  cursor: pointer;
}

.blink {
  animation: blinkAnimation 0.2s linear ;
}

@keyframes blinkAnimation {
  0%, 50%, 100% {
    opacity: 0;
  }
}

.menuItem.active svg,
.menuItem:hover svg {
  fill: #283891;
  transition: transform 500ms;
  transform: scale(1.2);
}
.menuItem:hover p,
.menuItem.active p {
  color: #283891;
}

.menu {
  padding: 0 28px;
  border-bottom: 1px solid #ebedf0;
  margin: 8px;
}

.menu:last-child {
  border-bottom: 0;
}

.menuItemText {
  margin-bottom: 0;
}
@media only screen and (width <= 1000px) {
  .sidebar {
    display: none;
   
  }
}
