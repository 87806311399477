.main2{
    width: 100%;
    margin-top: -120px;
    position: relative;
}


.main3{
    width: 100%;
    height: 100%;
    margin-top: 80px;
    margin-bottom: 100px;
}

.plan_heading{
    width: 100%;
}
.plan_heading h1{
    font-size: 36px;
    color: #283891;
    font-weight: 500;
    text-align: center;
}

.plan_heading_two{
    width: 100%;
    display: flex;
    justify-content: center;
}
.plan_heading h2{
    font-size: 24px;
    color: #6D747A;
    font-weight: 400;
    text-align: center;
    width: 75%;
    padding-top: 10px;
    line-height: 36px;

}
.plan_wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 50px;
    padding-left: 40px;
    padding-right: 40px;
}
.plan_card01{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.back_image{
    width: 100vw;
    object-fit: cover;
    margin-top: 80px;
}
.plan_card01 h3{
    font-size: 16px;
    text-align: center;
    color: #08090A;
    font-weight: 600;
    padding-top: 7px;
}
.plans_detail{
    width: 80%;
}
.plans_detail h4{
    font-size: 16px;
    text-align: center;
    color: #08090A;
    font-weight: 400;
    padding-top: 7px;
}

.main6{
    width: 100%;
    margin-top: 100px;
}

.main7{
    width: 100%;
    margin-top: 100px;
}

.main8{
    margin-top: -30px;
}

.clients_wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}
.main_clients{
    width: 90%;
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.main6{
    display: block;
}
.main06{
    display: none;
}

.mobile_view_client{
    display: none;
}

.main5{
    display: block;
}

.main05{
    display: none;
}
.company_main{
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
}
.hero_text2{
    display: none;
}

@media (min-width:1440px) {
   
    
}



@media (min-width:1539px) {
    .for_spacing{
        
        padding-right: 80px;
    }
    
}

@media (max-width:769px) {
    .hero_text2{
        display: block;
    }
    .main2{
        width: 100%;
        margin-top: 100%;
        position: relative;
    }
    .plan_wrapper{
        width: 100%;
        display: block;
        justify-content: center;
        gap: 30px;
        margin-top: 50px;
        padding-left: 40px;
        padding-right: 40px;
    }
    .plan_heading{
        width: 100%;
    }
    .pitch_image_1{
        margin-top: -10% !important;
    }
    .plan_heading h1{
        font-size: 24px;
        color: #283891;
        font-weight: 500;
        text-align: center;
    }
    .plan_card01{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 40px;
    }
    .company_wrapper{
        width: 100% !important;
        display: flex !important;
        overflow-x: scroll !important;
    }
    
    .company_main {
        flex-wrap: nowrap !important;
    }
    .main6{
        display: none;
    }
    .main06{
        overflow: hidden;

        display: block;
    }
    .mobile_view_client{
        display: block;
    }
    .main5{
        display: none;
    }
    .main05{
        display: block;
    }
    
    
}