.main-team-card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  gap: 10px;
  padding: 4px;
  border-radius: 8px;
  background: #f9fafb;
}
.team-image {
  display: block;
  width: 176px;
  height: 176px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.icon {
  display: block;
  width: 30px;
  height: 30px;
  margin-top: 10px;
  cursor: pointer;
  transition: all 200ms;
}
.icon:hover {
  filter: sepia(100%) hue-rotate(190deg) saturate(500%);
}
.team-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.info-data {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  text-align: center;

  color: #111827;
}
.name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.2px;
  margin-bottom: 0px;
}
.info-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  display: flex;
  align-items: center;
  text-align: center;

  /* blue-gray/500 */

  color: #64748b;
}
.name-info-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
@media only screen and (width >= 1000px) {
  .main-team-card {
    width: 220px;
    min-height: 300px;
    background-color: rgba(249, 250, 251, 1);
    border-radius: 8px;
    overflow: hidden;
  }
  .team-image {
    display: block;
    width: 100%;
    height: 220px;
    object-fit: cover;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .team-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
  }
  .team-info p {
    margin: 0px;
  }
  .name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    letter-spacing: -0.2px;

    /* Base/02 */

    color: #090914;
  }
  .info-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    /* blue-gray/500 */

    color: #64748b;
  }
  .info-data {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    margin-top: 18px;
    color: #111827;
  }
  .icon {
    display: block;
    width: 30px;
    height: 30px;
    margin-top: 10px;
    cursor: pointer;
    transition: all 200ms;
  }
  .icon:hover {
    filter: sepia(100%) hue-rotate(190deg) saturate(500%);
  }
}
