.w_wrapper {
  width: 90%;
  margin: 0 auto;
}
.w_container {
  /* margin: 20px 0; */
  margin-bottom: 30px;
}
.w_wrapper h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  color: #000000;
}
.w_left h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  display: flex;
  align-items: center;
  color: #262626;
  margin-left: 22px;
}
.w_left {
  display: flex;
  flex-direction: column;
  gap: 52px;
}
.w_buttons {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.w_buttons button {
  width: 336px;
  height: 56px;
  left: 348px;
  top: 324px;
  border-radius: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  justify-content: center;
}
.w_buttons .w_b1 {
  background: #283891;
  color: #ffffff;
}
.w_buttons .w_b2 {
  border: 1px solid #283891;
  color: #283891;
}
.head_Wrap {
  margin-top: 60px;
}

.tran_sort h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #262626;
}
.tran_sort {
  display: flex;
  align-items: baseline;
  gap: 50px;
}
.w_filter {
  display: flex;
  gap: 50px;
}
.t_list h1,
.t_list h2,
.t_list p {
  margin-bottom: 0px;
}
.head_Wrap {
  display: flex;
  justify-content: space-between;
}
.t_deposit {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.t_type {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
}
.t_wrap_list h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #262626;
}
.t_wrap_list h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;

  color: #262626;
}

.t_date {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #828282;
}
.t_wrap_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 27px;
  gap: 16px;
}
.w_right {
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 20px;
  padding-bottom: 10px;
  width: 60%;
}
.pagechange {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 20px;
}
.pagechange p {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #283891;
}
.tittle_date {
  display: flex;
  gap: 16px;
}
.no_transaction{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.remarks{
  max-width: 250px;
  overflow: auto;
}
.remarks::-webkit-scrollbar {
  height: 2px;
}

.remarks::-webkit-scrollbar-track {
  width: 2px;
  height: 2px; 
}

@media only screen and (max-width: 1250px) {
  .w_wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .head_Wrap {
    flex-direction: column;
    justify-content: center;
  }
  .w_wrapper {
    width: 90%;
    padding: 20px 0;
  }
  .head_Wrap {
    margin-top: 40px;
    gap: 24px;
  }
  .w_left h2 {
    margin: 0;
  }
  .w_left {
    align-items: center;
  }
  .w_wrapper h1 {
    text-align: center;
  }
  .t_wrap_list h1 {
    font-weight: 500;
    font-size: 14px;
  }
  .tittle_date {
    flex-direction: column;
    gap: 0px;
  }
  .t_wrap_list h2 {
    font-size: 14px;
    line-height: 17px;
  }
  .w_right {
    width: 80vw;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 706px) {
  .w_wrapper h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -0.5px;
    text-align: start;
  }
  .t_wrap_list h1 {
    font-weight: 500;
    font-size: 14px;
  }
  .t_type {
    display: none;
  }
  .t_deposit {
    width: 32px;
    height: 32px;
  }
  .t_deposit svg {
    width: 16px;
    height: 16px;
  }
  .w_filter {
    display: none;
  }
  .w_right {
    width: 334px;
  }
  .t_wrap_list {
    gap: 9px;
  }
  .w_left h2 {
    width: 334px;
    height: 105px;
    background: #f8f9fa;
    border-radius: 12px;
    font-weight: 700;
    font-size: 32px;
    justify-content: center;
  }
  .w_left {
    gap: 24px;
  }
  .w_buttons {
    gap: 10px;
  }
  .head_Wrap {
    margin-top: 13px;
  }
}
