#InvestmentDocuments {
  margin-top: 15px;
}
.navigation-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style-type: none;
  padding: 0px 30px;
  gap: 30px;
}
#InvestmentDocuments ul {
  margin: 0px 20px;
  margin-bottom: 10px !important;
  border-bottom: 2px solid rgba(206, 212, 218, 0.5);
}
.navigation-btn button {
  border: none;
  padding: 10px 0px;
  background-color: transparent;
  font-size: 18px !important;
}
.navigation-btn .active {
  border-bottom: 4px solid #3e4c9c;
  color: #3e4c9c !important;
}
@media only screen and (width <= 800px) {
  .navigation-btn {
    padding: 0px 15px;
  }
  #InvestmentDocuments ul {
    margin: 0px 20px;
    margin-bottom: 20px !important;
    border-bottom: 2px solid rgba(206, 212, 218, 0.5);
  }
  .navigation-btn button {
    border: none;
    padding: 10px 0px;
    background-color: transparent;
    font-size: 14px !important;
    font-weight: 500;
  }
  .navigation-btn .active {
    border-bottom: none;
    color: #3e4c9c !important;
    position: relative;
    transition: all 200ms;
  }
  .navigation-btn .active::after {
    content: '';
    width: 88px;
    height: 4px;
    position: absolute;
    background: #3e4c9c;
    bottom: -2px;
    left: -15px;
  }
}
/* Queries.module.css */

.queriesContainer {
  padding: 20px;
  background-color: #ffffff; /* Light background to distinguish the section */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0); /* Subtle shadow for depth */
  max-width: 600px; /* Limit the width of the form */
  margin: 2px 50px; /* Center the form */
}

h3 {
  margin-bottom: 20px;
  color: #333; /* Dark text for the heading */
  font-weight: bold;
  font-size: 1.5rem; /* Adjust font size for readability */
  text-align: left; /* Center the heading */
}

.queryInput {
  width: 100%;
  height: 150px; /* Set a height for the textarea */
  padding: 10px;
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 20px;
  resize: vertical; /* Allow the textarea to be resized vertically */
  font-family: inherit; /* Use the same font as the rest of the app */
  resize: none;
}

.submitButton {
  display: block;
  width: 140px;
  height: 48px;
  background-color: #2c3e91; /* Primary color for the button */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  /* font-weight: bold; */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  text-align: center;
}

.submitButton:hover {
  background-color: #1b2a70; /* Darker shade on hover */
}
.message {
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: bold;
}

.successMessage {
  color: green;
  margin-top: 10px;
}

.errorMessage {
  color: red;
  margin-top: 10px;
}