.listing-container {
  padding: 0px 0px;
  padding-top: 3.5rem;
  position: relative;
}
@media only screen and (min-width: 1000px) {
  .listing-container {
    width: 100%;
    padding: 10px;
    margin-top: -2.5%;
  }
  .back-btn{
    margin-left: 0px !important;
  }
}
