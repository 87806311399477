.w_container {
  width: 80%;
  margin: 0 auto;
}
.w_wrap {
  width: 526px;
  display: flex;
  gap: 24px;
  flex-direction: column;
}
.w_wrap h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  color: #000000;
  margin: 0;
}
.w_head p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
  color: #283891;
}
.w_head span {
  font-weight: 700;
}
.w_bank h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 56px;
  margin: 0;
  color: #000000;
}
.am_amount_input {
  position: relative;
}
.am_amount label {
  position: absolute;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #283891;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}
.am_amount input {
  width: 100%;
  height: 70px;
  background: #f8f9fa;
  border: 1px solid #bfc3de;
  border-radius: 12px;
  padding: 0 34px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #283891;
}
.am_amount input:focus {
  outline: 1px solid #bfc3de;
}
.pre_amount li {
  padding: 8px 24px;
  gap: 10px;
  background: #e9ebf4;
  border-radius: 50px;
  list-style: none;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 17px; */
  display: flex;
  align-items: center;
  color: #283891;
  height: 37px;
  cursor: pointer;
}
.pre_amount .pre_active {
  background: #5360a7;
  color: #ffffff;
}
.pre_amount {
  display: flex;
  gap: 24px;
}
.am_amount {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.select_card {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.am_cards {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  /* width: 380px; */
  height: 70px;
  background: #f8f9fa;
  border-radius: 12px;
  padding: 0 10px;
}
.am_cards img {
  width: 100%;
  /* height: 38.22px; */
  object-fit: cover;
}
.am_cards p {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #262626;
}

.bank_logo {
  width: 50px;
}
.bank_details {
  display: flex;
  gap: 24px;
  align-items: center;
}
.w_wrap button {
  background: #283891;
  border-radius: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  border: none;
  justify-content: center;
  color: #ffffff;
  margin-top: 30px;
}
