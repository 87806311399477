/* Mobile first designs - All CSS without @media is written for mobile.  */

:root {
  --main-content-max-width: 100%;
}
.body-wrapper {
  margin-top: 40px;
  position: relative;
  display: flex;
  gap: 20px;
}

.body-wrapper p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.body-wrapper aside {
  width: 100%;
  height: 3rem;
  overflow-y: hidden;
  overflow-x: auto;
  display: block;
  position: fixed;
  z-index: 100;
  top: 50px !important;
  background: #ffffff;
  padding: 0px 10px;
  scroll-behavior: smooth;
}
.body-wrapper aside::-webkit-scrollbar {
  display: none;
}
.body-wrapper aside ul {
  display: flex;
  align-items: center;
  gap: 15px;
  list-style-type: none;
  position: sticky;
  top: 0;
  width: max-content;
  padding: 10px 35px 10px 25px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.body-wrapper aside li {
  display: inline-block;
  padding: 10px 0;
  padding-bottom: 4px;
  color: #000000 !important;
  list-style-type: none;
  font-weight: 400 !important;
  font-size: 14px;
  cursor: pointer;
}

.body-wrapper aside li.active {
  border-bottom: 2px solid #3e4c9c;
}
.body-wrapper aside li::before {
  content: '\2022';
  color: #6d747a;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  display: none;
}
.body-wrapper aside li.active::before {
  color: #283891;
}

.body-wrapper main {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  overflow-x: hidden;
}

.main-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: var(--main-content-max-width);
}
.side-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 325px;
}

/* Others  */
.sectionHeader {
  font-size: 24px;
  font-weight: 600;
  color: #2e3e94;
  display: inline-block;
  width: max-content;
}

.borderBottom {
  height: 2px;
  width: 110%;
  background-color: #283891;
  margin-top: 4px;
}
.left-arrow-container,
.right-arrow-conatiner {
  display: block;
  position: fixed;
  top: 50px;
}
.right-arrow-conatiner {
  right: 0px;
  transform: rotate(180deg);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  /* object-fit: cover; */
  padding-bottom: 19px;
  height: 3rem;
  z-index: 101;
}
.right-arrow-conatiner > img {
  display: block;
  object-fit: contain;
  margin-bottom: -0.4rem;
}
.left-arrow-container {
  left: 0px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  /* object-fit: cover; */
  height: 3rem;
  padding-top: 15px;
  z-index: 101;
}
.left-arrow-container > img {
  display: block;
  object-fit: contain;
  margin-bottom: 0.2rem;
}
.blur-component-children {
  min-width: 92vw;
  height: 25rem;
  background-color: transparent;
}
@media screen and (min-width: 1000px) {
  .left-arrow-container,
  .right-arrow-conatiner {
    display: none;
  }
  .blur-component-children {
    min-width: 36rem;
    height: 25rem;
    background-color: transparent;
  }
  .body-wrapper main {
    flex-direction: row-reverse;
    align-items: flex-start;
  }
  .body-wrapper aside {
    position: sticky;
    width: fit-content !important;
    height: max-content;
    overflow: hidden;
    top: var(--top) !important;
  }
  .body-wrapper aside ul {
    width: fit-content !important;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    list-style-type: none;
    margin-left: 20px;
    position: sticky;
    top: 0;
    padding: 0 !important;
    gap: 0px !important;
  }
  .body-wrapper aside li {
    padding: 12px 0;
    color: #6d747a !important;
    list-style-type: disc;
    font-weight: 500 !important;
    cursor: pointer;
    font-size: 16px;
  }

  .body-wrapper aside li.active {
    color: #283891 !important;
    font-weight: 600;
    border-bottom: 0px;
  }
  .body-wrapper aside li::before {
    display: block;
    content: '\2022';
    color: #6d747a;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  .main-content {
    --main-content-max-width: 500px;
  }
  .sectionHeader {
    color: #283891;
  }
  .body-wrapper p:has(.mt-0) {
    font-weight: 400;
  }
}
@media screen and (min-width: 1200px) {
  .body-wrapper aside {
    display: block;
  }
  .main-content {
    --main-content-max-width: 500px;
  }
}

@media screen and (min-width: 1300px) {
  .main-content {
    --main-content-max-width: 550px;
  }
}
@media screen and (min-width: 1400px) {
  .main-content {
    --main-content-max-width: 650px;
  }
}
@media screen and (min-width: 1600px) {
  .main-content {
    --main-content-max-width: 850px;
  }
}
