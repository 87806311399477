#investNowContent {
  margin-top: 40px;
  flex-wrap: wrap;
}
.modal-body > div {
  width: 858px;
}
#promo_input input {
  background: #ffffff;
  width: 100%;
  padding: 14px 16px;
  border: 1px solid #0f1f78;
  border-radius: 8px;
}
#promo_input input:focus {
  outline: #939ca3;
}
#promo_input {
  margin-bottom: 24px;
  flex-direction: column;
}
#promo_input p {
  margin: 0 4px;
}
#investNowContent h1 {
  color: #3e4c9c;
  font-size: 32px;
  font-weight: 600;
}

#aboutPortal p {
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
}

#aboutPortal {
  padding-right: 46px;
  border-right: 1px solid #ebedf0;
}
#aboutPortal img {
  border-radius: 6px;
  width: 100%;
}
.total_amount {
  font-weight: 600;
  font-size: 18px !important;
  line-height: 24px;
  color: #08090a;
  flex: none;
  order: 1;
  flex-grow: 0;
}
#lots {
  /* margin-left: 40px; */
  padding: 24px;
  background-color: #f8f9fa;
  border-radius: 20px;
}

#lotContainer {
  padding-left: 40px;
  padding-right: 0;
}

#lots label {
  font-size: 16px;
  font-weight: 500;
}

#lots input {
  border: 1px solid #ced4da;
  margin-top: 12px;
  font-size: 14px;
  padding: 14px;
}

#lots input:focus {
  box-shadow: none;
  border: 1px solid #0f1f78;
}

#recommended {
  font-size: 14px;
  font-weight: 400;
  color: #6d747a;
  text-align: right;
  display: flex;
  gap: 20px;
}

#recommended span {
  margin: 0 16px;
  cursor: pointer;
}

#investButton {
  align-self: center;
  margin-left: 5%;
  margin-top: 32px;
  width: 90%;
  
}

#dealTerms {
  padding: 24px 12px;
  background-color: #ebf8ed;
  border: 2px solid #39b54a;
  border-radius: 20px;
}

.termTextContainer {
  margin-top: 16px;
}

.termTextContainer img {
  width: 13px;
  height: 13px;
}

.termTextContainer h6 {
  color: #6d747a;
  font-size: 14px;
  font-weight: 400;
}

#termsHeader {
  color: black;
  font-size: 18px;
  font-weight: 500;
  margin-top: 4px;
  border-bottom: 1px solid black;
  display: inline-block;
}

.termsText {
  padding-bottom: 4px;
  border-bottom: 1px solid #c4e9c9;
}
.termsText h2 {
  font-size: 18px;
}
.termsText:last-child {
  border-bottom: none;
}

#modalLeft {
  background-color: #e9ebf4;
  padding-left: 36px;
  padding-top: 20px;
  padding-right: 24px;
  width: 47%;
}

.formModal {
  width: 500px;
}

#modalLeft label {
  color: #6d747a;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 12px;
}

#modalLeft input {
  background-color: #f8f9fa;
}
#modalLeft input:disabled {
  cursor: not-allowed;
}

#modalRight {
  /* padding: 24px; */
  /* padding-right: 40px; */
  /* padding-left: 35px; */
  margin-left: 17px;
}

#modalRight h5 {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 24px;
  text-transform: uppercase;

}

#modalRight button {
  font-size: 20px;
}

.startupDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.startupDetails h4 {
  margin: 0px;
  font-size: 16px;
  color: #6d747a;
}
.startupDetails h5 {
  font-size: 16px;
  color: #08090a;
}

#tds {
  font-size: 14px;
  font-weight: 400;
  color: #939ca3;
}

.submitButton {
  width: 100%;
 
  border: 0;
  background-color: #4dbc5c;
  color: white;
  font-size: 16px !important;
  font-weight: 600;
  border-radius: 8px;
  margin-top: 48px;
  margin-left: -8px;
}

#walletBalance {
  gap: 16px;
  background-color: #ebf8ed;
  padding: 45px 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.200691);
  border-radius: 12px;
}

#walletbody {
  padding-bottom: 40px;
}

#walletbody p {
  font-size: 16px;
  font-weight: 400;
  padding-top: 16px;
  padding-bottom: 24px;
  cursor: pointer;
}

#walletbody button {
  margin-top: 0;
}

#dealContainer {
  padding-left: 40px;
}

#modal {
  width: 100%;
}

#checkboxIconContainer {
  width: 24px;
  height: 24px;
  border: 1px solid #3e4c9c;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#checkboxIconContainer img {
  width: 12.5px;
  height: auto;
}

#promo {
  font-size: 16px;
  font-weight: 400;
  color: #3e4c9c;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  margin-top: 16px;
}

.balance {
  display: flex;
  align-items: center;
  gap: 8px;
}

#checkout h1 {
  color: #08090a;
  font-size: 18px;
  font-weight: 600;
}

.fc {
  border: 1px solid #ced4da;
  margin-top: 12px;
  font-size: 14px;
  padding: 14px;
}
@media only screen and (width <=1000px) {
  #user-dob {
    position: relative !important;
    right: -16px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 858px;
  }
}

/* T&C  */

#TermsAndConditionPage {
  padding: 21px;
  border-radius: 12px;
  box-shadow: 0px 1px 6px rgba(134, 134, 134, 0.25);
}

#termsAndCondition {
  overflow-y: scroll;
  /* margin: 21px; */
  padding: 24px;
  border-radius: 12px;
}

#termsAndCondition p {
  font-style: normal;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}

#termsAndCondition h3 {
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 700;
}
#termsAndCondition ul li {
  padding: 5px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

#termsAndCondition h4 {
  font-family: 'Roboto';
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 8px;
}
.personal_details {
  display: none;
}
.term_padding {
  padding: 0px !important;
}
.term_padding > div {
  width: 100%;
}
.Order-details {
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #08090a;
}
.email-input {
  background-color: red;
}
@media only screen and (width > 1000px) {
  .back-btn {
    display: none;
  }
  .modal-body > div {
    width: 858px;
  }
  .term_padding > div {
    width: 70%;
  }
  #flex_column {
    display: flex;
    align-items: center !important;
    justify-content: space-around;
    flex-direction: row !important;
    gap: 3rem;
    flex-wrap: nowrap;
  }
  .startupDetails h5 {
    margin-bottom: 0px !important;
  }
  .startupDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
  }
  .submitButton {
    margin-top: 2rem;
  }
}
#date_container {
  text-align: left;
}
#align_right {
  text-align: right;
}
#flex_column input {
  width: 100%;
}

#decline {
  margin-right: 16px;
  font-size: 14px;
  width: 150px;
  height: 60px;
}
/* https://docs.google.com/spreadsheets/d/1PpigSF3EWqSc_9_8Ix7alXH7NUN_kzltYCQnh8i5bRc/edit#gid=2073903484 */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  
  #termsAndCondition h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
  }
  #decline {
    width: 108px;
    height: 48px;
    line-height: 2px;
  }
  .accept_button {
    width: 108px !important;
    height: 48px !important;
    line-height: 2px !important;
  }
  #registerRight {
    color: #000;
  }

  #termsContainer {
    height: 90vh;
  }

  #termsAndCondition {
    max-height: 60vh;
  }
  #termsAndCondition ul {
    padding-left: 15px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #termsContainer {
    height: 90vh;
  }

  #termsAndCondition {
    max-height: 351px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #termsContainer {
    height: 100%;
  }

  #termsAndCondition {
    max-height: 425px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #termsAndCondition {
    max-height: 425px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #termsAndCondition {
    max-height: 425px;
  }
  /* #lots{
    margin-right: 90px !important;
   } */
   #lotContainer{
    margin-right: 12px;
    padding-left: 0px ;
   }
}
@media only screen and (width <=1000px) {
  .termsText h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
  }
  .hidden_mob {
    display: none;
  }
  
  #modalLeft form {
    gap: 0;
    width: 100%;
  }
  #modalLeft input{
    width: fit-content;
  }
  #modalLeft .mb-3 {
    margin-bottom: 0 !important;
  }
  #modalRight h5 {
    margin-bottom: 0;
  }
  #investNowContent h1 {
    margin-bottom: 12px;
    padding-bottom: 5px;
    font-size: 24px;
  }
  #investNowContent {
    display: flex;
    margin: 0px;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
  }
  #dealContainer {
    width: 100%;
    height: fit-content;
    padding: 0px !important;
    margin-bottom: 25px;
  }
  #dealTerms {
    width: 100%;
    height: fit-content;
    background-color: rgba(235, 248, 237, 1);
    border-radius: 0px;
    border: 0px;
  }
  .termsText {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    padding-top: 3px;
    padding-bottom: 0;
  }
  .termsText h2 {
    text-align: right;
  }
  .termTextContainer {
    margin-top: 0px;
  }

  .termsText {
    padding-bottom: 0px;
    border-bottom: none;
  }
  #lots {
    /* margin-left: 40px; */
    padding: 0px;
    background-color: #f8f9fa;
    border-radius: 0px;
  }
  #lotContainer {
    padding: 12px;
    width: 100%;
    margin-bottom: 30px;
  }
  #aboutPortal {
    padding: 12px;
    width: 100%;
  }
  #mobile_modal {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    box-shadow: none;
    left: 10px;
  }
  #modalLeft {
    background-color: #e9ebf4;
    width: 100%;
    height: fit-content;
    padding: 20px 13px;
  }
  #modalLeft div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0 !important;
  }
  #modalLeft input {
    background-color: transparent;
    width: 10rem;
    border: 0px;
    outline: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: black;
    text-align: right;
  }
  .personal_details {
    display: block;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }
  .personal_details h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
  }
  #flex_column {
    display: flex !important;
    align-items: flex-start !important;
    flex-direction: column !important;
  }
  #modalRight {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    /* left: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 13px;
    padding: 20px 13px;
    transform: translateX(-8px);
  }
  #hide_text {
    display: none;
  }
  #termsAndCondition {
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.13);
  }
  #TermsAndConditionPage {
    padding: 13px 0 !important;
    border-radius: 12px;
    box-shadow: 0px 1px 6px rgba(134, 134, 134, 0.25);
    height: 50vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  #termsAndCondition {
    max-height: 60vh;
    padding: 17px 13px !important;
    align-self: flex-start;
    margin: 0 13px;
  }
  .submitButton {
    margin-top: 16px;
  }
  #termsAndCondition ul li {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
  }
}

@media (max-width:1643) {
 #lots{
  margin-right: 90px !important;
 }
 #lotContainer{
  margin-right: 100px;
 }
}
