.user-image{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0px;
}
.user-image > img {
  display: block;
  object-fit: contain;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.main-testimonial-card {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: auto;
  text-align: center;
}
.testimonial-para p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  text-align: center;
  text-transform: capitalize;

  /* Light / 02 Greys fill/ Dark */

  color: #323338;
}
.name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  text-align: center;
  text-transform: capitalize;
  margin-bottom: 0px;

  /* Gray/900 */

  color: #101828;
}
.position {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  /* identical to box height, or 171% */

  text-align: center;

  /* Gray/500 */

  color: #667085;
}
@media only screen and (width >=1000px) {
  .main-testimonial-card {
    width: 785px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: auto;
    text-align: center;
  }
  .testimonial-para p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    /* or 29px */

    text-align: center;

    /* Light / 02 Greys fill/ Dark */

    color: #323338;
  }
  .user-image > img {
    display: block;
    object-fit: contain;
    width: 64px;
    height: 64px;
  }
  .name {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */

    text-align: center;

    /* Gray/900 */

    color: #101828;
    margin-bottom: 0px;
  }
  .position {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;

    /* Gray/500 */

    color: #667085;
  }
}
