.main-comment-card {
  width: 100%;
  margin: 12px 0px;
  display: flex;
  align-items: flex-start;
  gap: 6px;
}
.three-dots {
  width: 4rem;
  height: 2rem;
  position: absolute;
  right: 8px;
  top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}
.dots {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  /* Neutral / 300 */

  background: #b8c0cc;
}
.profile-pic > img {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.comment {
  width: 100%;
  background: #f7f8f9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  position: relative;
  gap: 8px;
}
.time > p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  /* Neutral / 500 */

  color: #64748b;
  margin: 0px;
}
.comment-text > p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.006em;

  /* Dark Gray / Dark Gray 2 */
  margin: 0px;
  color: #252c32;
}
.actions-btns {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.actions-btns p {
  margin: 0px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.2px;

  /* Neutral / 500 */

  color: #64748b;
  display: flex;
  align-items: center;
  gap: 5px;
}

.rotate-100 {
  transform: rotate(180deg);
}

.liked{
  filter: brightness(110%) saturate(120%) hue-rotate(200deg);
  width: 20px;
  height: 20px;
}
@media only screen and (width <= 900px) {
  .three-dots {
    gap: 4px;
    right: -5px;
  }
}
