.preview_wrap {
  font-weight: 600;
  font-size: 32px;
  line-height: 56px;
  color: #000000;
}
.preview_wrap h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1c1f3b;
  margin: 0;
}
.preview_wrap p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6d747a;
  margin: 0;
}
.logo_preview p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #6d747a;
}

.logo_preview svg {
  width: 92px;
  height: 92px;
  background: #d8d8d8;
  border-radius: 50%;
  padding: 23px 7px 2px;
}
.logo_preview img {
  width: 92px;
  height: 92px;
  border-radius: 50%;
  object-fit: cover;
}
.edit_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  background: #0f1f78;
  border-radius: 8px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  border: none;
  color: #ffffff;
}
.edit_btn:hover{
  background-color: transparent;
  color: #283891;
  outline: 2px solid #283891;
  transition: all 200ms;
}
.first_preview {
  gap: 20px;
  display: flex;
  justify-content: space-between;
}
.edit_btn_wrap {
  width: 30%;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-right: 70px;
}
.preview_wrap h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  color: #000000;
  margin: 0;
}
.logo_preview {
  display: flex;
  gap: 20px;
  align-items: center;
}
.preview_wrap {
  width: 765px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.preview_div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.third_preview {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.fifth_preview {
  margin-top: 6px;
}
.forth_preview {
  margin-top: 6px;
}
.second_preview {
  margin-top: 6px;
}

@media only screen and (max-width: 1000px) {
  .preview_wrap {
    margin: 20px auto;
    width: 90vw;
  }
}
@media only screen and (max-width: 549px) {
  .preview_wrap {
    width: 96vw;
  }
  .first_preview {
    flex-direction: column;
    gap: 10px;
  }
  .edit_btn_wrap {
    width: fit-content;
  }
  .edit_btn {
    width: 94px;
    height: 30px;
    background: #283891;
    border-radius: 8px;
    align-items: center;
    padding: 0;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    justify-content: center;
  }
  .logo_preview {
    flex-direction: column;
    align-items: flex-start;
  }
  .third_preview {
    flex-direction: column;
    align-self: start;
    gap: 20px;
  }
  .preview_wrap {
    gap: 20px;
  }
  .preview_wrap h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    margin-top: 8px;
  }
  .preview_wrap h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
  .preview_wrap p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  .userType {
    display: none;
  }
  .preview_div {
    gap: 10px;
  }
  .logo_preview img {
    width: 86px;
    height: 86px;
  }
}
