.Nav_contain {
  position: fixed;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
  width: 100%;
  top: -0.5px;
  z-index: 1000;
  left: 0;
}
.nav_wrap {
  width: 90%;
  margin: 0 auto;
}
.logo1 img {
  width: 120px;
}
.first {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.logo1 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.first p {
  margin: 0;
}
.slide_wrap {
  width: 90%;
  margin: 0 auto;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.subname {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  /* identical to box height, or 146% */

  /* Blacks/Darker grey */

  color: #6d747a;
}
.profileimg {
  height: 46.5px;
  width: 46.5px;
  background: #d8d8d8;
  padding: 10px 2px 0px;
  border-radius: 50%;
  /* border: 3px solid #939ca3; */
}

.name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-transform: capitalize;
  color: #000000;
}
.slide_logo img {
  width: 100px;
  height: 35px;
}
.slide_user {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 14px 0;
  padding-left: 15px;
  margin: 17px 0;
  background: #f8f9fa;
  border-radius: 14px;
}
.userdetail {
  display: flex;
  flex-direction: column;
}
.slide_details p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Blacks/Darker grey */

  color: #6d747a;
}
.second {
  /* border: 1px solid #ebedf0; */
  /* box-shadow: 0px 0px 11px rgb(198 198 198 / 25%); */
  border-radius: 20px;
  padding: 24px 0;
  background-color: #ffffff;
}
.detail1 {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-bottom: 23px;
  padding-left: 21px;
  border-bottom: 2px solid #ebedf0;
}
.detail2 {
  border-bottom: 2px solid #ebedf0;
  padding-bottom: 23px;
}

.slide_details p {
  margin-bottom: 0;
}
#log_out {
  display: flex;
  gap: 14px;
}
.detail2,
.detail3 {
  flex-direction: column;
  display: flex;
  gap: 18px;
  padding-top: 23px;
  padding-left: 21px;
}
.detail1 a {
  display: flex;
  gap: 14px;
}
.detail2 a,
.detail2 div {
  display: flex;
  gap: 14px;
}
.slider_das {
  position: fixed;
  z-index: 1000;
  width: 260px;
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
}
.menu_list.active svg,
.menu_list:hover svg {
  fill: #283891;
  transition: transform 300ms;
  transform: scale(1.2);
}
.menu_list:hover p,
.menu_list.active p {
  color: #283891;
}
.profileImages {
  border: 50%;
}
.profileImages img {
  width: 46.5px;
  height: 46.5px;
  border-radius: 50%;
  object-fit: cover;
}
@media only screen and (max-width: 600px) {
  .first {
    padding: 10px 0;
  }
  .logo1 {
    margin-right: 27px;
  }
}
@media only screen and (max-width: 376px) {
  .logo1 img {
    width: 100px;
    height: 36px;
  }
}

/* margin-top: 15px;
    max-height: 670px;
    overflow: hidden;
    margin-bottom: 30px; */
