.about-startup-wrapper {
  padding: 15px;
}

.about-startup-wrapper p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;

  color: #000000;
}

.list-item {
  margin-top: 15px;
  display: flex;
  gap: 10px;
}
.list-item p {
  color: #6d747a;
}
.checked-circle {
  width: 24px;
  height: 24px;
}

@media screen and (min-width: 700px) {
  .checkCircle {
    width: 24px;
    height: 24px;
  }

  #aboutStartup p {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }

  .listItem {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 0;
  }

  .listItem p {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    color: #6d747a;
  }
}
