.alertCont {
  padding: 24px 0;
  width: 100%;
}

.alert {
  background-color: #fdf8eb;
  color: #dea700;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.btnGold {
  display: inline-block;
  padding: 5px 14px;
  border: 1px solid #dea700;
  color: #dea700;
  border-radius: 50px;
  background: transparent;
}

#close {
  color: #dea700;
  border: 0;
}

#iconContainer {
  padding: 0 35px;
}
/* 
#raiseCapital {
    font-size: 14px !important;
} */
@media only screen and (width <=1000px) {
  #iconContainer {
    display: none !important;
  }
  .alertCont {
    padding: 0px;
    width: 100%;
  }
  .alert {
    font-size: 12px;
    margin: 0px 7px;
    margin-top: 18px;
    padding: 0 7px;
    height: 54px;
  }
  .alert-right-container {
    display: flex;
  }
  .kyc-msg-text {
    font-size: 11px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0px 5px !important;
  }
  .warning-icon {
    margin: 0px !important;
  }
  .kyc-right-container {
    display: flex;
    align-items: center;
    font-size: 11px;
  }
  .btnGold {
    width: 6rem;
    padding: 0.2em 0.4em;
    font-size: 10px;
  }
  #close {
    padding: 0px 5px;
    padding: 0px 5px;
    font-size: 23px !important;
    font-weight: 200;
  }
}
