#login-right p {
  margin-top: 18px;
  margin-bottom: 12px;
  font-weight: 600;
  color: #000;
}

#emailOrPhone {
  margin-bottom: 16px;
}

#or {
  margin: 12px !important;
}

@media screen and (max-width: 768px) {
  #or {
    margin: 0px !important;
  }
  
}
#continue {
  margin: 0 !important;
}

#nextButton {
  display: none !important;
}

#LoginEmail {
  padding: 40px;
}



#googleSignIn {
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
}

#googleSignIn:hover {
  background-color: #283891;
}
@media only screen and (width <= 650px) {
  #LoginEmail {
    padding: 10px 5px;
  }
}
