.app {
  padding: 0px 68px 0px 68px;
}

.btn-view {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  width: 128px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #0f1f78;
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #0f1f78;
}

#root {
  max-width: 2000px;
  margin: auto;
}

/* cards */

.card {
  height: 500px;
  /* width: 480px; */
  margin-bottom: 37px;
}

.card-title {
  font-style: normal;
  font-weight: bolder;
  font-size: 18px;
  line-height: 27px;
  color: #08090a;
  padding-top: 45px;
}

/* .card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #6d747a;
  padding-bottom: 70px;
} */

.card:hover .card-text {
  padding-bottom: 0;
}

/* .card .btn-custom {
  height: 28px;
  padding: 4px 8px;
  margin-right: 8px;
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  text-transform: uppercase;
  color: #283891;
  background: #e9ebf4;
  border-radius: 4px;
} */

.card .profile {
  position: absolute;
  top: 208px;
  left: 20px;
}

.card .closed {
  position: absolute;
  width: 88px;
  height: 32px;
  background: #ebedf0;
  border-radius: 4px;
  margin-top: 17px;
  margin-left: 16px;
  padding: 4px 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #08090a;
}

.accordion {
  /* padding: 0px 260px 70px 260px; */
}

.accordion-button:active,
.accordion-button:focus {
  background-color: white;
  color: #283891;
  box-shadow: none;
}

.footer {
  height: 220px;
  background: #283891;
  margin: 0px;
  padding: 0%;
}

.footer .text {
  padding: 115px 0px 89px 0px;
  color: white;
  display: flex;
  justify-content: center;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.content {
  margin-left: 126px;
}

.app {
  /* padding: 0px 68px 0px 68px; */
  font-family: 'Poppins', sans-serif;
}

.btn-outline-dark-blue {
  padding: 16px 26px;
  background-color: #fff;
  border: 1px solid #0f1f78;
  color: #0f1f78;
  border-radius: 8px;
  font-weight: 600;
}

.btn-outline-dark-blue:hover {
  background-color: #0f1f78;
  color: white;
  transition: all 0.3s;
}

/* navbar */

.navbar-brand {
  margin-top: 16px;
}

.nav-link,
.nav-link:disabled {
  padding-right: 32px;
  font-size: 16px;
  font-weight: 500;
  color: #6d747a;
}

.nav .btn-custom {
  margin-left: 8 px;
  background-color: #39b54a;
  color: #e9ebf4;
  padding: 16px 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}

/* heading */

.heading {
  padding: 68px 0 24px 0;
}

.heading .title {
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  color: #000000;
}

.heading .sub-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #6d747a;
}

input[type='text']:focus {
  border: 0;
  box-shadow: none;
}

/* options */

.options {
  background: #f8f9fa;
  border-radius: 12px 12px 0px 0px;
  padding: 24px;
  gap: 16px;
  color: #6d747a !important;
}

option {
  background-color: white;
}

#revenueOptions {
  width: 115%;
}

#search:focus {
  border: 0 !important;
}

.input-group {
  height: 40px;
}

.search-icon {
  position: absolute;
  top: 3px;
  bottom: 0;
  left: 18px;
  margin: auto;
  width: 16px;
  height: 16px;
}

#search-text {
  padding-left: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 272px;
  text-align: center;
}

.options .btn {
  background-color: white;
  padding: 7px 24px 7px 24px;
  color: #6d747a;
}

.options .btn:hover {
  border: 1px solid transparent;
}

.options .btn:focus {
  border: 1px solid transparent;
}

/* live */

.livedeals {
  padding-top: 32px;
}

.livedeals .title {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
}

.livedeals .sub-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #6d747a;
  padding-bottom: 24px;
}

/* cards */

.card {
  /* height: 472px;
      width: 410px; */
  /* margin-bottom: 37px; */
}

.card-footer {
  background-color: transparent;
  border-top: 0;
  /* padding-bottom: 12px; */
  position: absolute;
  bottom: 0;
}

.card-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #08090a;
  padding-top: 43px;
}

.card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #6d747a;
}

.list-group-item {
  color: #08090a;
}

.card-body {
  padding-top: 0;
  background-color: #fff;
}

.card:hover .btn-custom {
  display: none;
}

.card {
  overflow: hidden;
}

.card-img-top {
  position: relative;
}

.card:hover .card-body {
  top: -130px;
}

.card:hover .card-img-top {
  top: -5rem;
}

.card .btn-custom {
  padding: 4px 8px;
  margin-right: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  color: #283891;
  background: #e9ebf4;
  border-radius: 4px;
  margin-bottom: 0;
}

.card .profile {
  position: absolute;
  top: -32px;
  left: 16px;
}

.card .closed {
  position: absolute;
  width: 88px;
  height: 32px;
  background: #ebedf0;
  border-radius: 4px;
  margin-top: 17px;
  margin-left: 16px;
  padding: 4px 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #08090a;
  z-index: 1;
}

/* faq */

.faq {
  padding-top: 120px;
}

.faq .title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #283891;
}

.accordion {
  --bs-accordion-btn-focus-border-color: none;
  --bs-accordion-btn-focus-box-shadow: none;
}

hr {
  margin-right: 16px;
  height: 34px;
  width: 3px;
  background: #a5a5a5;
  margin-top: 9px;
}

.accordion-body {
  padding-top: 16px;
}

.accordion-button {
  margin-bottom: 0;
  margin-top: 0;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.accordion-button:not(.collapsed)::after {
  background-image: url('./Assets/expanded-arrow.png');
  transform: rotate(0deg) !important;
}

.accordion-button {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 8px;
  line-height: 27px;
  color: #49516a;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
 
}

@keyframes accordion-expanded {
  0% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes accordion {
  0% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(0);
  }
}

.accordion-button[aria-expanded='true'] {
  color: #283891;
  background-color: white;
  border: 0 !important;
}

.accordion-body {
  padding-top: 24px;
  font-size: 16px;
  font-weight: 400;
}

.footer {
  /* height: 220px; */
  background: #283891;
  margin: 0px;
  padding: 0%;
}

.footer .text {
  padding: 107px 0px 89px 0px;
  color: white;
  display: flex;
  justify-content: center;
}
#wealtmanager-page-swiper-navigator {
  bottom: 60px;
}
@media only screen and (width <= 800px) {
  #wealtmanager-page-swiper-navigator {
    bottom: 80px;
  }
}
@media only screen and (width <=1000px) {
  #wealthmanager-navigation-btn-right {
    display: none;
  }
  #wealthmanager-navigation-btn-left {
    display: none;
  }
}
/* accordion */
@media only screen and (max-width: 445px) {
  .accordion-button_mob {
    font-size: 16px;
  }
  .accordion-body_mob {
    font-size: 14px;
    padding-top: 10px;
  }
}


@media (min-width:1360px) {
  .container_maxWidth_1440{
    width: 100%;
    padding: 0 calc(50vw - 720px);
  }}



  .back_image{
    width: 100vw;
    object-fit: cover;
}