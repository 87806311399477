.borderImg {
  position: absolute;
  width: 85px;
  height: 85px;
  z-index: 1;
}

#insights {
  left: -8%;
  top: 43% !important;
}

#barchart {
  right: 6.5%;
  top: 11%;
}

.carContainer {
  position: relative;
  width: 533px;
  height: 200px;
}

#sliderContainer {
  box-shadow: 0 0 5px #ddd !important;
  border-radius: 35px;
  padding-top: 0px !important;
  padding-bottom: -10px !important;
  width: 462px;
  height: 492px;
  padding-left: 19px;
  padding-right: 19px;

}

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {
  .carContainer {
    position: relative;
    width: 351px;
    height: 533px;
  }
}
 */
/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
  .carContainer {
    position: relative;
    width: 351px;
    height: 533px;
  }
} */


/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {
  .carContainer {
    position: relative;
    width: 533px;
    height: 533px;
  }
} */

@media (min-width: 300px) and (max-width: 679px) {
  .carContainer {
    width: 80% !important;
    margin: auto;
  }
  #sliderContainer {
    padding: 1px !important;
  }
}
@media (min-width: 300px) and (max-width: 990px) {
  .carContainer {
    width: 100% !important;
    margin: auto;
  }
   #sliderContainer {
    padding: 1px !important;
    width: 362px;
    height: 492px;
  
  }
  #sliderContainer{
    border-radius: 0px !important;
  }
  #insights{
    display: none;
  }
  #barchart{
    display: none;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .carContainer {
    position: relative;
    width: 533px;
    height: 533px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .carContainer {
    position: relative;
    width: 533px;
    height: 533px;
  }
}
@media only screen and (width <=700px) {
  #sliderContainer{
    border: none;
  }
  .carContainer{
    height: fit-content;
    padding: 12px;
    box-shadow: 0px 0px 3.48187px rgba(0, 0, 0, 0.25);
  }
}