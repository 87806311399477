.gettouch{
    width: 100%;
    height: 100%;
    margin-top: 150px;
    margin-bottom: 100px;
}

.gettouch_heading h1{
    text-align: center;
    font-weight: 600;
    color: #0F1F78;
    font-size: 40px;
}
.gettouch_heading h3{
    text-align: center;
    font-weight: 500;
    color: #6D747A;
    font-size: 16px;
    padding-top: 4px;
}

.gettouch_wrapp{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.gettouch_wrapper{
    width: 80%;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.25); 
    display: flex;
    justify-content: center;

}
.inputs_gettouch{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.whole_gettouch_wrapper{
    width: 60%;
    padding-top: 30px;
    padding-bottom: 30px;
}
.input_01{
    display: flex;
    flex-direction: column;
}
.input_02{
    display: flex;
    gap: 8;
    flex-direction: column;
}
.input_01 label{
    font-size: 16px;
    color: #08090A;
    font-weight: 600;
   
}
.input_02 label{
    font-size: 16px;
    color: #08090A;
    font-weight: 600;
   
}

.input_01 input{
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    margin-top: 10px;
    width: 300px;
    border: 1px solid #CED4DA;
}

.input_01 input::placeholder{
    color: #6D747A;
    font-weight: 400;
}

.input_02 input{
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    margin-top: 10px;
    width: 300px;
    border: 1px solid #CED4DA;
}

.input_02 input::placeholder{
    color: #6D747A;
    font-weight: 400;
}

.gettouch_send{
    width: 100%;
    height: 48px;
    background-color: #283891;
    color: white;
    font-weight: 500;
    border-radius: 8px;
    margin-top: 33px;
    border: none;
}



@media (max-width:769px) {
    .gettouch_wrapper{
        width: 100%;
        border-radius: 8px;
        box-shadow: none; 
        display: flex;
        justify-content: center;
    
    }
    .inputs_gettouch{
        display: flex;
        /* justify-content: space-between;
        align-items: center; */
        flex-direction: column;
    }
    .whole_gettouch_wrapper{
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .input_01{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .input_02{
        display: flex;
        gap: 8;
        flex-direction: column;
        width: 100%;
    }
    .input_02 label{
        font-size: 16px;
        color: #08090A;
        font-weight: 600;
       
    }
    
    .input_01 input{
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 8px;
        margin-top: 10px;
        width: 100% !important;
        border: 1px solid #CED4DA;
    }
    
    .input_01 input::placeholder{
        color: #6D747A;
        font-weight: 400;
    }
    
    .input_02 input{
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 8px;
        margin-top: 10px;
   width: 100%;
        border: 1px solid #CED4DA;
    }
    .gettouch_heading h1{
        text-align: center;
        font-weight: 600;
        color: #0F1F78;
        font-size: 24px;
    }
    .gettouch_heading h3{
        text-align: center;
        font-weight: 500;
        color: #6D747A;
        font-size: 16px;
        padding-top: 4px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .input_02{
        display: flex;
        gap: 8;
        flex-direction: column;
        margin-top: 15px;
    }
    
}