.main-community-container {
  padding: 1rem 6rem;
}
.top-container {
  width: 100%;
  height: 35rem;
  padding: 2rem;
  overflow: hidden;
  margin-bottom: 3rem;
}
.bottom-container {
  width: 100%;
  height: 35rem;
  padding: 2rem;
  overflow: hidden;
  margin-bottom: 3rem;
}
.top-container-heading h1,
.bottom-container-heading h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  /* Grey/Darkest */

  color: #08090a;
}
.sub-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* Blacks/Darker grey */

  color: #6d747a;
}
.community-table {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 1.5rem 4rem;
}
.community-table > table {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.community-table thead {
  width: 100%;
  height: 44px;
  background: #d4d7e9;
  text-align: center;
}
.community-table tbody {
  height: 33rem;
  padding: 0px 8px;
  margin-bottom: 18px;
  overflow: auto;
}
.community-table tbody tr {
  border-top: 1px solid #d4d7e9;
  
}
.community-table tbody tr:first-child {
  border-top: none;
}
.community-table thead tr th:first-child {
  border-radius: 16px 0px 0px 16px;
}
.community-table thead tr th:last-child {
  border-radius: 0px 16px 16px 0px;
}
.community-table th {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* Grey/Darker */

  color: #6d747a;
}
.community-table td {
  padding: 16px 18px;
  text-align: center;
  min-width: 10rem;
}
.text-start {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  text-align: start !important;
  min-height: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Grey/Darkest */

  color: #08090a;
}
.sub-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Blacks/Darker grey */

  color: #6d747a;
}
.data-3,
.data-4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* Grey/Darker */

  color: #6d747a;
}
.data-2 > div {
  width: 100%;
  height: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-image {
  width: 24px;
  height: 24px;
  object-fit: contain;
  border-radius: 50%;
  position: absolute;
  border: 1.5px solid #ffffff;
  /* top: 50%;
    transform: translate(-50%, -50%); */
  left: calc(var(--position) * 20px);
}
#remaining-image {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  justify-content: center;

  /* Primary/100 */

  color: #283891;
  background: #f9f5ff;
  /* White */

  border: 2px solid #ffffff;
}
.testimonials-container {
  width: 100%;
  height: 30rem;
  overflow: auto;
  padding: 1.5rem 4rem;
}
.testimonial-card {
  width: 100%;
  min-height: 3rem;
  border-bottom: 1px solid #d4d7e9;
  margin-bottom: 24px;
}
.des {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* Grey/Darkest */

  color: #08090a;
}
.user-name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* Grey/Darker */
  text-align: right;
  color: #6d747a;
}
@media only screen and (width <= 800px) {
  .community-table tbody tr:first-child {
    border-top: 1px solid #d4d7e9;
  }
  .main-community-container {
    padding: 2.5rem 0.6rem;
  }
  .top-container {
    padding: 0px;
    margin-bottom: 0px;
  }
  .top-container-heading h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    /* Grey/Darkest */

    color: #08090a;
  }
  .sub-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    /* Blacks/Darker grey */

    color: #6d747a;
  }
  .community-table {
    padding: 0px;
    padding-bottom: 6rem;
  }
  .community-table table thead {
    display: none;
  }
  .community-table table tbody tr {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 3rem;
    padding-bottom: 2rem;
  }
  .community-table table tbody td {
    padding: 12px 0px;
  }
  .community-table table tbody td:first-child {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  .data-2 {
    min-width: 9rem !important;
    position: relative;
  }
  .data-3,
  .data-4 {
    min-width: 3rem !important;
    width: 3rem !important;
    position: relative;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    /* Grey/Darker */

    color: #6d747a;
  }
  .data-2::before {
    content: 'Users';
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    /* Primary/80 */

    color: #5360a7;
    position: absolute;
    top: -4px;
    left: 0px;
  }
  .data-3::before {
    content: 'Views';
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    /* Primary/80 */

    color: #5360a7;
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%);
  }
  .data-4::before {
    content: 'Comments';
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    /* Primary/80 */

    color: #5360a7;
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%);
  }
  .text-start {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Grey/Darkest */

    color: #08090a;
  }
  .sub-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Blacks/Darker grey */

    color: #6d747a;
  }
  .bottom-container {
    padding: 1rem 0px;
  }
  .bottom-container-heading h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    /* Grey/Darkest */

    color: #08090a;
  }
  .sub-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    /* Blacks/Darker grey */

    color: #6d747a;
  }
  .testimonials-container {
    padding: 1.5rem 0px;
  }
  .des {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    /* Grey/Darkest */

    color: #08090a;
  }
  .user-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    /* Grey/Darker */

    color: #6d747a;
    text-align: left;
  }
}
