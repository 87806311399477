#registerEmail {
  /* padding-top: 25px; */
  padding-left: 61px;
  padding-right: 70px;
  height: 100%;
  gap: 3px;
}

#registerEmail input::-ms-input-placeholder {
  font-weight: 700 !important;
}

#or {
  font-size: 17px !important;
  font-weight: 700 !important;
  line-height: 16px !important;
  margin: 12px 0;
}

#google {
  font-weight: 600 !important;
  /* color: #0F1F78; */
}

#signUp {
  font-weight: 600 !important;
  font-size: 15px;
  margin-top: 16px !important;
}

#registerEmail h1 {
  padding-bottom: 24px;
  padding-top: 16px;
  color: #000000;
}


.emailOrMobile {
  margin-bottom: 16px;
}

.emailNext {
  font-size: 14px;
  font-weight: 600 !important;
}

#handleGoogleRegister {
  font-size: 14px;
  font-weight: 600;
}
.Desc {
  color: #6d747a;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  #registerEmail h1 {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 32px !important;
  }

  #registerEmail h6 {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 32px !important;
    margin: 0px;
  }
  #registerEmail {
    gap: 12px;
  }
}

@media only screen and (max-width: 475px) {
  #registerEmail {
    gap: 1px;
    padding-top: 10px;
  }
  .head_one {
    margin-bottom: 15px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #registerEmail h1 {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 32px !important;
  }

  #registerEmail h6 {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 32px !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #registerEmail h1 {
    font-size: 29px !important;
    font-weight: 700 !important;
    line-height: 32px !important;
  }

  #registerEmail h6 {
    font-size: 19px !important;
    font-weight: 500 !important;
    line-height: 32px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #registerEmail h1 {
    font-size: 29px !important;
    font-weight: 700 !important;
    line-height: 32px !important;
  }

  #registerEmail h6 {
    font-size: 19px !important;
    font-weight: 500 !important;
    line-height: 32px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #registerEmail h1 {
    font-size: 29px !important;
    font-weight: 700 !important;
    line-height: 32px !important;
  }

  #registerEmail h6 {
    font-size: 19px !important;
    font-weight: 500 !important;
    line-height: 32px !important;
  }
}
