.accoutning_heading{
    width: 100%;
}
.accoutning_heading h1{
    text-align: center;
    font-size: 40px;
    color: #0F1F78;
    font-weight: 600;
}
.accoutning_heading h2{
    text-align: center;
    font-size: 16px;
    color: #6D747A;
    font-weight: 500;
    padding-bottom: 15px;
}
.main2{
    margin-top: 160px;
}

@media (min-width:1540px) {
    .main2{
        margin-top: 6px;
    }
    .package_wrapper_list_2{
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 30px;
    }
    
}

.main{
    margin-bottom: 0px;
}
.accoint_list1{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 10px;
    
}
.card_list_1{
    border-left: 4.1px solid #D4D7E9;
    display: flex;
    align-items: center;
    margin-left: 30px;
    height: 48px;
    max-height: 48px;

}
.card_list_1 p{
margin-top: 12px;
font-size: 20px;
font-weight: 400;
padding-left: 13px;
color: #283891;
}

.accoint_list2{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 10px;
}

.main3{
    margin-top: 100px;
    position: relative;
    overflow-x: hidden;
    max-width: 1,440px;

}

.Package_headeing{
    width: 100%;
}
.Package_headeing h1{
    font-size: 40px;
    font-weight: 600;
    color: #283891;
    text-align: center;
}
.Package_headeing h2{
    font-size: 16px;
    text-align: center;
    color: #6D747A;
    font-weight: 500;
}

.main_package_bg{
    width: 100%;
    height: 100%;
    margin-top: -30px;
}

.main_package_bg img{
    /* width: 100%; */
   
    
}
.mian_package_image_green{
 transform: translateX(-1045px); 
}
.package_wrapper_list{
    position: absolute;
    top: 5%;
    width: 100%;
}
.back_image{
    width: 100vw;
    object-fit: cover;
    margin-top: 80px;
}

.package_wrapper_list_2{
    width: 100%;
    display: flex;
    margin-top: 50px;
    justify-content: space-around;
}

.package_card_02{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:291px !important;
    background-color: white !important;
    /* border: 1px solid #0F1F78; */
    box-shadow: 0 4px 4px 0 rgba(40, 56, 145, 0.2), /* X, Y, Blur, Spread, Color */
    /* Second drop shadow */
    0 -1px 1px 0 rgba(40, 56, 145, 0.1);
    height:484px;
}

.package_image{
    display: flex;
    justify-content: center;
}

.main4{
    position: absolute;
    bottom: 16%;
    width: 100%;
}

@media (min-width:1539px) {
    .main4{
        position: absolute;
        bottom: 18%;
        width: 100%;
    }
    .main8{
        margin-top: 100px;
    }
    
}

.main5{
    width: 100%;
    height: 100%;
    position: relative;
    /* margin-top: -160px; */
}

.package_wrapper_list_3{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.package_wrapper_list2{
    width: 100%;
    display: flex;
    margin-top: 50px;
    justify-content: space-around;
}

.main6{
    width: 100%;
    height: 100%;
    margin-top: 100px;
    margin-bottom: 70px;
}

.software_heaing{
    width: 100%;
}
.software_heaing h1{
    font-size: 40px;
    color: #0F1F78;
    font-weight: 600;
    text-align: center;
    /* padding-bottom: 25px; */
}

.company_icons{
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-left: 100px;
    padding-right: 100px;
}


.main8{
    margin-top: 10px;
}

.company_container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.main7{
    display: block;   
}

.company_main{
    display: flex;
    justify-content: space-around;
    gap: 32px;
    margin-top: 30px;
}
.main10{
    margin-top: -30px;
}
.main08{
    display: none;
}

.mobile_view_client{
    display: none;
}

.main07{
    display: none;
}







@media (max-width:769px) {
    .main2{
        margin-top: 65%;
    }
    
    .accoutning_heading h1{
        text-align: center;
        font-size: 24px;
        color: #0F1F78;
        font-weight: 600;
    }
    .accoutning_heading h2{
        text-align: center;
        font-size: 16px;
        color: #6D747A;
        font-weight: 500;
        padding-bottom: 15px;
        padding-top: 10px;
    }
    .acoount_main{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .accoint_list1{
        display: block;
        justify-content: center;
        align-items: center;
        gap: 30px;
        margin-bottom: 10px;
        padding-left: 10%;
        
    }
    .accoint_list2{
        display: block;
        justify-content: center;
        align-items: center;
        gap: 30px;
        margin-bottom: 10px;
        padding-left: 10%;

        
    }
    .card_list_1{
        border-left: 4.1px solid #D4D7E9;
        display: flex;
        align-items: center;
        margin-left: 30px;
        height: 48px;
        max-height: 48px;
        margin-top: 5px;
    
    }
    .card_list_1 p{
    margin-top: 12px;
    font-size: 14px;
    font-weight: 400;
    padding-left: 13px;
    color: #283891;
    }
    .Package_headeing{
        width: 100%;
    }
    .Package_headeing h1{
        font-size: 24px;
        font-weight: 600;
        color: #283891;
        text-align: center;
    }
    .Package_headeing h2{
        font-size: 16px;
        text-align: center;
        color: #6D747A;
        font-weight: 500;
        padding-top: 10px;
    }
    .package_wrapper_list_2{
        width: 100%;
        display: block;
        margin-top: 50px;
        padding-left: 15%;
    }
    .package_card_02{
        margin-top: 30px;
    }
    
.main4{
    position: relative;
    margin-top: 270%;
    width: 100%;
}
.acoount_main{
    width: 100%;
    display: block;
    justify-content: center;
    flex-direction: column;
}
.package_wrapper_list_3{
    width: 100%;
    display: block;
    justify-content: space-around;
    padding-left: 10% !important;
}
.main5{
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 0px;
}

.software_heaing{
    width: 100%;
}
.software_heaing h1{
    font-size: 24px;
    color: #0F1F78;
    font-weight: 600;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
}
.company_icons {
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
}

.company_icons img {
    flex: 0 0 calc(33.33% - 10px); 
    margin: 5px; 
}
.company_wrapper{
    width: 100%;
    display: flex;
    overflow-x: scroll;
}

.company_main {
    flex-wrap: nowrap; 
}
.main8{
    display: none;
}
.main08{
    margin-top: 10%;
    display: block;
    overflow: hidden;
}
.mobile_view_client{
    display: block;
}
.main7{
    display: none;
}
.main07{
    display: block;
}
}