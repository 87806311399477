.main-blur-info-container {
  position: relative;
  min-width: 45% !important;
  width: fit-content !important;
  padding: 1rem;
  /* overflow: hidden; */
}
.msg-btn-container {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: black;
  z-index: 100;
  gap: 1.4rem;
}
.msg-btn-container p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 32px;
  /* or 133% */

  text-align: center;

  color: #000000;
}
.login-btn {
  all: unset;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;

  width: 336px;
  height: 48px;

  /* Primary/100 */

  background: #283891;
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  text-align: center;

  /* Blacks/White */
  text-transform: uppercase;

  color: #ffffff;
  box-sizing: border-box;
}
.lock-icon {
  width: 3.8rem !important;
  height: 3.8rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: 1px solid #0f1f78;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
.lock-icon svg {
  position: static !important;
  transform: none !important;
  width: 20px !important;
  height: 27px !important;
}
