#paymentSuccessContainer {
  background-color: #f8f9fa;
  border-radius: 20px;
  margin-top: 62px;
  padding: 45px 0;
}

#paymentSuccessContainer img {
  width: 87px;
}

#paymentSuccessContainer h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin-top: 22.25px;
  margin-bottom: 12px;
}

#paymentSuccessContainer h3 {
  color: #6d747a;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 31px;
}

#paymentSuccessContainer button {
  padding: 16px 48.5px;
  background-color: #4dbc5c;
  color: white;
  border-radius: 8px;
  border: 0;
  margin-bottom: 16px;
}

#backHome {
  color: #3e4c9c;
  margin-top: 10px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline !important;
  text-underline-offset: 4px;
}

#close {
  color: #dea700;
}

#iconContainer {
  padding: 24px 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.download-btn {
  padding: 16px 24px;
  border: none;
  color: white;
  margin-top: 20px;
  background: #4dbc5c;
  border-radius: 8px;
}

.try-again-btn {
  padding: 16px 24px;
  border: none;
  color: white;
  margin-top: 20px;
  background: #4dbc5c;
  border-radius: 8px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.wrapper h1 {
  font-weight: bolder;
}
.payment_verify h5 {
  color: #6d747a;
}
@media screen and (max-width: 975px) {
  #isPaymentVerifiedContainer {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem 1.5rem;
  }
  .payment_verify {
    width: fit-content !important;
    padding: 4rem 0px;
    background: #f8f9fa;
    border-radius: 20px;
  }
}

@media screen and (max-width: 600px) {
  #iconContainer {
    padding: 24px 20px;
  }
  #iconContainer .logo {
    height: 70px;
    width: auto;
  }
  .payment_verify h5 {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 0;
  }
  .payment_verify h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 0;
  }
  .payment_verify svg {
    width: 44px;
    height: 44px;
  }
  .download-btn {
    width: 294px;
    height: 48px;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
    font-weight: 600;
    margin: 0;
  }
}

@media screen and (max-width: 475px) {
  #isPaymentVerifiedContainer {
    width: fit-content !important;
  }
  .wrapper {
    gap: 16px;
    padding: 0 20px;
  }
}
