#registerPass {
  padding-top: 16px;
  padding-left: 56px;
  padding-right: 78px;
  height: 70vh;
}

#LoginRight {
  padding: 40px;
}
input[type="password"]
{
  -webkit-text-security: disc;
}

#password {
  position: relative;
}

#passwordField {
  -webkit-text-security: none;
}

#eye {
  position: absolute;
  top: 16px;
  right: 4%;
  cursor: pointer;
  transform: scaleX(-1);
  z-index: 999;
}

#check {
  display: none;
}

.fa-eye-slash:before {
  font-size: 15px !important;
}

#input {
  /* margin-top: 5rem; */
}

#requestOtp {
  font-size: 14px;
}

#requestOTP {
  opacity: 1 !important;
}
#requestOTP:disabled {
  opacity: 0.5 !important;
  pointer-events: none;
}
@media only screen and (width <=700px) {
  #LoginRight{
    padding: 10px 5px;
    background-color: white;
    border: 0;
  }
}