#loginRight {
  /* padding: 40px; */
  background-color: #f8f9fa;
}

#loginLeft {
  background: #283891;
  border-radius: 12px 0 0 12px;
  padding: 63px 0;
}

#loginLeft p {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

#loginImage {
  width: 250px;
  height: 250px;
}

#loginRight input:focus {
  box-shadow: none;
}
@media only screen and (width <= 650px) {
  #main_login_container {
    width: 100%;
    height: fit-content;
    margin: auto !important;
  }
  #loginRight {
    /* padding: 40px; */
    background-color: transparent;
  }
}
@media only screen and (max-width: 390px) {
  #main_login_container {
    padding: 1vw;
  }
}
