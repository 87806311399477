.company-document-wrapper {
  background: #f8f9fa;
  padding: 15px;
}
.company-document-wrapper h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  text-decoration: underline;
  padding: 0px;
  color: #000000;
}
.second_wrap{
  border-bottom: 1px solid #000000;
  width: 70%;
  height: 28px;
}
.document {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 13px 0;
}
.document img {
  height: 20px;
}
.document h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  margin: 0;

  color: #0f1f78;
}
.border {
  height: 1px;
  width: 100%;
  background-color: #ced4da;
}

@media screen and (min-width: 700px) {
  .company-document-wrapper h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
  }
  .document h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    margin: 0;
  
    color: #0f1f78;
  }
}
