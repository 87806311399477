/* #TermsAndConditionPage {
    padding: 21px;
    border-radius: 12px;
    box-shadow: 0px 1px 6px rgba(134, 134, 134, 0.25);
} */

/* #termsAndCondition {
    overflow-y: scroll;
    /*  margin: 21px; */
/* padding: 24px;
    border-radius: 12px;
  }  */
/* 
#termsAndCondition p {
    font-style: normal;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
} */

/* #termsAndCondition h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
} */

/* #termsAndCondition h4 {
  font-family: "Roboto";
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 8px;
} */

/* #decline {
    margin-right: 16px;
} */

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {
  #registerRight {
    color: #000;
  }

  #termsContainer {
    height: 90vh;
  }

  #termsAndCondition {
    max-height: 55vh;
  }
  } */

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
    #termsContainer {
      height: 90vh;
    }
  
    #termsAndCondition {
      max-height: 351px;
    }
  }
   */
/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {
    #termsContainer {
      height: 100%;
    }
  
    #termsAndCondition {
      max-height: 425px;
    }
  } */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {
    #termsAndCondition {
      max-height: 425px;
    }
  } */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {
    #termsAndCondition {
      max-height: 425px;
    }
  }
   */

/* new terms css starts here*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
#TermsAndConditionPage h1,
#TermsAndConditionPage li,
#TermsAndConditionPage p,
#TermsAndConditionPage h2,
#TermsAndConditionPage h3 {
  font-family: "Poppins", sans-serif;
}
#TermsAndConditionPage ul,
#TermsAndConditionPage h1,
#TermsAndConditionPage h2,
#TermsAndConditionPage h3,
#TermsAndConditionPage p {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.TermsAndConditionPage_wrap {
  width: 80%;
  margin: 40px auto;
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.terms_start {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.TermsAndConditionPage_wrap h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #08090a;
}
.TermsAndConditionPage_wrap li,
.TermsAndConditionPage_wrap p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6d747a;
}
.terms_start h2 {
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: #08090a;
}
.terms_start h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #08090a;
}
.terms_body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.terms_body > div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.terms_body ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media only screen and (max-width: 992px) {
  .TermsAndConditionPage_wrap {
    width: 80%;
  }
}
@media only screen and (max-width: 725px) {
  .TermsAndConditionPage_wrap {
    width: 90%;
    gap: 4px;
  }
  .TermsAndConditionPage_wrap li,
  .TermsAndConditionPage_wrap p {
    font-size: 14px;
  }
  .TermsAndConditionPage_wrap h1 {
    font-size: 24px;
  }
  .terms_start h2 {
    font-size: 20px;
  }
  .terms_start h3 {
    font-size: 18px;
  }
  .terms_start {
    gap: 10px;
  }
  .terms_body {
    gap: 16px;
  }
}
@media only screen and (max-width: 475px) {
  .TermsAndConditionPage_wrap {
    margin-top: 16px !important;
  }
}
