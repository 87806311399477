.main_testimonial_container {
  width: 20rem;
  height: fit-content;
  margin-bottom: 5rem;
  margin-top: 3rem;
  /* background-color: red; */
}
.user_data_conatiner {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
}
.client_image {
  width: 50px;
  height: 50px;
}
.client_image > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.client_name {
  height: 100%;
}
.client_name p {
  margin: 0px;
}
.client_name p:first-child {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}
.client_name p:first-child span {
  font-size: 14px;
  font-weight: 600;
  color: rgba(40, 56, 145, 1);
  text-transform: uppercase;
}
.client_name p:last-child {
  font-size: 16px;
  font-weight: 500;
  color: rgba(109, 116, 122, 1);
}
.testimonial_heading h3 {
  font-size: 20px;
  font-weight: 600;
}
.stars {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  margin: 1.3rem 0px;
}
.description {
  width: 100%;
  height: fit-content;
}
.description > p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(109, 116, 122, 1);
}

@media only screen and (max-width: 600px) {
  .stars {
    gap: 5px;
    margin-top: 8px;
    margin-bottom: 10px;
  }
  .client_image {
    width: 56px;
    height: 56px;
  }
  .review{
    margin-top: 12%;
  }
}
