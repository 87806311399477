.support_bg {
  background: linear-gradient(180deg, #ffffff 0%, #ebf8ed 27.4%, #ebf8ed 74.79%, #ffffff 100%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 80%;
  z-index: -1;
}

.support_container {
  padding: 0 39px;
  position: relative;
}

.support_title h1 {
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
}
.support_title p {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #6d747a;
}

.support_info {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.info_container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 20px;
  gap: 80px;
  width: 480px;
  height: 88px;
  background: #ffffff;
  border: 1px solid #d4d7e9;
  border-radius: 12px;
  transition: 0.4s;
  cursor: pointer;
}
.info_container:hover {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}

.info_container .info_text {
  display: flex;
  justify-content: left;
  flex: 1;
}

.info_container .info_text .text {
  font-weight: 500;
  font-size: 16px;
  line-height: 145.8%;
  color: #000000;
  cursor: pointer;
  margin-bottom: 0;
}

.info_container .info_text span {
  color: #283891;
}

/* Start Popup */
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.32);
  z-index: 999;
}
.popup_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 16px;
  padding: 24px 24px 44px;
  width: 678px;
  z-index: 9999;
}
.popup_header {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 50px;
}
.popup_header p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: #08090a;
}
.popup_header button {
  position: absolute;
  right: 0;
  top: -10px;
  background-color: white;
  outline: none;
  border: none;
  width: 24px;
  height: 24px;
}
.request_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.request_form input {
  box-sizing: border-box;
  background: #ffffff;
  width: 421px;
  height: 50px;
  border: 1px solid #0f1f78;
  border-radius: 8px;
  padding: 16px 24px;
  color: #6d747a;
}
.request_form input:focus {
  outline: none;
  border: 1px solid #0f1f78;
}
.request_form input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6d747a;
}
.request_form .form_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  width: 421px;
  height: 50px;
  background: #0f1f78;
  border-radius: 8px;
  color: white;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}
.request_form .form-input-err input {
  color: red !important;
  border: red 2px solid !important;
}
.request_form .form-input-err input::placeholder {
  color: red !important;
}
.request_form .input-err-msg {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 400 !important;
  color: red !important;
  font-size: 13px !important;
}

@media screen and (max-width: 1000px) {
  .support_container {
    width: 91vw;
    margin: 0 auto;
  }
}
@media screen and (max-width: 561px) {
  .support_title h1 {
    font-size: 38px;
  }
  .support_title p {
    font-size: 16px;
  }

  .info_container {
    width: auto;
    height: auto;
    gap: 3rem;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 391px) {
  .support_info svg {
    width: 30px;
  }

  .info_container .info_text .text {
    font-size: 14px;
  }
  .info_container .info_text span {
    display: block;
  }
}
@media screen and (max-width: 337px) {
  .info_container {
    gap: 1rem;
  }
}
