.hero-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 0rem;
  min-height: 50rem;
  flex-wrap: wrap;
  padding: 2.5rem 4rem;
  background-image: url(../../Assets/images/Illustration-1.png);
  background-position: 0px -350px;
  background-size: 100%;
  background-repeat: no-repeat;
}
#contact-number::-webkit-outer-spin-button,
#contact-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.hero-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 76px;
  color: rgba(8, 9, 10, 1);
}
.text-green {
  font-size: 40px !important;
  color: rgba(57, 181, 74, 1);
}
.text-blue {
  color: rgba(15, 31, 120, 1);
  font-weight: 600;
}
.hero-text-container {
  width: 50%;
  flex-grow: 1;
}
.hero-form-container {
  width: 40%;
  margin-top: 1.5rem;
}
.sub-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  /* or 167% */

  /* Blacks/Darker grey */

  color: #6d747a;
  display: inline-block;
  width: fit-content;
}
.form-heading h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  /* identical to box height */

  text-align: center;

  /* Primary/90 */
  /* margin-bottom: 2rem; */
  color: #3e4c9c;
}
.main-form {
  width: 100%;
  background: #ffffff;
  padding: 2rem;
  margin-top: 2rem;
  box-shadow: 0px 2px 38px rgba(0, 0, 0, 0.125);
  border-radius: 8px;
}
.form-btn {
  padding: 16px 24px;
  width: 100%;
  height: 48px;
  left: 47px;
  top: 389px;

  /* Primary/100 */

  background: #283891;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  text-align: center;

  /* Blacks/White */
  border: 2px solid #283891;
  color: #ffffff;
  transition: all 300ms;
}
.form-btn:hover,
.form-btn:focus {
  color: #283891;
  background-color: transparent;
}
.form-btn:disabled {
  background: #939bc8;
  color: #ffffff;
  border: none;
}
.form-btn:disabled:hover {
  background: #939bc8;
  color: #ffffff;
}
.main-form label {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Blacks/Black */
  margin: 1.5rem 0px;
  color: #08090a;
}
.main-form input {
  background: #ffffff;
  /* lighter lighter grey */
  height: 48px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding-left: 18px;
}
.benifits-container {
  background-image: url(../../Assets/images/Illustration-2.png);
  background-repeat: no-repeat;
  background-position: 0px -30px;
  background-size: cover;
  min-height: 105rem;
  margin-bottom: 4rem;
}
.benifit-options {
  width: 650px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
}
.manager-card-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: -1rem;
}
.benifit-heading h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height, or 122% */

  text-align: center;

  color: #283891;
}
.dialog-box {
  width: 80%;
  height: 293px;
  background: #ffffff;
  border-radius: 12px;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  box-shadow: 0px 2px 38px rgba(0, 0, 0, 0.125);
  margin-top: 6rem;
}
.dialog-box p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  text-align: center;

  /* Primary/Dark */
  width: 637px;
  color: #0f1f78;
}
.dialog-box button {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  /* identical to box height, or 88% */

  /* Blacks/White */

  color: #ffffff;
  padding: 16px 24px;
  height: 46px;
  border: 2px solid #283891;
  /* Primary/100 */

  background: #283891;
  border-radius: 8px;
  transition: all 300ms;
}
.dialog-box button:hover,
.dialog-box button:focus {
  background-color: transparent;
  color: #283891;
}
.illustration {
  position: absolute;
  left: 2rem;
  bottom: 0.5rem;
}
.swiper-container {
  margin-top: 0rem;
}
.team-swiper {
  width: 70%;
  margin: 0 auto;
}
#team {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height, or 122% */

  /* Primary/Dark */
  text-align: center;
  color: #0f1f78;
  margin-bottom: -4rem;
}
.main-modal-container {
  position: fixed;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  opacity: 1;
  transition: opacity 300ms, transform 500ms;
  transform: translateY(0px);
}
.main-modal-container_closed {
  position: fixed;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  pointer-events: none;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 500ms, transform 500ms;
  transform: translateY(-400px);
}
.modal-message-container {
  width: 770px;
  height: 337px;

  background: #ffffff;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.modal-message-container p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height */

  text-align: center;

  color: #283891;
  margin-bottom: 1.5rem;
}

.modal-message-container button {
  padding: 16px 48px;
  background: #283891;
  border-radius: 6px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
  text-align: center;
  font-feature-settings: 'salt' on;

  /* Blacks/White */

  color: #ffffff;
  border: 2px solid #283891;
  transition: all 300ms;
}
.modal-message-container button:hover,
.modal-message-container button:focus {
  background-color: transparent;
  color: #283891;
}
.close-btn {
  display: none;
}
.error {
  color: red;
}
@media only screen and (width <=800px) {
  .hero-section {
    padding: 0rem 1rem;
    background-position: unset;
  }
  .hero-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    color: rgba(8, 9, 10, 1);
  }
  .hero-heading br {
    display: none;
  }
  .text-green {
    font-size: 28px !important;
    color: rgba(57, 181, 74, 1);
  }
  .text-blue {
    color: rgba(15, 31, 120, 1);
    font-weight: 500;
  }
  .hero-text-container {
    width: 100%;
  }
  .hero-form-container {
    width: 100%;
    margin-top: 1.5rem;
  }
  .sub-heading {
    display: none;
  }
  .form-heading h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    text-align: center;

    /* Primary/90 */

    color: #3e4c9c;
  }
  .main-form {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1.5rem;
  }
  .main-form label {
    width: 100%;
    margin: 0px;
  }
  .benifit-heading h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    width: 60%;
    margin: auto;
    color: #283891;
  }
  .dialog-box {
    width: 95%;
    height: 395px;
    margin-top: 6px;
    margin: auto;
  }
  .benifit-options {
    width: 100%;
  }
  .dialog-box p {
    width: 334px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;

    /* Primary/Dark */

    color: #0f1f78;
  }
  .illustration {
    display: none;
  }
  #team {
    display: none;
  }
  .swiper-container {
    margin-top: -7rem;
  }
  .modal-message-container {
    width: 324px;
    height: 133px;
  }
  .modal-message-container p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    text-align: center;

    color: #283891;
  }
  .modal-message-container button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'salt' on;

    /* Blacks/White */

    color: #ffffff;
    padding: 9px 48px;
    box-sizing: border-box;
  }
  .close-btn {
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
    transform: rotate(135deg) scale(1.2);
    cursor: pointer;
  }
  .manager-card-wrapper {
    margin: 5rem 0px;
  }
  .manager-card-wrapper br {
    display: none;
  }
  .benifits-container {
    background-image: none;
    background-repeat: no-repeat;
    background-position: 0px -30px;
    background-size: cover;
    min-height: 75rem;
    margin-bottom: 4rem;
    padding: 0rem 0px;
  }
  .main-wealth-manager-page-container {
    background-image: url(../../Assets/images/Illustration-2.png);
    background-repeat: no-repeat;
    background-size: 459px;
    background-position: -73px 504px;
  }
}
