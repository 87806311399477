#getTouchHeader {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  display: block;
  padding: 0px;
  /* padding-bottom: 0.65em; */
  letter-spacing: -0.02em;
  color: #0f1f78;
  text-align: center;
}
#getTouchPara {
  font-size: 16px;
  font-weight: 500;
  line-height: 24ox;
  color: #6d747a;
}
.label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #08090a;
}
.select-dropdown{
  width: 100%;
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 8px!important;
  padding: 14px 16px;
  padding-right: 20px;
  transition: all .4s;
  outline: none;
  cursor: pointer;
  -moz-appearance: none;
  appearance: none;
}
.is-investor-dropdown{
  position: relative;
}
.is-investor-dropdown::after{
  content: "";
  position: absolute;
  background-image: url(../../Assets//dropdown.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 55%;
  transform: translateY(-50%);
  right: 14px;
}
.getTouchContainer {
  background: #ffffff;
  height: fit-content;
  /* border: 1px solid #e5e7eb; */
  border-radius: 8px;
  /* padding: 0px 48px; */
  width: 100%;
  padding-top: 6rem !important;
  padding-left: 6rem;
  padding-right: 6rem;
  margin: 0px !important;
}
.form_mobile {
  display: none;
}
.phone_getIn_touch_msg {
  display: block;
  width: 50%;
  margin: auto;
  margin: auto;
  text-align: center;
  color: #6D747A;
  font-size: 16px;
  font-weight: 500;
}
.get-in-touch-form {
  width: 100% !important;
  /* background-color: red; */
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin: auto;
  height: fit-content;
  padding: 3rem 0px;
}
.form_input_container {
  width: 70%;
  margin: auto;
}
#submitBtn {
  width: 99% !important;
}
#submitBtn:focus {
  color: #283891 !important;
}
#padding_left_none {
  padding-right: 0px !important;
}
@media only screen and (width < 700px) {
  .getTouchContainer {
    border: 0px;
    width: 100%;
    padding: 0rem 0.3rem 1rem 0.3rem;
    height: fit-content;
    margin-top: 0px !important;
  }
  #getTouchHeader {
    text-align: center;
    font-size: 24px;
    color: #0F1F78;
    border: 0;
    margin-bottom: 0px;
  }
  .phone_getIn_touch_msg {
    display: block;
    margin: auto;
    text-align: center;
    color: #6d747a;
    font-size: 14px;
  }
  .form_mobile {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    gap: 0;
  }
  .drop_input {
    margin-bottom: 1rem !important;
  }
  .form_mobile label {
    margin-bottom: 1rem;
  }
  #submitBtn_mobile {
    width: 100%;
    height: 3.5rem;
    border-radius: 8px;
    margin-top: 1rem;
    background-color: #283891;
    color: white !important;
  }
  #submitBtn_mobile:focus {
    color: white !important;
  }
  .is-investor-dropdown {
    padding: 0px 0px 0px 0px !important;
  }
  .phone_getIn_touch_msg {
    display: block;
    width: 97%;
    margin: 8px auto;
    text-align: center;
    color: #6d747a;
    font-size: 14px;
  }
  .label {
    padding: 0px !important;
  }
}
.getTouchContainer input:focus {
  border: 1px solid #ced4da;
}
.getTouchContainer textarea {
  resize: none;
}
.getTouchContainer textarea:focus {
  border: 1px solid #ced4da !important;
}
@media only screen and (width < 700px) {
  .get-in-touch-form {
    display: none;
  }
}
.get-in-touch-form input {
  background: #ffffff;
  /* lighter lighter grey */

  border: 1px solid #ced4da;
  border-radius: 8px;
}

.dropdown {
  box-shadow: 0px 0px 11px rgba(198, 198, 198, 0.25);
  border-radius: 8px;
  border: 0;
  margin-top: 9px !important;
}
.dropdown li {
  cursor: pointer;
}

.is-investor-dropdown button {
  background: #ffffff;
  width: 100%;
  /* lighter lighter grey */
  overflow: hidden;
  padding: 14px 16px;
  transition: all 0.4s;
  border: none;
  border: 1px solid #ced4da;
  border-radius: 8px !important;
  text-align: left !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  #submitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;

    width: 238px;
    height: 48px;
  }
}
@media only screen and (min-width: 414px) and (max-width: 500px) {
  #submitBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;

    width: 304px;
    height: 48px;
  }
}
#submitBtn {
  margin-top: 3rem;
  width: 7rem;
}
@media only screen and (width <=800px) {
  .label {
    font-size: 16px;
    margin-bottom: 0.5rem !important;
  }
}
