#becomeAnInvestor {
  border: 1px solid #ebedf0;
  box-shadow: 0px 1px 6px rgba(134, 134, 134, 0.25);
  border-radius: 12px;
  padding-top: 32px;
  padding-bottom: 24px;
  padding-left: 56px;
  padding-right: 176px;
  margin: 0 68px;
  color: #08090a;
}

#becomeAnInvestor h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}
#becomeAnInvestor h2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-top: 9px;
}
#becomeAnInvestor h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 9px;
}
#becomeAnInvestor h4 {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 9px;
  color: #939ca3;
}

/* .name {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
} */

#becomeAnInvestor input {
  padding: 14px 16px;
  border-radius: 8px;
  /* margin-bottom: 32px; */
}

#becomeAnInvestor button:disabled {
  background-color: #bfc3de !important;
  border: 0;
}
#becomeAnInvestor button:disabled:hover {
  /* background-color: #ced4da !important; */
  border: 0 !important;
  color: white !important;
}
#nameSubmit {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
#nameSubmit:disabled:hover {
  border: 0 !important;
  color: white !important;
}

@media only screen and (max-width: 1082px) {
  #becomeAnInvestor {
    padding: 40px;
    margin: 0 40px;
  }
}

@media only screen and (max-width: 769px) {
  #becomeAnInvestor h2 {
    font-size: 14px;
  }
  #becomeAnInvestor input {
    padding: 12px 16px;
    font-size: 14px;
  }
  #becomeAnInvestor {
    padding: 30px;
    margin: 0 30px;
  }
}

@media only screen and (max-width: 678px) {
  .input_box {
    width: 100%;
  }
}
@media only screen and (max-width: 606px) {
  #becomeAnInvestor h1 {
    display: none;
  }
  #becomeAnInvestor h3 {
    display: none;
  }
  #becomeAnInvestor {
    border: none;
    background: transparent;
    box-shadow: none;
    padding: 0;
  }
  #becomeAnInvestor button {
    width: 140px !important;
    height: 48px !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    margin: 0 auto !important;
  }
  .margin_wid {
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 13px;
  }
  form {
    display: flex;
    gap: 13px;
    flex-direction: column;
  }
  #becomeAnInvestor input {
    /* padding: 12px 16px; */
    font-size: 14px;
  }
}
@media only screen and (max-width: 475px) {
  #becomeAnInvestor {
    margin: 0 13px;
  }
  /* .input_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 13px;
  } */
}
