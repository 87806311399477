/* This css is mobile first */
/* Css without media query is written for mobile */
.main-policy-container {
  height: fit-content;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  background: #f5f5f5;
}
.policy-text-container {
  padding: 15px 10px;
}
.page-heading {
  font-weight: 700;
  font-size: 18px;
  color: #08090a;
  line-height: 27px;
}
.page-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #6d747a;
  text-align: start;
  word-wrap: break-word;
  word-break: normal;
  word-spacing: inherit;
}
.policy-heading h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #08090a;
}
.policy-text p {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #6d747a;
}
.policy-text ul {
  padding-left: 21px !important;
  color: #6d747a;
  list-style-type: disc;
  font-size: 12px;
}
.policy-text li {
  font-weight: 400;
  margin: 10px 0px;
  font-size: 12px;
  line-height: 20px;
  color: #6d747a;
}
@media only screen and (width >= 700px) {
  .main-policy-container {
    background: #ffffff;
  }
  .policy-text-container {
    padding: 2.5rem 10rem;
  }
  .page-heading {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.02em;
    color: #08090a;
  }
  .page-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #6d747a;
  }
  .policy-heading h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #08090a;
    margin-top: 18px;
  }
  .policy-text p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #6d747a;
    margin: 10px 0px;
  }
  .policy-text ul {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #6d747a;
  }
  .policy-text li {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #6d747a;
  }
}
