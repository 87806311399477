.main-fullimage-container {
  width: 100%;
  height: fit-content;
  position: relative;
}
.main-fullimage-container > img {
  display: block;
  object-fit: cover;
}
.fullscreen-btn {
  opacity: 0;
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: all 200ms;
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 5px;
  background: linear-gradient(325deg, #ffffffb5, #ffffffa8, #ffffff42);
  border-radius: 3px;
}
.fullscreen-btn svg {
  transition: 0.25s;
  will-change: transform;
}
.fullscreen-btn:hover svg {
  transform: scale(1.15);
}

.main-fullimage-container:hover .fullscreen-btn {
  opacity: 1;
}
.fullscreen-image {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  z-index: 2000;
  overflow-y: auto;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: all 300ms;
}
.fullscreen-image.show {
  opacity: 1;
  pointer-events: all;
  overflow: hidden;
}
/* For DeskTop */
.full-image {
  display: block !important;
  object-fit: contain !important;
  width: 80vw !important;
  margin: auto;
  height: 35rem !important;
}
.close-btn {
  position: absolute;
  top: 3rem;
  right: 3rem;
  z-index: 1000;
  background-color: white;
  padding: 5px 10px;
  text-align: center;
  border: 8px;
  cursor: pointer;
}
.close-btn > img {
  transform: scale(1.5) rotate(45deg);
  object-fit: cover;
}
@media only screen and (width < 900px) {
  .fullscreen-image > img {
    margin: auto;
    object-fit: contain !important;
    width: 100% !important; 
    height: 100% !important;
  }
}
