.main1 {
    width: 100%;
    height: auto;
    /* overflow: hidden; */
  }
  
  .main2 {
    width: 100%;
    margin-top: 80px;
  }
  
  .main4 {
    position: relative;
    /* overflow-x: hidden; */
    /* overflow-y: hidden; */
    height: 100%;
  }
  
  @media (min-width: 1539px) {
    .main2 {
      width: 100%;
      margin-top: 50px;
    }
    .back_image2 {
      position: absolute;
      z-index: -1;
      transform: translateX(-1000px);
      top: 15%;
    }
  }
  
  .helphing_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 45px;
    padding-right: 15px;
  }
  
  .helping_left_side {
    width: 45%;
    padding-left: 30px;
  }
  
  .helping_left_side h1 {
    font-weight: 600;
    font-size: 40px;
    color: #283891;
  }
  
  .helping_left_side p {
    font-weight: 400;
    font-size: 16px;
    color: #5360a7;
  }
  
  .helping_left_side h2 {
    font-size: 24px;
    color: #283891;
    font-weight: 500;
  }
  
  .main4 {
    width: 100%;
    margin-top: 40px;
  }
  
  .back_image {
    margin-top: 80px;
  }
  
  .back_image2 {
    position: absolute;
    z-index: -1;
    transform: translateX(-1020px);
  }
  
  .main5 {
    margin-top: 50px;
  }
  
  .company_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .company_main {
    display: flex;
    gap: 32px;
    margin-top: 40px;
  }
  
  .main6 {
    margin-top: 100px;
  }
  
  .main7 {
    margin-top: 120px;
    margin-bottom: 100px;
  }
  
  .hepling_right_side {
    display: block;
  }
  
  .hepling_right_side2 {
    display: none;
  }
  
  .main6 {
    display: block;
  }
  
  .main06 {
    display: none;
  }
  
  .mobile_view_client {
    display: none;
  }
  
  .main5 {
    display: block;
  }
  
  .main05 {
    display: none;
  }
  
  .hero_text2 {
    display: none;
  }
  
  @media (max-width: 769px) {
    .hero_text2 {
      display: block;
    }
    .main1 {
      width: 100%;
      height: 50%;
    }
    .main2 {
      width: 100%;
      position: relative;
      top: auto;
      z-index: 1;
      margin-top: 35%;
    }
    .main3 {
      padding-top: 0;
    }
    .hepling_right_side {
      display: none;
    }
    .hepling_right_side2 {
      display: flex;
    }
    .hepling_right_side2 img {
      width: 328px;
      height: 218px;
    }
    .helping_left_side {
      width: 100%;
    }
    .helping_left_side h1 {
      font-weight: 600;
      font-size: 24px;
      color: #283891;
    }
    .helping_left_side p {
      font-weight: 400;
      font-size: 16px;
      color: #5360a7;
    }
    .helping_left_side h2 {
      font-size: 24px;
      color: #283891;
      font-weight: 500;
    }
    .helphing_wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 15px;
    }
    .company_wrapper {
      width: 100% !important;
      display: flex !important;
      overflow-x: scroll !important;
    }
    .company_main {
      flex-wrap: nowrap !important;
    }
    .main1 {
      width: 100%;
      height: 100%;
      /* overflow: hidden; */
    }
    .main6 {
      display: none;
    }
    .main06 {
      margin-top: 15%;
      overflow: hidden;

      display: block;
    }
    .mobile_view_client {
      display: none;
    }
    .main5 {
      display: none;
    }
    .main05 {
      display: block;
    }
    .main4{
      margin-top: 15% !important;
    }
  
  }
  
  @media (min-width: 1440px) {
    .back_image {
      width: 100vw;
      object-fit: cover;
    }
  }
  
  .pitch_image_1 img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  
  .pitch_image_1 {
    width: 100%;
    height: auto;
    /* overflow: hidden; */
  }
  
  .detail_hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
  }
  