.am_wrap {
  width: 90%;
  margin: 0 auto;
}
.am_right h1 {
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  /* identical to box height, or 117% */

  color: #000000;
}
.am_head_wrap {
  margin: 40px 0;
  /* width: 45%; */
  flex-direction: column;
  gap: 24px;
  display: flex;
}
.am_amount_input {
  position: relative;
}
.am_amount label {
  position: absolute;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #283891;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}
.am_amount input {
  width: 100%;
  height: 70px;
  background: #f8f9fa;
  border: 1px solid #bfc3de;
  border-radius: 12px;
  padding: 0 34px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #283891;
}
.am_amount input:focus {
  outline: 1px solid #bfc3de;
}
.pre_amount li {
  padding: 8px 24px;
  gap: 10px;
  background: #e9ebf4;
  border-radius: 50px;
  list-style: none;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 17px; */
  display: flex;
  align-items: center;
  color: #283891;
  height: 37px;
  cursor: pointer;
}
.pre_amount .pre_active {
  background: #5360a7;
  color: #ffffff;
}
.pre_amount {
  display: flex;
  gap: 24px;
}
.am_amount {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.saved_card h2,
.upi h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 56px;
  color: #000000;
  margin-bottom: 0;
}
.am_cards {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  padding: 0 20px;
  height: 70px;
  background: #f8f9fa;
  border-radius: 12px;
}
.am_cards img {
  width: 48px;
  /* height: 38.22px; */
}
.am_cards p,
.upi_box p {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #262626;
}

.add_card_wrap {
  width: 102px;
  height: 196px;
  left: 758px;
  top: 415px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #262626;
  border-radius: 12px;
  flex-direction: column;
  gap: 12px;
}
.add_card_wrap p {
  text-align: center;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #262626;
}
.add_card {
  background: #d6e5f4;
  border-radius: 12px;
  padding: 10px;
  display: inline-block;
  height: 216px;
}
.select_card_box {
  display: flex;
  justify-content: space-between;
}
.select_card,
.upi_option {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.upi_box {
  display: flex;
  gap: 15px;
  /* justify-content: space-between; */
  align-items: center;
  width: 400px;
  height: 70px;
  background: #f8f9fa;
  padding: 0 20px;
  border-radius: 12px;
}
.am_right button {
  background: #283891;
  border-radius: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  height: 56px;
  border: none;
}
.am_left {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.am_left h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 56px;
  color: #000000;
  margin: 0;
}
.cuppon {
  position: relative;
  display: inline-block;
}
.cuppon button {
  width: 66px;
  height: 26px;
  background: #283891;
  border-radius: 12px;
  font-weight: 400;
  font-size: 11px;
  text-align: center;
  top: 10px;
  right: 10px;
  color: #ffffff;
  border: none;
  padding: 4px 10px;
  position: absolute;
}
.am_wrap {
  display: flex;
  gap: 100px;
}
.am_right {
  width: 50%;
}
