.main-blog-container {
  width: 592px;
  height: fit-content;
}
.blog-image {
  width: 100%;
  height: 484px;
}
.main-blog-container > .blog-image > img {
  display: block;
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
  box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1),
    0px 8px 10px -6px rgba(16, 24, 40, 0.1);
  border-radius: 6px;
}
.blog-heading > h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */
  margin-top: 1.5rem;

  /* Light / 02 Greys fill/ Dark */

  color: #323338;
}
.blog-short-des {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  font-feature-settings: "salt" on;
  margin-bottom: 0px;

  /* Neutral/500 */

  color: #6b7280;
}
.author-info {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 1.5rem;
}
.author-pic {
  display: block;
  width: 40px;
  height: 40px;
  border: 1.5px solid #ffffff;
}
.other-info p {
  margin: 0px;
}
.name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  font-feature-settings: "salt" on;

  /* Neutral/900 */

  color: #111827;
}
.date-time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  font-feature-settings: "salt" on;

  /* Neutral/500 */

  color: #6b7280;
}
@media only screen and (width <=1000px) {
  .main-blog-container {
    width: 100%;
    height: fit-content;
  }
  .blog-image {
    width: 100%;
    height: 300px;
  }
  .main-blog-container > .blog-image > img {
    display: block;
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
    box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1),
      0px 8px 10px -6px rgba(16, 24, 40, 0.1);
    border-radius: 6px;
  }
  .blog-heading > h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    /* or 178% */

    /* Light / 02 Greys fill/ Dark */

    color: #323338;
  }
  .blog-short-des {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    font-feature-settings: "salt" on;

    /* Neutral/500 */

    color: #6b7280;
  }
  .author-info {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 1.5rem;
  }
  .author-pic {
    display: block;
    width: 40px;
    height: 40px;
    border: 1.5px solid #ffffff;
  }
  .other-info p {
    margin: 0px;
  }
  .name {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    font-feature-settings: "salt" on;

    /* Neutral/900 */

    color: #111827;
  }
  .date-time {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    font-feature-settings: "salt" on;

    /* Neutral/500 */

    color: #6b7280;
  }
}
