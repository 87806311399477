.loader {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border-top: 4px solid #0f1f78;
  border-left: 4px solid #0f1f78;
  border-bottom: 4px solid #0f1f78;
  border-right: 4px solid transparent;
  animation: rotate 500ms infinite;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
