.main-comments-container {
  padding: 1rem 6rem;
  --margin-bottom: 1.5rem;
}
.comment-container-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.comment-container-heading h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  /* Grey/Darkest */

  color: #08090a;
}
.post-btn {
  all: unset;
  width: 108px;
  height: 48px;
  box-sizing: border-box;
  /* Primary/100 */

  background: #283891;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  /* identical to box height, or 80% */
  text-align: center;
  /* Primary/10 */
  outline: 2px solid #283891;
  color: #e9ebf4;
  transition: all 200ms;
  margin-right: 65px;
}
.post-btn:hover,
.post-btn:focus {
  background-color: transparent;
  color: #283891;
  outline: 2px solid #283891;
  transition: all 200ms;
}
.comment-container-heading p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* Blacks/Darker grey */

  color: #6d747a;
}
.main-comment {
  padding: 1rem 2rem;
}
.back-btn {
  margin-bottom: var(--margin-bottom);
}
.back-btn > button {
  all: unset;
  display: block;
  width: 40px;
  height: 40px;
  background: #e9ebf4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
}
.back-btn > button > img {
  display: block;
  width: 18px;
  height: 18px;
  object-fit: contain;
}
.comment-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  /* gray/900 */

  color: #18181b;
}
.date-time-user-container {
  width: 20rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--margin-bottom);
}
.date-time {
  display: flex;
  align-items: center;
  gap: 1.4rem;
}
.date-time span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height, or 131% */

  /* gray/500 */

  color: #71717a;
}
.user {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  /* gray/900 */

  color: #18181b;
  display: flex;
  align-items: center;
  gap: 8px;
}
.user > img {
  display: block;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  border: 1.5px solid #ffffff;
  object-fit: cover;
}
.option-container {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: var(--margin-bottom);
}
.number {
  width: 44px;
  height: 24px;

  /* Light Gray / Light Gray 3 */

  background: #e5e9eb;
  border-radius: 12px;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.006em;

  /* Dark Gray / Dark Gray 2 */

  color: #252c32;
}
.border {
  border-bottom: 1px solid #939ca3;
}
.all-comments-container {
  width: 100%;
  min-height: 5rem;
  padding: 0px 3.5rem;
}
.all-comments-heading {
  display: flex;
  align-items: center;
  gap: 8px;
}
.all-comments-heading > button {
  all: unset;
  width: 24px;
  height: 24px;

  /* Neutral / 200 */

  border: 1px solid #d0d5dd;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.all-comments-heading > button > img {
  display: block;
  width: 12px;
  height: 12px;
  object-fit: contain;
}
.all-comments-heading > p {
  margin: 0px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  /* Neutral / 800 */

  color: #191d23;
}
.other-comment {
  padding-left: 35px;
}
.all-comments-list {
  position: relative;
  isolation: isolate;
}
.all-comments-list::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  inset: 0;
  left: 15px;
  z-index: -1;
  border: 1px solid #d0d5dd;
}
.all-comment-btn > button {
  all: unset;
  margin: 0px 38px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}
.all-comment-btn > button > img {
  transform: rotate(-90deg);
  display: block;
  width: 12px;
  height: 12px;
  object-fit: contain;
}
@media only screen and (width <=900px) {
  .main-comments-container {
    padding: 3rem 0.8rem;
  }
  .comment-container-heading {
    row-gap: 2rem;
    margin-bottom: 1.5rem;
  }
  .comment-container-heading h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    /* Grey/Darkest */

    color: #08090a;
  }
  .comment-container-heading p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    /* Blacks/Darker grey */

    color: #6d747a;
  }
  .back-btn {
    display: none;
  }
  .main-comment {
    padding: 1.5rem 0px;
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    margin-bottom: 1.5rem;
  }
  .option-container {
    padding: 0px 4px;
    margin-bottom: 0px;
  }
  .border {
    display: none;
  }
  .comment-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;

    /* gray/900 */

    color: #18181b;
  }
  .date-time span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height, or 131% */

    /* gray/500 */

    color: #71717a;
  }
  .user {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    /* gray/900 */

    color: #18181b;
  }
  .all-comments-heading {
    margin-bottom: 1.8rem;
  }
  .all-comments-container {
    padding: 0px;
  }
  .all-comments-heading p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    /* Neutral / 800 */

    color: #191d23;
  }
  .main_comment{
    margin-left: 0px !important;
    width: 100% !important;
  }
  .main_comment2{
    margin-left: 20px !important;
  }
}
