.card-container {
  --container-height: 460px;
  --header-height: 200px;
  --body-height: calc(var(--container-height) - var(--header-height));

  height: var(--container-height);
  width: 27vw;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  max-width: 460px;
}

* {
  text-decoration: none !important;
}
.card-header {
  position: relative;
  width: 27vw;
  max-width: 100%;
  height: var(--header-height);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background-color: #bbb;
  transition: inherit;
}
.card-header .startup-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.card-header .startup-logo {
  --size: 56px;
  position: absolute;
  bottom: calc(var(--size) / -2);
  left: 16px;
  width: var(--size);
  height: var(--size);
  border-radius: 100%;
  background-color: #6d747a;
  box-shadow: 0 0 6px 0px #cacaca;
  object-fit: contain;
}
.card-header .closed-deal-tag {
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 4px 12px;
  width: 88px;
  height: 32px;
  background: #ebedf0;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #08090a;
  transition: inherit;
}

.card-body {
  padding: 7px 16px;
  height: var(--body-height);
  position: relative;
  transition: inherit;
}

.bookmark-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: inherit;
  /* opacity: 0; */
  background-color: #eeeeeec0;
}
.bookmark-btn:hover {
}
.card-body h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #08090a;
}
.card-body p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #6d747a;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.card-body .sectors {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  position: absolute;
  left: 16px;
  bottom: 25px;
  transition: inherit;
  max-height: 60px;
}
.card-body .sectors span {
  padding: 4px 8px;
  background: #e9ebf4;
  border-radius: 4px;
  white-space: nowrap;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #283891;
  cursor: default;
}

.card-footer {
  padding: 10px 16px;
  opacity: 0;
  transition: inherit;
}
.card-footer p {
  font-family: "Hind";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #08090a;
}
.card-footer p span {
  font-weight: 500;
  font-size: 18px;
}
.card-footer hr {
  all: revert;
  border: none;
  border-bottom: 2px solid #ebedf0;
  border-radius: 1px;
  margin: 0;
}

.card-container:hover {
  --header-height: 72px;
}
/* .card-container:hover .bookmark-btn {
  opacity: 1;
} */
.card-container:hover .card-body .sectors {
  transform: translateY(-190px);
  opacity: 0;
}
.card-container:hover .card-footer {
  transform: translateY(-100%);
  opacity: 1;
}
.card-container:hover .card-header .closed-deal-tag {
  top: 6px;
  left: 6px;
}
@media only screen and (max-width: 1078px) {
  .card-container {
    width: 41vw;
  }
  .card-header {
    width: 41vw;
  }
}
@media only screen and (max-width: 708px) {
  .card-container {
    width: 41vw;
  }
  .card-header {
    width: 41vw;
  }
}
@media only screen and (max-width: 600px) {
  .card-container {
    width: 78vw;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin: 0 auto;
    position: relative;
    /* z-index: -1; */
  }
  .card-header {
    width: 78vw;
  }
}
@media only screen and (max-width: 445px) {
  .card-container {
    width: 90.5vw;
    /* height: 400px; */
  }
  .card-header {
    width: 90.5vw;
    /* height: 120px; */
  }
  /* .card-container:hover .card-footer {
    transform: translateY(-140%);
  }
  .card-body .sectors {
    bottom: -20px;
  } */
}
