.side_box {
  display: flex;
}
.nav_display {
  display: none;
}
@media only screen and (width <=1000px) {
  .dashboard-conatiner-style-mobile {
    padding: 0px !important;
    width: 100% !important;
  }
  .side_box {
    padding-top: 4rem;
  }
  .nav_display {
    display: initial;
  }
}
@media only screen and (width > 1000px) {
  .dashboard-conatiner-style-mobile {
    margin-left: 8.7rem;
  }
  .nav_display {
    display: none;
  }
}
