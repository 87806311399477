.h2 {
  font-size: 48px;
  font-weight: 600;
  color: #08090a;
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row !important;
}

.p {
  font-size: 18px;
  line-height: 30px;
  color: #6d747a;
}

#h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

#btnText {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  padding: 16px 24px;
}

#btnText:hover {
  color: #283891;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #header {
    padding: 16px 24px;
  }

  #logo {
    position: absolute;
  }
  #h6 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
  }
  .h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
  }
  .header-text-container h2 {
    padding: 0 10px;
  }
  .p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    /* or 214% */

    text-align: center;
  }
  #btnText {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;

    width: 210px;
    height: 48px;

    /* Primary/10 */

    background: #e9ebf4;
    border-radius: 8px;

    /* Inside auto layout */

    /* flex: none; */
    order: 0;
    flex-grow: 0;
    justify-content: center;
    margin-left: 71px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #header {
    padding: 16px 123px;
  }

  #logo {
    position: absolute;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #header {
    padding: 16px 68px;
  }

  #logo {
    position: static;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #header {
    padding: 16px 68px;
  }
  .buttonC {
    text-align: center !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #header {
    padding: 70px 68px;
    padding-bottom: 100px !important;
    padding-top: 10px;
    /* min-height: 75vh !important; */
    /* max-height: 100% !important; */
  }
}

@media (max-width: 990px) {
  .header-image-carousel {
    width: 100% !important;
    margin: auto !important;
  }
}
@media only screen and (width <=700px) {
  .carContainer {
    width: 100% !important;
  }
  #carousel_container_mobile {
    width: 100%;
  }
  #header {
    padding: 0px;
    margin-bottom: 4rem;
  }
  .header-container {
    width: 100%;
    margin: 0px !important;
  }
  .header-text-container {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 14px;
    padding-right: 14px;
  }
  #btnText {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    width: 210px;
    height: 48px;
    background: #e9ebf4;
    border-radius: 8px;
    justify-content: center;
    margin: 0px;
  }
  #carousel_container_mobile {
    padding-left: 0px;
    padding-right: 0px;
  }
}
