#referBG {
  width: 100%;
  /* height: 100vh; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding-left: 20px;
  padding-right: 20px;
}

#referContent {
  margin-left: auto;
  transition: all 0.5s;
}
