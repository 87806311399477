#whyChooseUs {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #ebf8ed 27.4%,
    #ebf8ed 74.79%,
    #ffffff 100%
  );
  background-size: 100% 104%;
  background-repeat: no-repeat;
  background-position: center center;
  /* padding: 58px 0; */
  margin: 0px !important;
}
.sectionHeader {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #283891;
}
.choose_us_mobile_msg {
  display: none;
}

.choose_us_mobile_msg {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #6d747a;
}
.sectionHeader_warp {
  width: 96% !important;
}
.sectionText {
  font-weight: 500;
  font-size: 16px;
  color: #6d747a;
  width: 600px;
}

.whyChooseCardHeader {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  width: 234px;
  color: #08090a;
  margin-top: 30px;
}

.whyChooseCardParagraph {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #6d747a;
}

.whyChooseImg {
  width: 55%;
  height: auto;
}

@media only screen and (width <700px) {
  .sectionHeader {
    font-size: 24px;
    line-height: 44px;
    color: #283891 !important;
  }
  .sectionText{
    display: none;
  }
  .choose_us_mobile_msg {
    display: block;
    color: #6d747a;
    font-weight: 500;
    margin: 10px;
    text-align: left center;
  }
}
