.main-team-card {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 739px;
  height: fit-content;
  gap: 10px;
  /* background: #f9fafb; */
}
.team-image {
  display: block;
  width: 176px;
  height: 176px;
  object-fit: cover;
}
.icon {
  display: none;
}
.team-info {
  display: flex;
  flex-direction: column-reverse;
}
.info-data {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  text-align: center;

  color: #111827;
}
.name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  display: flex;
  align-items: center;
  text-align: center;
  margin: 0px;
  letter-spacing: -0.2px;
}
.info-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  display: block;

  /* blue-gray/500 */
  margin: 0px;
  color: #64748b;
}
.info-heading span {
  margin: 0px 4px;
}
.name-info-holder {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (width >= 1000px) {
  .name-info-holder {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  }
  .main-team-card {
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    width: 739px;
    height: 304px;
    gap: 10px;
    background: #f9fafb;
  }
  .team-image {
    display: block;
    width: 291px;
    height: 100%;
  }
  .team-info {
    /* height: 100%; */
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    flex-direction: column;
    width: 448px;
  }
  .team-info p {
    margin: 0px;
  }
  .name-info-holder {
    align-self: flex-start;
  }
  .name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    display: flex;
    letter-spacing: -0.2px;

    /* Base/02 */

    color: #090914;
  }
  .info-heading {
    width: 398px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    /* blue-gray/500 */

    color: #64748b;
  }
  .info-data {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */
    text-align: start;
    color: #111827;
  }
  .icon {
    display: block;
    width: 30px;
    height: 30px;
    margin-top: 10px;
    align-self: flex-start;
    cursor: pointer;
    transition: all 200ms;
  }
  .icon:hover {
    filter: sepia(100%) hue-rotate(190deg) saturate(500%);
  }
  .info-heading {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .info-heading span {
    margin: 0px 3px;
    position: relative;
    display: inline-block;
    margin-left: 18px;
    text-transform: capitalize;
  }
  .info-heading span::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border: 1px solid white;
    border-radius: 50%;
    background-color: #090914;
    inset: 0;
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
  }
}
