@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

#registerLeft {
  background: #283891;
  border-radius: 12px 0 0 12px;
  padding: 63px 30px;
}

#registerLeft p {
  font-size: 23px !important;
  font-weight: 500;
}

#registerPara {
  font-size: 20px !important;
  font-weight: 500;
}

#registerLeft img {
  width: 270px;
  height: 270px;
  margin-top: 38px;
}

#registerRight {
  /* background-color: #f8f9fa; */
  background: #f8f9fa;
  border-radius: 10px;
  padding: 0;
}

#termsContainer {
  width: 100%;
}

#registerRight label {
  z-index: 1;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #registerRight {
    color: #000;
  }

  #registerRight label {
    font-size: 16px !important;
    font-weight: 500;
  }

  #termsContainer {
    height: 90vh;
  }

  #termsAndCondition {
    height: 80vh;
  }
  .register_mob {
    height: calc(100vh - 98px);
  }
  #registerRight {
    background: #fff;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #registerRight label {
    font-size: 16px !important;
    font-weight: 500;
  }

  #termsContainer {
    height: 90vh;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #registerRight label {
    font-size: 19px !important;
    font-weight: 500;
  }

  #termsContainer {
    height: 100%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #registerRight label {
    font-size: 19px !important;
    font-weight: 500;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #registerRight label {
    font-size: 19px !important;
    font-weight: 500;
  }
}
