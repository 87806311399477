.pitch-deck-wrapper {
  padding: 15px;
}

.pitch-deck-wrapper h3 {
  margin-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #dea700;
}
.pitch-deck-wrapper p {
  margin-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
}
.pitch-deck-wrapper img {
  width: 100%;
  border-radius: 12px;
}

@media screen and (min-width: 700px) {
  .textWarning {
    color: #dea700;
    margin: 16px 0;
    font-size: 18px;
  }

  #pitchDeck p {
    color: black;
    font-size: 16px;
  }

  .solutionHeading {
    color: black;
    font-size: 24px;
    font-weight: 600;
    padding: 24px 154px;
    line-height: 40px;
    text-align: center;
    background-color: #f8f9fa;
    border-radius: 12px;
  }

  .image {
    border-radius: 12px;
  }
  
}
