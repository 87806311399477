.section-bg-holder {
  position: absolute;
  top: -10px;
  right: 0;
  left: 0;
  opacity: 0.8;
  z-index: -1;
  overflow-x: hidden;
}
.section-bg-holder img {
  width: calc(100% + 230px);
  max-width: 100%;
}

/* Header Section */
.main-fund-raising-container {
  overflow-x: hidden;
  padding-top: 8.5rem;
  padding-bottom: 5rem;
}
.fundraise-upper-container {
  width: 100%;
  min-height: 20rem;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 5rem 3rem 20rem;
  z-index: 1;
}
.fundraise-text {
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: baseline;
  flex-direction: column;
  margin-top: 0px;
}
.heading p {
  font-family: 'Poppins';
  font-style: normal;
  font-size: 45px;
  letter-spacing: 0.01em;
  margin-bottom: 1rem;
  color: #08090a;
}
.heading p .green {
  color: #39b54a;
}
.heading p .blue {
  color: #0f1f78;
  font-size: 60px;
  font-weight: 600;
}
.fund-raise-btn > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  border-radius: 8px;
  background-color: #283891;
  color: white !important;
  font-size: 14px;
  border: 2px solid #283891;
  letter-spacing: 1px;
  text-decoration: none;
  font-weight: 600;
}
.fund-raise-btn > button:hover {
  background-color: transparent;
  color: #283891 !important;
  border: 2px solid #283891 !important;
  transition: all 200ms;
}
.fund-raise-btn > button:focus {
  background-color: transparent;
  color: #283891 !important;
  border: 2px solid #283891 !important;
}
.fund-raise-image {
  display: flex;
  align-items: center;
  width: 50%;
  position: relative;
  z-index: 1;
  top: -150px;
  right: -90px;
}
.fund-raise-image img:nth-child(1) {
  width: 261.49px;
  height: 219px;
  max-width: 100%;
  margin-right: -50px;
}
.fund-raise-image img:nth-child(2) {
  width: 313px;
  height: 301.09px;
  max-width: 100%;
  margin-top: 70px;
  position: relative;
  top: 25px;
}

/* Timelines Section */
.timelines {
  text-align: center;
  padding: 0 3rem 5rem;
  position: relative;
  z-index: 1;
}
.timelines p {
  margin: auto auto 5rem;
  max-width: 896px;
}
.timelines-card-container {
  display: flex;
  justify-content: center;
}
.timelines .section-bg-holder {
  top: -280px;
}

/* How It Works Section */
.main-how-it-works {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.main-heading-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;
}
.main-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #283891;
  margin-bottom: 12px;
  text-align: center;
}
.how-it-works-card-container {
  width: 60%;
  margin: auto;
  padding: 0px 0px;
  gap: 48px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap !important;
  margin-bottom: 5rem;
}
.how-it-works-card-container h1 {
  color: #283891 !important;
}
.how-it-works-card-container div:nth-child(3) {
  margin: 16px 0px;
}

/* Testimonials */
.testimonials {
  /* position: relative; */
  margin-top: -5% !important;
}
.testimonials .section-bg-holder {
  top: calc(-100% - 200px);
}

/* FAQ Section */
.faq {
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: relative;
}
.faq .section-bg-holder {
  top: 68%;
}
.faq .section-bg-holder img {
  height: 582px;
}

@media only screen and (width < 1300px) {
  .section-bg-holder {
    top: 0px;
  }
  .timelines-card-container {
    flex-wrap: wrap;
    gap: 3rem;
  }
}

@media only screen and (width < 1199px) {
  .section-bg-holder {
    top: 120px;
  }
  .section-bg-holder img {
    width: 100%;
  }

  .testimonials .section-bg-holder {
    top: calc(-100% + 180px);
  }

  .heading p {
    font-size: 33px;
  }
  .heading p .blue {
    font-size: 45px;
  }
  .fund-raise-image {
    top: -120px;
    right: 0px;
  }
}

@media only screen and (width < 992px) {
  .main-fund-raising-container {
    padding-top: 0;
  }
  .fundraise-upper-container {
    flex-direction: column-reverse;
    align-items: center;
    padding: 5rem 3rem 6rem;
  }
  .fundraise-text {
    align-items: center;
    text-align: center;
    width: auto;
    margin-top: -40px;
  }
  .heading p {
    font-size: 30px;
  }
  .fund-raise-btn > button {
    font-size: 16px;
    margin-top: 0.5rem;
  }
  .fundraise-upper-container .section-bg-holder {
    top: 0px !important;
    left: -450px;
  }
  .fund-raise-image {
    width: auto;
    top: -50px;
    right: -50px;
  }
  .fund-raise-image img:nth-child(1) {
    margin-right: -20px;
  }

  .main-heading {
    font-weight: 500;
    font-size: 25px;
    line-height: 27px;
  }

  .timelines .section-bg-holder {
    top: 100px;
  }
}

@media only screen and (width < 703px) {
  .section-bg-holder img {
    width: auto;
  }

  .fund-raise-image {
    top: -40px;
    width: auto;
  }
  .fund-raise-image img:nth-child(1) {
    width: 150px;
    height: 150px;
    margin-right: -20px;
  }
  .fund-raise-image img:nth-child(2) {
    width: 220px;
    height: 220px;
  }
  .testimonials {
    /* position: relative; */
    margin-top: -45% !important;
  }
  .testimonials .section-bg-holder {
    display: none;
  }
}

@media only screen and (width < 605px) {
  .main-heading {
    font-size: 18px;
  }
  .main-fund-raising-container {
    padding-bottom: 2rem;
  }
  .fund-raise-image {
    top: -120px;
    right: auto !important;
  }

  .heading p {
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;
  }
  .heading p .blue {
    font-size: 32px;
  }
  .fund-raise-btn {
    padding: 16px 24px;
    font-size: 16px;
    line-height: 16px;
  }

  .why-raise {
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    margin-bottom: 0px;
  }

  /* FAQ Section */
  .faq {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .faq .section-bg-holder {
    top: 80% !important;
  }
}

@media only screen and (width < 420px) {
  .main-heading {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }

  .fundraise-upper-container {
    padding-right: 5px;
    padding-left: 5px;
  }
  .fund-raise-image {
    right: 8px !important;
  }

  .timelines {
    padding-right: 5px;
    padding-left: 5px;
  }
}
