.custom-section {
  padding: 15px;
}
.custom-section-wrapper p {
  color: black;
  font-size: 16px;
}

.custom-section {
  margin-top: 24px;
}
.solutionHeading {
  color: black;
  font-size: 24px;
  font-weight: 600;
  padding: 24px 154px;
  line-height: 40px;
  text-align: center;
  background-color: #f8f9fa;
  border-radius: 12px;
}

.image {
  border-radius: 12px;
  margin-top: 20px;
  width: 100%;
}
