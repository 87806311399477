/* faq */

.body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.faq {
  padding-top: 50px;
  width: 90%;
   margin-left: 50px;
}
@media screen and (max-width: 768px) {
  .faq {
    width: 100%;
    padding: 10px !important;
    margin: 0% !important;
  }
  
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #283891;
}
/* .accordion {
  padding-left: 26vw !important;
  padding-right: 26vw !important;
} */
.item {
  background-color: transparent !important;
  text-align: left;
}
.item button {
  background-color: transparent !important;
  padding: 0.6rem 0.5rem !important;
}

.accordion_body {
  padding-top: 12px !important;
}
.according_body_holder {
  position: relative;
  padding-left: 6px;
}
.according_body_holder::after {
  content: '';
  position: absolute;
  height: calc(100% - 40px);
  left: 10px;
  top: 18px;
}

@media only screen and (max-width: 1203px) {
  .accordion {
    padding-left: 20vw !important;
    padding-right: 20vw !important;
  }
}
@media only screen and (max-width: 816px) {
  .accordion {
    padding-left: 10vw !important;
    padding-right: 10vw !important;
  }
}
@media only screen and (max-width: 606px) {
  .accordion {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .item {
    background-color: transparent !important;
    text-align: left;
    padding-left: 0px !important;
  }
}
@media only screen and (max-width: 445px) {
  .accordion {
    padding-top: 18px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .title {
    font-size: 24px;
    padding: 0 20px;
  }
}
@media only screen and (width < 376px) {
  .title {
    font-weight: 600;
    line-height: 130%;
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .accordion_body {
    padding-top: 0px !important;
  }

  .according_body_holder::after {
    height: calc(100% - 15px);
    left: 10px;
    top: 0px;
  }
}
.investerText{
font-family: "Poppins";
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: 36px; /* 128.571% */
letter-spacing: -0.56px;
padding-right: 20px;
border-right: 1px solid #E5E7EB;
}
.investerText > p{
  cursor: pointer;
}
.founderText > p{
  cursor: pointer;
}
.founderText{
font-family: "Poppins";
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: 36px; /* 128.571% */
letter-spacing: -0.56px;
padding-left: 20px;
}