#img {
  max-width: 100%; 
  max-height: 100%;
  object-fit: contain; 
  display: block; 
}
#img_div{
  display: flex;
  justify-content: center; 
  align-items: center; 
  width: 80%;
  height: 100%; 
  overflow: hidden; 
}
.carText {
  /* background-color: rgba(255, 255, 255, 0.4); */
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0)
  );
  width: 90%; padding: 2%;
  border-bottom-left-radius: 21px !important;
  border-bottom-right-radius: 21px !important;
  padding-bottom: 0px !important;
}
.carousel-img-text {
  width: 100%;
}
#mobile_carousel {
  width: 452px;
  height: 452px;
  margin-top: -65px;
  margin-left: -15%;
  background-color: #fff;
  border-radius: 0px !important;
}
/* 
@media only screen and (width <=700px) {

  #img {
    width: 100%;
    height: 100%;
    border-radius: 0px !important;
  }
  .carText {
    border-radius: 0px !important;
    padding-left: 20.89px !important;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0)
    );
  }
  .carText h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
  .carousel-img-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    width: 100%;
    word-wrap: normal; 
    height: 100%;
  }
} */
