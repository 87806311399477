.main-comment-component {
  width: 70%;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-top: 2rem;
}
.user-image {
  display: block;
  width: 40px;
  height: 40px;
  border: 1.5px solid #ffffff;
  border-radius: 50%;
}
.title-des-container {
  width: 100%;
}
.title-des-container h6 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  margin-bottom: 4px;
  color: #000000;
}
.title-des-container p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #000000;
}
.buttons {
  width: 100%;
}
.like,
.dislike {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 6px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #000000;
}
.dislike > svg {
  transform: rotate(180deg);
}
.reply {
  all: unset;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buttons > div {
  display: flex;
  gap: 16px;
}
@media only screen and (width <= 800px) {
  .main-comment-component {
    width: 100%;
  }
  .title-des-container h6 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #000000;
  }
  .title-des-container p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    color: #000000;
  }
  .reply {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    color: #000000;
  }
}
