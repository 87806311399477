.header {
  background: #ffffff;
  box-shadow: 0px 0px 11px rgba(198, 198, 198, 0.25);
  padding: 18px;
  padding-left: 68px;
}
.wrap {
  position: relative;
}
.header img {
  width: 150px;
  height: 54px;
}
.header svg {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .header svg {
    display: inline;
    position: absolute;
    left: 30px;
  }
  .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header {
    padding: 18px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
}
@media only screen and (max-width: 600px) {
  .header svg {
    left: 16px;
  }
  .header img {
    width: 100px;
    height: 36px;
  }
}
