.otp {
  width: 54px;
  height: 52px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #d9d9d9;
}

#verifyEmail {
  padding-top: 30px;
  padding-bottom: 24px;
  padding-left: 56px;
  padding-right: 96px;
  /* height: 70vh; */
}

#verifyEmail h1 {
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 32px !important;
}

#verifyEmail h6 {
  font-size: 18px !important;
  font-weight: 500 !important;
}

#verifyEmail p {
  font-weight: 400;
  color: #6d747a;
  font-size: 14px;
}

#resend {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #000;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #verifyEmail h6 {
    margin-top: 36px;
    margin-bottom: 16px;
  }

  #verifyEmail p {
    margin-bottom: 16px;
  }

  #timer {
    margin-top: 8px !important;
    margin-bottom: 24px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #verifyEmail h6 {
    margin-top: 36px;
    margin-bottom: 16px;
  }

  #verifyEmail p {
    margin-bottom: 16px;
  }

  #timer {
    margin-top: 8px !important;
    margin-bottom: 24px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #verifyEmail h6 {
    margin-top: 36px;
    margin-bottom: 16px;
  }

  #verifyEmail p {
    margin-bottom: 16px;
  }

  #timer {
    margin-top: 8px !important;
    margin-bottom: 24px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #verifyEmail h6 {
    margin-top: 36px;
    margin-bottom: 16px;
  }

  #verifyEmail p {
    margin-bottom: 16px;
  }

  #timer {
    margin-top: 8px !important;
    margin-bottom: 24px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #verifyEmail h6 {
    margin-top: 36px;
    margin-bottom: 16px;
  }

  #verifyEmail p {
    margin-bottom: 16px;
  }

  #timer {
    margin-top: 12px !important;
    margin-bottom: 24px;
  }
}
