.startUpImg {
  background-color: #3e4c9c !important;
  opacity: 0.6;
  width: 80px;
  height: 80px;
  padding: 23px 21px;
  cursor: pointer;
  transition: all 0.2s;
}

#typeOfStartUps h5 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  text-align: center;

  /* Blacks/Black */

  color: #08090a;
}

.continue_btn{
  
}

#typeOfStartUps h6 {
  font-size: 14px !important;
  font-weight: 400;
}

div#consumer {
  margin-top: 24px;
}

div#consumer img {
  width: 24px;
  height: 38px;
}

#typeOfStartUps img {
  width: 40px;
}
.startUp.active .startUpImg {
  background-color: #4dbc5c !important;
  opacity: 1;
  box-shadow: 0px 0px 16px rgba(77, 188, 92, 0.5);
}

.startUp.active .startUpText {
  font-weight: 600 !important;
}

#startUpContainer {
  width: 665px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  gap: 2rem;
}

#typeOfStartUps button.disabled {
  background-color: #bfc3de;
}

@media only screen and (max-width: 600px) {
  #typeOfStartUps {
    /* height: 90vh; */
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
}
@media only screen and (max-width: 475px) {
  .typesbox {
    padding-top: 17px !important;
  }
}

@media only screen and (width <= 800px) {
  .heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    /* or 175% */

    text-align: center;

    /* Blacks/Black */

    color: #08090a;
  }
  #startUpContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 18px !important;
  }
  .startUpText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: #000000;
  }
}
