.blogs_container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.view_more_container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 12%;
}
.view_more_container button{
    width: 128px;
    height: 48px;
    border: 1px solid #0F1F78;
    border-radius: 8px;
    color: #0F1F78;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
}
.main_container{
    width: 95%;
}