#container {
  margin: 8px;
  margin-top: 0px !important;
}

#investHeadingContainer {
  padding: 17px 0px;
  padding-bottom: 15px;
  border-bottom: 1px solid #CED4DA;
}

.startup-logo{
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 8px;
}

#investHeading h1 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 0;
}

#investHeading {
  margin-top: 30px;
}

#investorType h6 {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}

#investorType select {
  width: 240px;
  padding: 12px;
  font-size: 16px;
}

#arrow {
  margin-top: 0px;
}
.mobile_back {
  display: none;
}
@media only screen and (width <=1000px) {
  #container {
    margin: 0px;
  }
  .mobile_back {
    display: block;
    margin: 10px 12px;
    position: relative;
    top: 1rem;
    width: 14px;
    transform: translateY(20px);
  }
  #arrow {
    display: none;
  }
  #investHeading h1 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
  }
  #investHeading img {
    width: 25px;
    height: 25px;
    border-radius: 8px;
    object-fit: contain;
  }
  #investorType h6 {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  #investorType select {
    width: 127px;
    height: 34px;
    padding: 4px 12px !important;
    font-size: 14px !important;
  }
  #investHeadingContainer {
    border-bottom: 0px;
    padding: 0px 12px !important;
  }
  #investorType {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: flex-end; */
  }
}
