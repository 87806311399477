/* Mobile first designs 
    - All CSS without @media is written for mobile design.
    - @media queries has been used for laptop and desktop designs.
    - Mobile: 0 - 700 
    - Laptop: 701 - 1000
    - Desktop 1000 - 
*/

.deal-term-wrapper {
  background: #ebf8ed;
  padding: 10px 15px;
  
}
.deal_text{
  border-bottom: 1px solid #000000;
  width: 40%;
  height: 28px;
  margin-bottom: 15px;
}
.deal-term-wrapper h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}
.deal-term-statement-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  border-bottom: 1px solid #CED4DA;
  padding-bottom: 5px;
}
.deal-term-statement {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 6px;
}
.deal-term-statement h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin: 0;
  color: #6d747a;
}
.deal-term-statement p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}
.tooltip-container {
  align-self: flex-end;
  margin-top: 5px;
}
.type-of-instrument {
  text-align: end;
  line-height: 20px !important;
}

.promo_code h3 {
  font-style: normal;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  margin-top: 16px;
  font-weight: 600;
  color: #3e4c9c;
}
@media screen and (min-width: 700px) {
  .promo_code h3 {
    font-style: normal;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #18a62b;
    margin-top: 24px;
  }
  .type-of-instrument {
    text-align: start;
    line-height: 20px !important;
  }
  .deal-term-wrapper {
    background-color: #f8f9fa;
    padding: 24px;
    border-radius: 20px;
  }
  .deal-term-statement {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  .deal-term-statement h4 {
    font-size: 14px;
    line-height: 20px;
    color: #6d747a;
  }
  .deal-term-statement p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 26px !important;
    color: #000000;
  }
  .tooltip-container {
    align-self: baseline;
  }
}
