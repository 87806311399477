.OpenChat{
  width: 412px; 
  height: 558px;
}

.online_sts{
  visibility: hidden;
  display: none;
}

/* Mobile First Css */
.chatbot-btn {
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* When chat bot modal is not open background color is this */
  background: #3e4c9c;
  z-index: 3000;
  border-radius: 50%;
  cursor: pointer;
  transition: all 300ms;
}
.chatbot-btn-active {
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* When Chat bot modal is open Show this background color */
  background-color: #283891;
  z-index: 3000;
  border-radius: 50%;
  cursor: pointer;
  transition: all 300ms;
}
.close-svg {
  width: 25px;
  height: 25px;
}
.chatbot-btn > img,
.chatbot-btn-active > img {
  display: flex;
  width: 25px;
  height: 25px;
  object-fit: contain;
}
.chat-container-closed {
  position: fixed;
  z-index: 0;
  width: 334px;
  bottom: 6rem;
  right: 5rem;
  opacity: 0;
  height: fit-content;
  background: #ffffff;
  border: 1px solid #bfc3de;
  border-radius: 24px;
  pointer-events: none;
  transition: all 300ms;
}
.chat-container-open {
  position: fixed;
  z-index: 2000;
  width: 334px;
  bottom: 6rem;
  right: 1rem;
  opacity: 1;
  height: fit-content;
  overflow: auto;
  background: #ffffff;
  border: 1px solid #bfc3de;
  border-radius: 24px;
  pointer-events: all;
  transition: all 300ms;
  padding: 12px 8px;
}
.heading {
  width: 314px;
  height: 120px;

  /* Primary/100 */

  background: #283891;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 16px;
}
.name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */

  text-align: center;
  letter-spacing: 0.01em;

  color: #ffffff;
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 0px;
}
.online {
  margin-bottom: 0px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: none;
  text-align: center;
  letter-spacing: 0.01em;

  color: #ffffff;
  position: relative;
}
.online::before {
  content: '';
  width: 12px;
  height: 12px;
  background: #88d392;
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}
.conversation-container {
  height: 22rem;
  overflow: auto;
  padding: 18px;
}
.message-input {
  display: flex;
  /* height: 5rem; */
  /* align-items: center; */
  justify-content: center;
  gap: 1rem;
  padding: 18px;
  padding-top: 0%;
}
.message-input input {
  width: 226px;
  height: 40px;

  background: #fafafa;
  /* lighter lighter grey */
  border: none;
  padding: 10px 24px;
  border-radius: 14px;
  outline: 1px solid #ced4da;
}
.message-input input::placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: start;
  letter-spacing: 0.01em;

  /* Blacks/Dark grey */

  color: #939ca3;
}
.send-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4dbc5c;
  border: none;
  position: relative;
}
.send-btn > svg {
  display: block;
  position: absolute;
  top: 55%;
  left: 55%;
  transform: translate(-70%, -55%);
  width: 15px;
  height: 15px;
}
.chat-bot-msg {
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  background: #e9ebf4;
  border-radius: 24px;
  padding: 10px 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: left;
  letter-spacing: 0.01em;

  /* Blacks/Black */

  color: #08090a;
  position: relative;
  margin-left: 12px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  float: left;
  clear: both;
}
.chat-bot-msg::before {
  content: '';
  position: absolute;
  inset: 0;
  width: 32px;
  height: 32px;

  /* Primary/100 */

  background: #283891;
  border-radius: 50%;
  top: -35px;
  left: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-bot-msg::after {
  content: '';
  position: absolute;
  inset: 0;
  width: 32px;
  height: 32px;

  /* Primary/100 */

  background: url(../../Assets//icons/VectorbotProfile.png);
  background-size: 12px;
  /* background-size: contain; */
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  top: -35px;
  left: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-bot-msg-without-icon {
  width: fit-content;
  max-width: 50%;
  height: fit-content;
  background: #e9ebf4;
  border-radius: 24px;
  padding: 10px 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  margin-bottom: 4rem;
  color: #08090a;
  float: left;
}
.user-msg {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  float: right;
  clear: both;
  padding: 10px 24px;
  background: #4dbc5c;
  border-radius: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: right;
  letter-spacing: 0.01em;
  margin-right: -1rem;

  /* Blacks/White */
  margin-bottom: 1rem;
  color: #ffffff;
}
.user-msg:last-of-type {
  margin-bottom: 0px;
}
.found-options {
  float: left;
  clear: both;
  padding: 0px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 18px;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.chat-bot-found-options {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: center;
  letter-spacing: 0.01em;

  /* Blacks/Black */

  color: #08090a;
  background: #ced4da;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  margin-bottom: 0px;
}
.arrow {
  display: inline-block;
  object-fit: contain;
  transform: rotate(-220deg);
  width: 12px;
  height: 12px;
  margin-left: 6px;
}
.chat-container-open::-webkit-scrollbar {
  display: none;
}
.conversation-container::-webkit-scrollbar {
  display: none;
}
/* -------------------------------- */
/* ------------------------------ */
@media only screen and (width >= 1000px) {
  .chatbot-btn {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* When chat bot modal is not open background color is this */
    background: #283891;
    z-index: 1000;
    border-radius: 50%;
    cursor: pointer;
  }
  .chatbot-btn-active {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* When Chat bot modal is open Show this background color */
    background: #283891;
    z-index: 3000;
    border-radius: 50%;
    cursor: pointer;
  }
  .close-svg {
    width: 30px;
    height: 30px;
  }
  
  .chatbot-btn > img,
  .chatbot-btn-active > img {
    display: flex;
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  .chat-container-closed {
    position: fixed;
    z-index: 0;
    width: 412px;
    bottom: 2rem;
    right: 9rem;
    opacity: 1;
    height: 500px;
    opacity: 0;
    background: #ffffff;
    border: 1px solid #bfc3de;
    border-radius: 24px;
    pointer-events: none;
    transition: all 300ms;
  }
  .chat-container-open {
    position: fixed;
    z-index: 2000;
    width: 412px;
    transform: none;
    bottom: 2rem;
    right: 9rem;
    opacity: 1;
    height: 500px;
    overflow: auto;
    background: #ffffff;
    border: 1px solid #bfc3de;
    border-radius: 24px;
    pointer-events: all;
    transition: all 300ms;
    padding: 12px 8px;
    display: flex;
    flex-direction: column;
  }
  .chat-container-open::-webkit-scrollbar {
    display: none;
  }
  .heading {
    width: 100%;
    height: 80px;

    /* Primary/100 */

    background: #283891;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 16px;
  }
  .name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 83% */

    text-align: center;
    letter-spacing: 0.01em;

    color: #ffffff;
    margin-bottom: 0px;
  }
  .online {
    margin-bottom: 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    display: none;
    text-align: center;
    letter-spacing: 0.01em;

    color: #ffffff;
    position: relative;
  }
  .online::before {
    content: '';
    width: 12px;
    height: 12px;
    background: #88d392;
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  }
  .conversation-container {
    height: 28rem;
    flex-grow: 1;
    overflow: auto;
    padding: 18px;
  }
  .conversation-container::-webkit-scrollbar {
    display: none;
  }
  .message-input {
    display: flex;
    height: 3.5rem;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 18px;
  }
  .message-input input {
    width: 80%;
    height: 40px;

    background: #fafafa;
    /* lighter lighter grey */

    border: none;
    border-radius: 10px;
    padding: 10px 24px;
    outline: 1px solid #ced4da;
  }
  .send-btn {
    border-radius: 50%;
    background: #4dbc5c;
    border: none;
    position: relative;
  }
  .send-btn > svg {
    position: absolute;
    top: 55%;
    left: 55%;
    transform: translate(-70%, -60%);
  }
  .chat-bot-msg {
    width: fit-content;
    max-width: 100%;
    height: fit-content;
    background: #e9ebf4;
    border-radius: 24px;
    padding: 10px 24px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    letter-spacing: 0.01em;
    color: #08090a;
    position: relative;
    margin-left: 12px;
    margin-top: 4rem;
    float: left;
    clear: both;
  }
  .chat-bot-msg::before {
    content: '';
    position: absolute;
    inset: 0;
    width: 48px;
    height: 48px;

    /* Primary/100 */

    background: #283891;
    border-radius: 50%;
    top: -55px;
    left: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .chat-bot-msg::after {
    content: '';
    position: absolute;
    inset: 0;
    width: 48px;
    height: 48px;

    /* Primary/100 */

    background: url(../../Assets//icons/VectorbotProfile.png);
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    top: -55px;
    left: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .chat-bot-msg-without-icon {
    width: fit-content;
    max-width: 50%;
    height: fit-content;
    background: #e9ebf4;
    border-radius: 24px;
    padding: 10px 24px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    margin-bottom: 4rem;
    color: #08090a;
    float: left;
  }
  .user-msg {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    width: fit-content;
    max-width: 100%;
    height: fit-content;
    /* float: right;
    clear: left; */
    float: right;
    clear: both;
    padding: 10px 24px;
    background: #4dbc5c;
    border-radius: 24px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: right;
    letter-spacing: 0.01em;

    /* Blacks/White */
    margin-bottom: 1rem;
    margin-left: auto;
    color: #ffffff;
  }
  .user-msg:last-of-type {
    margin-bottom: 0px;
  }
  .found-options {
    float: left;
    clear: both;
    padding: 0px 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;
    flex-wrap: wrap;
  }
  .chat-bot-found-options {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 143% */

    text-align: center;
    letter-spacing: 0.01em;

    /* Blacks/Black */

    color: #08090a;
    background: #ced4da;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    margin-bottom: 0px;
  }
  .arrow {
    display: inline-block;
    object-fit: contain;
    transform: rotate(-220deg);
    width: 12px;
    height: 12px;
    margin-left: 6px;
  }
  .send-btn > img {
    display: inline-block;
    width: 25px;
    height: 25px;
  }
}
/* Mobile-first CSS */
@media only screen and (max-width: 767px) {



  .chat-container-open {
    width: 90%; /* Adjust width to be responsive */
    /* max-width: 300px; Maximum width */
    bottom: 5rem; /* Adjust position */
    right: 50%; /* Center horizontally */
    transform: translateX(50%); /* Center horizontally */
    height: 75vh; /* Use viewport height */
    /* max-height: 460px; Maximum height */
  }

  .heading {
    width: 100%; /* Full width */
    height: auto; /* Adjust height automatically */
    padding: 0px;
    margin-top: 10px;
  }
  .online_sts{
    visibility: visible;
    display: flex;
    flex-direction: row;
    margin-top: -12%;
    margin-bottom: 5%;
    color: #ffffff;
    font-size: 14px;
  }
  .online_sts svg{
    margin-top: 10%;
    margin-right: 5%;
  }


  .name {
    font-size: 16px; /* Smaller font size */
  }

  .conversation-container {
    height: calc(100% - 180px); /* Adjust height */
    padding: 10px;
  }

  .message-input {
    padding: 10px;
  }

  .message-input input {
    width: calc(100% - 60px); /* Adjust width */
  }

  .chat-bot-msg,
  .user-msg {
    max-width: 80%; /* Limit message width */
    font-size: 14px; /* Smaller font size */
  }

  .found-options {
    padding: 0 10px;
  }

  .chat-bot-found-options {
    font-size: 12px;
    padding: 6px;
  }

  .chatbot-btn,
  .chatbot-btn-active {
    bottom: 1rem;
    right: 20px;
    width: 50px;
    height: 50px;
  }

  .close-svg,
  .chatbot-btn > img,
  .chatbot-btn-active > img {
    width: 20px;
    height: 20px;
  }
}