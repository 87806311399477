.our-team-wrapper {
  padding: 15px;
}

.member-container {
  margin-top: 24px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.member-profile {
  width: 113px;
  height: 113px;
  border-radius: 100%;
  align-self: center;
  background-color: #cccccc;
  background-image: url("../../Assets/vector/avatar.svg");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center 40%;
  object-fit: cover;
}

.member-detail-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.member-detail-header h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.2px;
  margin: 0;
  color: #000000;
}
.member-detail-header p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: #939ca3;
}
.member-detail-desc {
  font-family: "Poppins";
  font-style: normal;
  margin: 10px 0 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

@media screen and (min-width: 700px) {
  .member-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }
  .member-detail-header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
}
