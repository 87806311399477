#KycAadharDetailContainer {
  padding: 0 68px;
  color: #000;
}

#KycAadharDetailContainer form {
  padding: 32px 56px;
  border: 1px solid #ebedf0;
  box-shadow: 0px 1px 6px rgba(134, 134, 134, 0.25);
  border-radius: 12px;
}

#KycAadharDetail {
  margin-top: 48px;
  margin-bottom: 18px;
}

#KycAadharDetailContainer h1 {
  font-size: 24px;
}

#KycAadharDetailContainer h2 {
  font-size: 16px !important;
}

#KycAadharDetailContainer h3 {
  font-size: 14px;
}

#KycAadharDetailContainer input {
  padding: 14px 16px !important;
}

#ifscCode {
}

#KycAadharDetailContainer input[type="submit"] {
  margin-bottom: 140px;
  margin-top: 0;
  padding: 16px 44px !important;
}

.verify-btn {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.verify-btn:disabled:hover {
  border: 0 !important;
  color: white !important;
  cursor: not-allowed;
}
.send-otp-btn {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0 !important;
}
.send-otp-btn:disabled:hover {
  border: 0 !important;
  color: white !important;
  cursor: not-allowed;
}
.a_no {
  /* height: 48px; */
  width: 469px;
}
.otp_no {
  width: 336px;
}
.verify {
  margin-top: 32px;
  display: flex;
  align-items: flex-start;
  gap: 40px;
  flex-direction: column;
}
.verify button {
  margin: 0px;
}
/* for large phones */
@media only screen and (max-width: 699px) {
  .card_no {
    flex-direction: column;
    align-items: flex-end !important;
    gap: 10px !important;
  }
  .verify {
    align-items: flex-end;
    gap: 10px;
  }
  .a_no {
    /* height: 48px; */
    width: 100%;
  }
  .otp_no {
    width: 100%;
  }
  #KycAadharDetail {
    width: 60vw;
    margin: 48px auto;
  }
}
@media only screen and (max-width: 606px) {
  #KycAadharDetailContainer {
    padding: 0 20px;
  }
  #KycAadharDetail {
    width: 89vw;
    margin: 16px auto;
  }
  #KycAadharDetailContainer form {
    padding: 32px 0px;
    border: none;
    box-shadow: none;
    border-radius: none;
  }
}
@media only screen and (max-width: 498px) {
  #KycAadharDetailContainer h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
  #KycAadharDetailContainer h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
  #KycAadharDetailContainer h2 {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
  #KycAadharDetail button {
    width: 125px !important;
    height: 40px !important;
    font-weight: 600;
    font-size: 14px;
  }
  #KycAadharDetailContainer input {
    padding: 11px 16px !important;
    font-size: 14px;
  }
  .verify {
    margin: 0;
  }
  #KycAadharDetail {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}
