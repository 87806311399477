.deal {
  width: 185px;
  height: 193px;
  padding: 32px;
  border: 1px solid #bfc3de !important;
}

.deal h6 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
}
.green_active_1{
  color: #18A62B !important;
  font-weight: 600 !important;
}

.deal p {
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  margin: 0;
}

#retail > div {
  height: 100%;
}
#typeOfDeals {
  margin: 40px;
  
}
#typeOfDeals h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
}

#buttonContainer {
  /* margin-top: 57px; */
}

@media only screen and (max-width: 600px) {
  #types {
    grid-gap: 26px;
    height: fit-content;
    padding-top: 70px !important;
    padding-bottom: 134px !important;
  }
  #typeOfDeals {
    margin: 40px 14px;
    margin-bottom: 0;
    padding: 0 !important;
    /* height: 54vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between; */
  }

  .deal {
    width: 163px;
    height: 180px;
    padding: 32px 0;
    border: 1px solid #bfc3de !important;
  }

  .deal h6 {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0;
  }

  #retail {
    background-image: url('./../../Assets/images/retailpng.png');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-size: 100%;
    background-position-y: 200%;
    transition: all 500ms;
    cursor: pointer;
  }

  #angel {
    background-image: url('./../../Assets/images/angel-investor.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 131px;
    transition: all 500ms;
    background-position-x: 54px;
    cursor: pointer;
  }

  #retail.active {
    background-image: url('./../../Assets/images/retailpng.png');
    background-repeat: no-repeat;
    background-position-x: 0px;
    background-size: 100%;
    background-position-y: 125%;
    border: 1px solid #18a62b !important;
    color: #18a62b;
    box-shadow: 0px 0px 16px 0px rgba(107, 234, 96, 0.13),

  }

  #angel.active {
    background-image: url('./../../Assets/images/angel-investor.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 99px;
    transition: all 500ms;
    background-position-x: 54px;
    border: 1px solid #18a62b !important;
    color: #18a62b;
    box-shadow: 0px 0px 16px 0px rgba(107, 234, 96, 0.13)
  }
  #buttonContainer {
    margin-bottom: 18px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #types {
    grid-gap: 25px;
    /* padding-bottom: 74px !important; */
  }

  .deal {
    width: 170px;
    height: 190px;
    padding: 32px;
    border: 1px solid #bfc3de !important;
  }

  .deal h6 {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0;
  }

  #retail {
    background-image: url('./../../Assets/images/retailpng.png');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-size: 100%;
    background-position-y: 185%;
    transition: all 500ms;
    cursor: pointer;
  }

  #angel {
    background-image: url('./../../Assets/images/angel-investor.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 131px;
    transition: all 500ms;
    background-position-x: 54px;
    cursor: pointer;
  }

  #retail.active {
    background-image: url('./../../Assets/images/retailpng.png');
    background-repeat: no-repeat;
    background-position-x: 0px;
    background-size: 100%;
    background-position-y: 109%;
    border: 1px solid #18a62b !important;
    color: #18a62b;
  }

  #angel.active {
    background-image: url('./../../Assets/images/angel-investor.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 99px;
    transition: all 500ms;
    background-position-x: 54px;
    border: 1px solid #18a62b !important;
    color: #18a62b;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #types {
    grid-gap: 0 60px;
  }

  .deal {
    width: 170px;
    height: 190px;
    padding: 32px;
    border: 1px solid #bfc3de !important;
  }

  .deal h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #283891;
    margin-bottom: 0;
  }

  #retail {
    background-image: url('./../../Assets/images/retailpng.png');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-size: 100%;
    background-position-y: 185%;
    transition: all 500ms;
    cursor: pointer;
  }

  #angel {
    background-image: url('./../../Assets/images/angel-investor.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 131px;
    transition: all 500ms;
    background-position-x: 54px;
    cursor: pointer;
  }

  #retail.active {
    background-image: url('./../../Assets/images/retailpng.png');
    background-repeat: no-repeat;
    background-position-x: 0px;
    background-size: 100%;
    background-position-y: 109%;
    border: 1px solid #18a62b !important;
    color: #18a62b;
  }

  #angel.active {
    background-image: url('./../../Assets/images/angel-investor.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 99px;
    transition: all 500ms;
    background-position-x: 54px;
    border: 1px solid #18a62b !important;
    color: #18a62b;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #types {
    grid-gap: 0 60px;
  }

  #retail {
    background-image: url('./../../Assets/images/retailpng.png');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-size: 100%;
    background-position-y: 185%;
    transition: all 500ms;
    cursor: pointer;
  }

  #angel {
    background-image: url('./../../Assets/images/angel-investor.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 131px;
    transition: all 500ms;
    background-position-x: 54px;
    cursor: pointer;
  }

  #retail.active {
    background-image: url('./../../Assets/images/retailpng.png');
    background-repeat: no-repeat;
    background-position-x: 0px;
    background-size: 100%;
    background-position-y: 109%;
    border: 1px solid #18a62b !important;
    color: #18a62b;
  }

  #angel.active {
    background-image: url('./../../Assets/images/angel-investor.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 99px;
    transition: all 500ms;
    background-position-x: 54px;
    border: 1px solid #18a62b !important;
    color: #18a62b;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #types {
    grid-gap: 0 60px;
    margin: 27px 0 !important;
    padding: 30px 0 !important;
  }

  #retail {
    background-image: url('./../../Assets/images/retailpng.png');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-size: 100%;
    background-position-y: 185%;
    transition: all 300ms;
    cursor: pointer;
  }

  #angel {
    background-image: url('./../../Assets/images/angel-investor.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 131px;
    transition: all 300ms;
    background-position-x: 54px;
    cursor: pointer;
  }

  #retail.active {
    background-image: url('./../../Assets/images/retailpng.png');
    background-repeat: no-repeat;
    background-position-x: 0px;
    background-size: 100%;
    background-position-y: 130%;
    border: 1px solid #18a62b !important;
    color: #18a62b;
  }

  #angel.active {
    background-image: url('./../../Assets/images/angel-investor.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 99px;
    background-position-x: 54px;
    border: 1px solid #18a62b !important;
    color: #18a62b;
  }
}

@media only screen and (max-width: 345px) {
  #types {
    grid-gap: 0px;
    height: 70vh;
    padding: 30px 0 !important;
  }
  #typeOfDeals {
    margin: 40px 25px;
  }
}

@media only screen and (max-width: 365px) {
  #types {
    grid-gap: 6px;
  }
}
