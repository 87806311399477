#organizations {
  background: url(./../../Assets/images/organisations-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* padding: 41vh 0; */
  padding: 3rem 0px;
  margin-bottom: 3rem;
  height: 30rem;
}
.slider_desktop {
  display: block;
  width: 100%;
  height: 11rem;
  padding: 10px;
  padding-left: 90px;
  padding-right: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}
.center {
  height: 7rem !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}
@media only screen and (width >= 800px) {
  .Orgimg {
    width: fit-content;
    /* background-color: pink; */
  }
  .swiper-slide-width {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #organizations {
    background: url(./../../Assets/images/organisations-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3rem 0px;
    gap: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3rem;
    height: 25rem;
  }
  /* #swiper_slide_desktop_autoplay{
    display: flex !important;
    align-items: center !important;
    justify-content: center;
  } */
}
.slider_desktop img {
  display: block;
  width: 120px;
  height: 120px;
  object-fit: cover;
}
@media only screen and (width >=800px) {
  .slider_desktop img {
    display: block;
    transform: scale(0.7);
    object-fit: cover;
  }
}
/* .slider_desktop div{
  box-shadow: none !important;
  border-radius: 0px !important;
} */
#organisation_conatiner_mobile {
  display: none;
}
#orgHead {
  font-weight: 600;
  font-size: 28px;
}

#growHead {
  font-weight: 500;
  font-size: 40px;
  line-height: 40px; 

}

#growPara {
  font-weight: 400;
  font-size: 16px;
}

#growBtn {
  font-weight: 600;
  font-size: 14px;
  width: 153px;
  height: 55px;
  padding: 0.8em 1.3em;
}

#organizations img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

#joinGrowingBusinesses {
  width: 90%;
  margin: auto;
  padding: 72px 50px;
}

#joinGrowingBusinesses p {
  padding-top: 32px;
  padding-bottom: 32px;
}
.breaker {
  display: none;
}
@media only screen and (max-width: 800px) {
  .slider_desktop {
    display: none;
  }
  .Orgimg {
    /* opacity: 0.6; */
    width: 6rem;
    height: 6rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    background: #ffffff;
    box-shadow: 0px 1.07692px 20.4615px rgba(0, 0, 0, 0.125);
    overflow: hidden;
  }
  #growHead {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
  #growPara {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    
    /* or 157% */

    text-align: center;
  }
  #orgHead {
    font-weight: 600;
    font-size: 24px;
    width: 80%;
    margin: auto;
    margin-bottom: 1rem;
  }
  #organizations {
    padding: 0px;
    /* margin-bottom: 10rem; */
    width: 100%;
    height: fit-content;
  }
  .Orgimg img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  #organisation_conatiner_mobile {
    display: flex;
    align-items: center !important;
    justify-content: space-evenly !important;
    flex-wrap: wrap !important;
    gap: 10px;
    overflow: hidden;
    padding: 10px 0;
  }
  #joinGrowingBusinesses {
    width: 100%;
    padding: 1.5rem;
    padding-bottom: 3rem;
    margin: 0px !important;
    height: fit-content;
    border-radius: 0px !important;
  }
  #joinGrowingBusinesses p {
    padding: 0px 5px 0.5rem 0px;
  }
  #joinGrowingBusinesses button {
    padding: 5px 10px !important;
  }
  #growBtn {
    font-size: 12px !important;
    border-radius: 6px !important;
  }
}
/* @media only screen and (max-width: 425px) {
  .rowtwo1 {
    margin-left: 60px;
  }
  .rowtwo2 {
    margin-right: 60px;
  }
} */
