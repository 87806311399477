.swiper-slide {
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination-bullets {
  display: block;
}
#navigator0 {
  bottom: 80px !important;
}
#navigator1 {
  bottom: 80px !important;
}
.swiper-pagination-bullet {
  background: #ced4da !important;
  opacity: 1 !important;
}
.swiper-pagination-bullet-active {
  background: #6d747a !important;
}
.swiper {
  width: 100%;
  height: 550px;
}
.swiper-button-next {
  width: 44px;
  height: 44px;
  background: url('../../Assets/icons/swiper-bnt.png');
  border-radius: 50%;
  border: 1px solid #e5e7eb;
}
#swiper-next-bnt {
  width: 44px;
  height: 44px;
  background: url('../../Assets/icons/swiperbutton.png');
  top: 12.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#swiper-prev-bnt {
  width: 44px;
  height: 44px;
  background: url('../../Assets/icons/swiperbutton.png');
  top: 12.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.swiper-button-prev {
  width: 44px;
  height: 44px;
  background: url('../../Assets/icons/swiper-bnt.png');
  transform: rotate(180deg);
  border-radius: 50%;
  border: 1px solid #e5e7eb;
}
.swiper-button-prev::after {
  display: none;
}
.swiper-button-next::after {
  display: none;
}
#navigator2 {
  bottom: 80px !important;
}
@media only screen and (width <=1000px) {
  #navigator1 {
    bottom: 119px !important;
  }
  #navigator2 {
    bottom: 64px !important;
  }
}
