@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  scroll-behavior: auto;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#prev2 {
  font-size: 30px;
}
/* .modal.fade .modal-dialog {
  transform: translate(-50px, 0px);
}
.modal.show .modal-dialog {
  transform: none;
} */
/* #sidebar .active {    ****
  color: #283891; */
input[type="text"]:focus,
input[type="email"]:focus {
  box-shadow: none;
}

input[name="method"],
input[name="method2"] {
  accent-color: #3e4c9c;
  width: 20px;
  height: 20px;
}

.swiper-pagination-bullets {
  display: none;
}

input[type="radio"i]::before {
  width: 100px;
}

input[type="password"] {
  -webkit-text-security: none !important;
}

body {
  overflow-x: hidden;
}

.bg-blue {
  background-color: #283891;
}

/* color: #283891;
} */
.bg-green {
  background-color: #39b54a;
}

.bg-secondary-blue {
  background: #e9ebf4;
}

img#verified {
  width: 137px !important;
}

/* section {
  margin: 100px 0;
} */

.btn-secondary-blue {
  background-color: #e9ebf4;
  border: 1px solid #e9ebf4;
  border-radius: 8px;
  color: #283891;
  font-weight: 600;
}

.btn-secondary-blue:hover {
  border: 1px solid #283891 !important;
  background: transparent;
}

.gold {
  color: #dea700;
}

/* .options {
  width: 756px; */
.active {
  color: #39b54a !important;
}

.nav-link {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #6d747a !important;
}

.nav-link.active {
  color: #39b54a !important;
  font-weight: 500 !important;
}

.text-green {
  color: #18a62b;
  text-decoration: none;
  cursor: pointer;
}

.text-green:hover {
  color: #39b54a;
}

/* .card {
  width: 300px;
  height: 400px;
} */

.btn-green {
  background-color: #39b54a;
  border: 2px solid #39b54a;
  color: #e9ebf4 !important;
  position: relative;
  z-index: 100;
}

.btn-green:focus {
  background-color: #39b54a;
  border: 2px solid #39b54a;
  color: #e9ebf4 !important;
}

.btn-green:hover {
  background-color: transparent;
  border: 2px solid #39b54a !important;
  color: #39b54a !important;
}

.sticky-top {
  padding: 13px 0;
}

.carousel-item {
  height: 100%;
}

.carousel {
  border-radius: 0 12px 12px 0;
}
body {
  font-family: "Poppins", sans-serif;
}

#login-left {
  background: #283891;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  border-radius: 12px 0 0 12px;
  padding: 63px 0;
}

.carousel-item {
  height: 100%;
}

.carousel {
  border-radius: 0 12px 12px 0;
  font-weight: 700 !important;
  height: 100%;
  /* padding-top: 40px; */
}

#login-right {
  padding: 0;
}

#login-right p {
  margin-top: 18px;
  margin-bottom: 12px;
}

#emailOrPhone {
  margin-bottom: 0;
}

#or {
  margin: 12px 0 !important;
}

.login-form {
  border-radius: 12px;
  box-shadow: 0px 1px 6px rgba(134, 134, 134, 0.25);
}
@media only screen and (width <=700px) {
  .login-form {
    box-shadow: none;
  }
}
#login {
  width: 800px;
}

/* .container {
  height: 100vh;
  display: flex;
  align-items: center;
} */

.col-md-7 {
  padding: 0;
}

.carousel h1 {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 24px;
  line-height: 32px;
}

.carousel h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.carousel p {
  font-weight: 500;
  font-size: 14px;
}
.carousel h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.carousel p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 12px;
}

/* background-color: #E9EBF4;
color: #283891;
} */

.dropdown-toggle:hover {
  background-color: #18a62b;
  color: white;
}
.btn-blue {
  background-color: #283891;
  padding: 16px 24px;
}
.carousel input[type="text"] {
  border-radius: 8px;
}

.carousel input[type="text"]:focus {
  outline: 1px solid #d9d9d9;
}

.btn-blue {
  background-color: #283891;
  padding: 16px 24px;
  color: white !important;
  border: 0;
  margin-top: 16px;
  font-size: 14px;
  border-radius: 8px;
  border: 2px solid #283891;
  letter-spacing: 1px;
  text-decoration: none;
  font-weight: 600;
}

.btn-blue:disabled {
  background-color: #bfc3de !important;
  border: none;
}

.btn-blue:disabled:hover {
  background-color: #bfc3de !important;
  border: none !important;
  color: white !important;
}

.btn-blue:hover {
  background-color: transparent;
  color: #283891 !important;
  border: 2px solid #283891 !important;
  transition: all 200ms;
}
.rounded-image {
  position: absolute;
  border-radius: 100%;
  top: 100px;
  left: 20px;
}

.btn-outline-blue {
  background-color: #fff;
  border: 2px solid #0f1f78;
  color: #0f1f78;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
  padding: 16px 0;
  font-weight: 600 !important;
}

.btn-outline-blue span {
  color: #0f1f78;
}

.btn-outline-blue:hover {
  color: white !important;
}

.btn-outline-blue:hover span {
  color: white !important;
}

/* } */

.text-blue {
  color: #283891;
}

/* .text-blue:hover {
  color: #283891;
  border: 2px solid #283891;
  transition: all 200ms;
} */

.btn-outline-blue:hover {
  background-color: #283891;
  color: white;
  border: 2px solid #283891;
  transition: all 200ms;
}

.text-blue {
  color: #3d4b9b;
}

.text-blue:hover {
  color: #3d4b9b;
  text-decoration: none;
}

.signUpLink {
  color: #283891;
  color: #3d4b9b;
}

.signUpLink:hover {
  text-decoration: none;
  color: #283891;
}

#loginImage {
  margin-top: 20px;
  height: 250px;
  width: 250px;
}

/* font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 28px;

color: #6D747A;
} */

#heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  /* identical to box height, or 129% */
}
#rocket h2 {
  margin-top: 42px;
}

#testimonial .nav .active {
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(40, 56, 145, 0.2);
  border-radius: 16px;
}

/* color: #08090A;
} */
#testimonial .active {
  color: #000 !important;
}

#testimonial .hr {
  display: inline-block;
  width: 2.5px !important;
  height: 15px !important;
  opacity: 100% !important;
  background-color: #283891;
}

/* #raise-btn {
  background-color: #283891;
  color: white; */
.comment-name {
  border-right: 2px solid blue;
  font-weight: 600;
}

#searchbar {
  width: 272px;
  height: 40px;
}

img.star {
  width: 18px;
}

/* font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
identical to box height, or 143%

.recharts-surface {
  width: 333px;
  position: relative;
  top: 0;
  bottom: 0;
}

.recharts-wrapper {
  top: -37px;
}

.recharts-pie {
  position: absolute;
  top: 0;
}


} */

.swiper-slide {
  /* box-shadow: 0 0 100px #333; */
  border-radius: 1rem;
}

.swiper {
  border-radius: 1rem;
  width: 100%;
}

.swiper-wrapper {
  border-radius: 1rem;
}
@media only screen and (width <= 700px) {
  .swiper {
    border-radius: 0px;
  }
  .swiper-slide {
    border-radius: 0px;
  }
  .swiper-wrapper {
    border-radius: 0px;
  }
}

.disabled {
  background-color: #bfc3de !important;
  border-color: #bfc3de !important;
}

.step-done {
  background-color: #39b54a !important;
  border: none !important;
  color: white !important;
}

.step-active {
  background-color: #d7f0db !important;
  border: none !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

.rupee {
  font-family: "Hind", sans-serif;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(150, 149, 149, 0.743);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.modal-content {
  overflow: hidden;
  border-radius: 16px;
  /* padding-bottom: 40px; */
}
@media only screen and (width <=1000px) {
  .modal-content {
    overflow: hidden;
    border: none;
    border-radius: 0px;
    /* padding-bottom: 40px; */
  }
  .modal-dialog {
    margin: 0px;
    width: 100% !important;
    max-width: 100% !important;
    height: fit-content;
  }
}
.modal-header {
  border: none;
}

.nav-tabs .active {
  border: 0;
  font-size: 18px;
  font-weight: 500;
  color: #3e4c9c !important;
  border-bottom: 4px solid #3e4c9c !important;
}

.nav-tabs button {
  font-family: "Poppins", sans-serif;
}

.nav-tabs button:hover {
  border-color: none;
}

.investmentType.show::after {
  transform: rotate(90deg) !important;
  margin-bottom: 0 !important;
}

/* Input field error  */
.form-input-err input {
  color: red !important;
  border: red 2px solid !important;
}
.form-input-err input:focus {
  color: red;
  border: red 2px solid !important;
}
.form-input-err input::placeholder {
  color: red;
}
.input-err-msg {
  display: flex;
  align-items: center;
  margin: 5px 2px !important;
  /* margin-left: 2px; */
  gap: 4px !important;
  font-weight: 400 !important;
  color: red !important;
  font-size: 13px !important;
}
