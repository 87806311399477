.main {
  background-color: #e9ebf4;
  width: 100%;
  margin-bottom: 100px;
  padding-bottom: 40px;
  max-width: 653px !important;
}
.title {
  padding-left: 15px;
  padding-right: 15px;
}

.title2 {
  border-bottom: 2px solid #283891;
  width: 32.5%;
}
.comm_box{
  border-radius: 12px;
  background: #ffff;
  margin: 10px;
  padding: 5px;
  

}
@media screen and (width<=600px) {
  .title2 {
    width: 38%;
  }
  
}
@media (min-width: 1539px) {
  .title2 {
    border-bottom: 2px solid #283891;
    width: 23.4%;
  }
  .join_wrap input {
    border: none;
    background-color: none;
    font-size: 12px;
    color: #939ca3;
    font-weight: 400;
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 8px;
    width: 100%;
    padding-top: 0px !important;
    outline: none;
  }
  .join_wrap button {
    width: 59px;
    height: 26%;
    border-radius: 8px;
    background-color: #283891;
    border: none;
    color: #ffffff;
    font-weight: 600;
    font-size: 12px;
    margin-top: 15px;
    margin-left: 10px;
  }
  .post_div_wrap input {
    width: 100%;
  }
  .post_div_wrap button {
    width: 63px;
    height: 26px;
    border-radius: 8px;
    background-color: #283891;
    color: #ffffff;
    font-weight: 600;
    border: none;
    font-size: 12px;
    /* margin-right: 0.50vw; */
    margin-left: 10px;
  }
}
.title2 p {
  text-align: left !important;
  padding-top: 15px;
  font-size: 24px;
  color: #283891;
  font-weight: 600;
}

.join_us {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 21px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}
.join_us p {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin-top: -2px;
  margin-bottom: 15px;
}

.join_wrap {
  margin-top: 8px;
  padding-bottom: 28px;
  padding-top: 28px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  /* box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1); */
  position: relative;
  display: flex;
}

.join_wrap input {
  border: none;
  background-color: none;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  /* background-color: red; */
  border-radius: 8px;
  width: 86%;
  outline: none;
  position: absolute;
  left: 0%;
  top: 6px;
  padding-bottom: 23px;
  padding-right: 8px;
  color: #2f2f2f;
}
.join_wrap input:placeholder-shown {
  color: #939ca3;
}

.join_wrap button {
  width: 15%;
  height: 50%;
  border-radius: 8px;
  background-color: #283891;
  border: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  margin-top: 15px;
  padding-top: 3px;
  position: absolute;
  right: 3%;
  top: 0px;
}

.comment_model {
  background-color: #ffffff;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 0px;
  padding-bottom: 10px;
  position: relative;
  /* box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1); */
}
.for_radius {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.comment_model_wrap {
  padding: 10px;
  display: flex;
}

.content {
  padding-left: 7px;
  padding-top: 10px;
}

.comment_model_wrap img {
  width: 32px;
  height: 32px;
}

.comment_model_wrap h1 {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}

.comment_model_wrap p {
  color: #6d747a;
  font-size: 12px;
  font-weight: 400;
}

.time_ago {
  color: #6d747a;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  right: 15px;
  top: 13px;
}

.post_div {
  border-top: 1px solid #ced4da;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.post_div_wrap {
  display: flex;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.post_div_wrap button {
  width: 63px;
  height: 26px;
  border-radius: 8px;
  background-color: #283891;
  color: #ffffff;
  font-weight: 600;
  border: none;
  font-size: 12px;
  margin-right: 2vw;
}

.post_div_wrap input::placeholder {
  color: #939ca3;
  font-weight: 400;
}

.input_div input {
  width: 85%;
}
.triangle {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #ffffff;
  margin-bottom: 0px;
  position: absolute;
  top: 31%;
  left: 8%;
}

.view_more {
  margin-top: 15px;
  margin-bottom: 15px;
}
.view_more p {
  text-align: center !important;
  color: #283891;
  font-size: 12px;
  font-weight: 600;
}

.reply_component {
  width: 100%;
  padding-left: 40px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  position: relative;
  margin-bottom: 20px;
}

.reply_profile {
  display: flex;
}

.content2 {
  padding-left: 7px;
  padding-top: 3px;
}

.content2 h1 {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}

.content2 p {
  color: #6d747a;
  font-size: 12px;
  font-weight: 400;
}

.reply_profile_image img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
