.industry-overview-wrapper {
  padding: 15px;
}

.industry-overview-wrapper p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}
.swiper-container {
  margin-top: 20px;
  border-radius: 0;
}
.swiper-item {
  width: 250px !important;
  height: 150px;
  color: white;
  background-color: #283891;
  padding: 16px 23px;
  border-radius: 12px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}
.swiper-item p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* or 114% */

  color: #ffffff;
}

.swiper-item::-webkit-scrollbar {
  width: 7px;
}

@media screen and (min-width: 700px) {
  #IndustryOverview p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: 56px;
  }
}
