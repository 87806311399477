#organizations {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3rem;
  padding-top: 0%;
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 3rem;
}
#orgHead {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #283891;
}

.slider_desktop {
  display: block;
  width: 100%;
  height: 11rem;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.center {
  height: 7rem !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}
.swiper-slide-width {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider_desktop img {
  display: block;
  display: block;
  transform: scale(0.7);
  max-width: 100%;
}

/* Grow Section */
#growHead {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #283891;
}
#joinGrowingBusinesses {
  width: 90%;
  margin: auto;
  padding: 3rem;
  background: #e9ebf4;
  border-radius: 24px;
  text-align: center;
}
#joinGrowingBusinesses p {
  padding-top: 32px;
  padding-bottom: 32px;
}
.fund-raise-btn > button {
  font-family: 'Poppins';
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  padding: 16px 24px;
  border-radius: 8px;
  height: 48px;
  background-color: #283891;
  color: white !important;
  font-size: 14px;
  border: 2px solid #283891;
  letter-spacing: 1px;
  text-decoration: none;
  font-weight: 600;
  border: none;
}
.fund-raise-btn > button:hover {
  background-color: transparent;
  color: #283891 !important;
  border: 2px solid #283891 !important;
  transition: all 200ms;
}
.fund-raise-btn > button:focus {
  background-color: transparent;
  color: #283891 !important;
  border: 2px solid #283891 !important;
}
.organisation_conatiner_mobile {
  display: none;
}

@media only screen and (width < 992px) {
  #organizations img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  #orgHead {
    font-weight: 500;
    font-size: 25px;
    line-height: 27px;
  }

  /* Grow Section */
  #joinGrowingBusinesses {
    width: 100%;
    border-radius: 0;
  }
  #growHead {
    font-size: 30px;
  }
  .fund-raise-btn > button {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    padding: 16px 24px;
  }
}

@media only screen and (width < 767px) {
  .slider_desktop {
    display: none;
  }

  .organisation_conatiner_mobile {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 10px 0;
    width: 95%;
    margin: auto;
  }
  .Orgimg {
    width: 86px;
    height: 86px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    background: #ffffff;
    box-shadow: 0px 1.07692px 20.4615px rgba(0, 0, 0, 0.125);
    border-radius: 50%;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .Orgimg img {
    display: block;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (width < 605px) {
  #organizations {
    padding-left: 10px;
    padding-right: 10px;
  }
  #orgHead {
    font-size: 24px;
    line-height: 36px;
  }
  #growHead {
    font-size: 24px;
    line-height: 36px;
    
  }
}
