#registerPass {
  padding-top: 16px;
  padding-left: 56px;
  padding-right: 78px;
  /* height: 70vh; */
  font-weight: 500;
  color: #08090A;
}

#registerPass h6{
  font-weight: 500;
  color: #08090A;
}

#registerPass h1 {
  font-size: 24px;
  font-weight: 500;
  color: #08090A;
 
}

#password {
  position: relative;
  height: max-content;
}

#passwordField {
  -webkit-text-security: none;
}

#eye {
  position: absolute;
  top: 16px;
  right: 4%;
  cursor: pointer;
  transform: scaleX(-1);
}

#check {
  display: none;
}

.fa-eye-slash:before {
  font-size: 15px !important;
}

@media only screen and (max-width: 600px) {
  #registerPass {
    background: white;
    padding: 10px;
  }

  #registerPass h1 {
    margin-bottom: 36px;
    /* font-size: 20px; */
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #registerPass {
    background: white;
  }

  #registerPass h1 {
    margin-bottom: 36px;
    /* font-size: 20px; */
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #registerPass {
    background: #f7f8f9;
  }

  #registerPass h1 {
    margin-bottom: 36px;
    /* font-size: 20px; */
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #registerPass {
    background: #f7f8f9;
  }

  #registerPass h1 {
    margin-bottom: 36px;
    /* font-size: 20px; */
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #registerPass {
    background: #f7f8f9;
  }

  #registerPass h1 {
    margin-bottom: 36px;
    /* font-size: 20px; */
  }
}
