@media only screen and (width > 1000px) {
  #copyReferCodeContainer h1 {
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 4px;
    color: #000000;
  }
  .refer-header-container {
    width: 100%;
    height: fit-content;
    /* padding: 0 12px; */
  }
  #copyReferCodeContainer p {
    font-size: 18px;
    font-weight: 500;
    color: #6d747a;
    width: 40%;
    min-width: 20rem;
    margin-bottom: 0px;
  }

  #codeContainer {
    width: 388px;
    border-radius: 17px;
    margin-top: 23px;
    padding: 0px 39px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 8px solid var(--Blacks-White, #fff);
  }

  #copiedContainer {
    width: 388px;
    background-color: #fff;
    border: 8px solid white;
    border-radius: 17px;
    margin-top: 23px;
    padding: 74px 39px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #codeContainer p,
  #copiedContainer p {
    margin-top: 28px;
    font-size: 20px;
    color: #6d747a;
    padding-right: 0;
    padding-bottom: 10px;
  }
  .your-referral {
    text-align: center !important;
  }

  #code {
    width: 100%;
    border: 4px dashed #bfc3de;
    border-radius: 11px;
    padding: 15px 32px;
    position: relative;
  }
  #code img {
    position: absolute;
    right: 36px;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;
    height: 17px;
    cursor: pointer;
  }

  #code p {
    /* line-height: 24px;
    font-weight: 600;
    font-size: 24px;
    font-family: "Hind";
    margin-top: 0;
    color: #0f1f78;
    margin-bottom: 0; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 37px;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    color: #0f1f78;
  }

  #shareContainer {
    align-items: center;
    gap: 27px;
    height: 4rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  #shareContainer img {
    width: auto;
    height: 20px;
    cursor: pointer;
  }

  #share {
    width: 6.5rem;
    display: flex;
    align-items: center;
  }

  #share p {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 37px;
    color: black;
    margin-left: 18px;
    padding-right: 26px;
    cursor: default;
  }
}
.mobile-image {
  display: block;
  width: 75%;
  height: 75%;
  min-width: 16rem;
  min-height: 16rem;
  object-fit: contain;
}
.other-mobile-image {
  display: none;
}
@media only screen and (width <=1000px) {
  .mobile-image {
    display: none;
  }
  .other-mobile-image {
    display: block;
    width: 50%;
    height: 50%;
    margin-left: 1.2rem;
    min-width: 15rem;
    min-height: 15rem;
    object-fit: contain;
  }
  .refer-header-container {
    width: 100%;
    padding: 30px 0px;
    margin-left: -2%;
  }
  .refer-header-container h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #08090a;
  }
  .refer-header-container p {
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #6d747a;
    margin: 0px !important;
  }
  #codeContainer,
  #copiedContainer {
    width: 100%;
    height: fit-content;
    background: #e9ebf4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 1rem;
    /* padding-bottom: 1rem; */
    gap: 1rem;
  }
  .your-referral {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #6d747a;
    margin-bottom: 0;
  }
  .referral-code {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0f1f78;
    margin: 0px !important;
  }
  #code {
    border: 2px dashed #bfc3de;
    border-radius: 8px;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
    padding: 0px 1rem;
  }
  .copy-icon {
    display: block;
    width: 15px;
    height: 15px;
  }
  #shareContainer {
    width: 100%;
    height: 4rem;
    padding-bottom: 25%;
    margin-top: -2%;
    /* padding: 26px 0px; */
    background: #e9ebf4;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
  }
  #shareContainer img {
    display: block;
    width: 18px;
    height: 18px;
    object-fit: contain;
  }
  #share {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 1.4rem;
  }
  #share p {
    margin: 0px !important;
    font-weight: 500;
    font-size: 14px;
  }
}
