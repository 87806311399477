#investment {
  transition: all 0.5s;
  /* width: 73vw; */
  margin-left: 20px;
}

#investmentHeader h1 {
  font-size: 48px;
  font-weight: 600;
  /* margin-top: 27px; */
}
#investmentHeader h6 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 4px;
  color: #6d747a;
  width: 39%;
}

#search {
  background-color: #f8f9fa;
  padding: 24px;
  border-radius: 12px;
}

#searchInput {
  position: relative;
}

#searchInput input {
  background-color: #fff;
  border: none;
  outline: 0;
  padding: 10px;
  padding-left: 40px;
  padding-right: 33px;
  width: 272px;
  font-size: 14px;
}

#searchIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 16px;
}

#sort {
  display: flex;
  align-items: center;
  gap: 16px;
}

#sort label {
  margin-right: 16px;
}

#sort select {
  padding: 7px 24px;
}

#sortHead {
  font-size: 14px;
  font-weight: 500;
  color: #6d747a;
  margin-bottom: 0;
}

#sortList {
  padding: 7px 24px;
  color: #6d747a;
}

#sort li {
  cursor: pointer;
  padding: 7px 24px;
}

#tableHeader,
.tableContent {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  align-items: center;
  padding-top: 44px;
}

#tableHeader {
  background-color: #f8f9fa;
  padding: 24px;
  margin-top: 24px;
  border-radius: 8px;
}

.tableContent {
  padding: 24px;
  padding-top: 19px;
  padding-bottom: 14px;
  position: relative;
  cursor: pointer;
  transition: all 0.5s;
}

.tableHeaderText {
  font-family: 'Roboto', sans-serif !important;
  font-size: 18px;
  font-weight: 500;
}

.tableHeaderText span:hover {
  background-color: transparent;
  font-family: 'Roboto', sans-serif;
  color: #212529;
}

.name {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.invested {
  color: #18a62b;
  font-size: 18px;
  font-weight: 500;
}

.updates {
  display: none;
  position: absolute;
  left: 50%;
  font-size: 20px;
}

#rightArrow,
.arrow {
  transform: rotate(180deg);
}

.navigationArrow {
  border: 1px solid #283891;
  padding: 10px 15px;
  border-radius: 50%;
  opacity: 38%;
}

.navigationArrow img {
  width: 13px;
}

#arrowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  /* position: fixed; */
  bottom: 0px;
  left: 0;
  padding: 10px 0;
  right: 0;
  margin: auto;
  background: white;
}

#venturebuddyInvestment {
  text-decoration: none;
  padding: 8px 16px;
  color: white;
  background-color: #0f1f78;
  border-radius: 20px;
}

#optionContainer {
  margin-top: 24px;
}

#otherInvestment {
  background-color: #e9ebf4;
  padding: 8px 16px;
  margin-left: 16px;
  color: #6d747a;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  border-radius: 20px;
}

.download {
  color: #0085ff;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.download img {
  width: 12px;
  height: 12px;
}

#dropdownMenu {
  background: #f8f9fa;

  border: 1px solid #ced4da;

  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

#dropdownMenu li {
  padding: 8px 0;
  text-align: center;
  cursor: pointer;
}

#dropdownMenu li:hover {
  background-color: #0f1f78;
  color: white;
}

#optionsHeader {
  cursor: pointer;
}

#optionsHeader::after {
  content: '';
  border-top: 0;
  width: 6px;
  height: 10px;
  background-image: url('./../../Assets/arrow.png');
  background-repeat: no-repeat;
  background-size: cover;
  transform: rotate(-90deg);
  margin-bottom: -7px;
  transition: all 0.2s;
}

/* mY inveSt */
.myInvest {
  width: 73vw;
  margin-top: 44px;
  margin-left: 10px;
  height: 39vh;
  overflow-y: scroll;
}
.list {
  display: flex;
  justify-content: space-between;
}
.investpart {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}
.list h4 {
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  font-family: 'Roboto';
}
.investpart span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: #000000;
  margin-top: 14px;
  transform: translateY(-11px);
}
.investpart h5 {
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  margin: 0;
}
.s_name {
  display: flex;
  gap: 8px;
  align-items: center;
  /* padding: 10px 0; */
  height: 73px;
  justify-content: center;
}
.invest_logo a {
  border-bottom: 1px solid #eaecf0;
  width: 100%;
}
.s_name img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.s_name p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6d747a;
  margin: 0;
  text-align: center;
  /* transform: translateY(-11px); */
}
.s_name button {
  contain: ' ';
  width: 100px;
  height: 6px;
  background: #39b54a;
  border-radius: 4px;
  border: none;
}
.raise {
  display: flex;
  gap: 2px;
  flex-direction: column;
}
.raise p {
  margin: 0;
  transform: none;
}
.price p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6d747a;
}

/* Myinvestt with api*/

.invest {
  display: grid;
  /* grid-template-columns: 158px 194px 226px 118px 134px 145px; */
  grid-template-columns: repeat(6, 1fr);
  margin: 20px 0;
}
.invest li {
  list-style: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #6d747a;
  height: 73px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: #f8f9fa;
  border-bottom: 1px solid #eaecf0;
}
.invest_logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* gap: 20px; */
}
.invest .logo_invest {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 20px; */
}
.invest_logo h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #08090a;
  margin-bottom: 0;
}

.investTabel table tr:nth-child(1) {
  background: #f8f9fa;
}
.investTabel table {
  width: 1182px;
}
.investTabel table tr {
  border-bottom: 1px solid #eaecf0;
}
.investTabel table tr th {
  padding: 24px 25px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;

  letter-spacing: -0.5px;

  color: #6d747a;
}
.investTabel table tr td {
  padding: 24px 25px;
  text-align: center;
}
.investTabel {
  margin-top: 24px;
}

.tabelcompanyName h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #08090a;
  margin-bottom: 0;
}
.tabelcompanyName {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
}
.tabelcompanyName img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.valuations,
.investment {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6d747a;
}
.insturment {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #08090a;
}
.date {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6d747a;
}
.investTabelPhone {
  display: none;
}

@media only screen and (max-width: 991px) {
  .investTabel {
    display: none;
  }
  .investTabelPhone {
    display: block;
  }
  .investTabelPhone table {
    width: 100%;
  }
  .mobCompany {
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    padding: 25px 0;
    align-items: center;
  }
  .mobCompany h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #08090a;
    margin-bottom: 0;
  }
  .mobCompany img {
    width: 36px;
    border-radius: 50%;
  }
  .phoneInvestAmount p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    color: #6d747a;
  }
  .phoneInvestAmount {
    padding: 25px 0;
    display: flex;
    align-items: center;
  }
  .investTabelPhone {
    width: 95%;
    margin-top: 44px;
    margin: 20px auto;
  }
  .investTabelPhoneWraper {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eaecf0;
  }
}
@media only screen and (max-width: 680px) {
  .investTabelPhone {
    margin: 20px 0;
  }
  #investmentHeader h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 26px;
    margin-top: 16px;
    color: #000000;
  }
  #investmentHeader h6 {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    width: 100%;
    color: #6d747a;
  }
  .phoneInvestAmount,
  .mobCompany {
    padding: 15px 0;
  }
}
.viewmore {
  width: 128px;
  height: 48px;
  left: 116px;
  top: 815px;
  background: #ffffff;
  border: 1px solid #0f1f78;
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 30px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #0f1f78;
}
.btnWraper {
  display: flex;
  justify-content: center;
}
