#BankDetailsContainer {
  padding: 0 68px;
  color: #000;
}

#BankDetailsContainer form {
  padding: 32px 56px;
  border: 1px solid #ebedf0;
  box-shadow: 0px 1px 6px rgba(134, 134, 134, 0.25);
  border-radius: 12px;
}

#BankDetails {
  margin-top: 48px;
  margin-bottom: 48px;
}

#BankDetailsContainer h1 {
  font-size: 24px;
}

#BankDetailsContainer h2 {
  font-size: 16px !important;
  /* font-weight: 500 !important; */
  line-height: 24px !important;
}

#BankDetailsContainer h3 {
  font-size: 14px;
}

#BankDetailsContainer input {
  padding: 14px 16px !important;
}

#ifscCode {
  margin-top: 32px;
}

#BankDetailsContainer input[type="submit"] {
  margin-bottom: 140px;
  margin-top: 0;
  padding: 16px 44px !important;
}
.verify-btn {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.verify-btn:disabled:hover {
  border: 0 !important;
  color: white !important;
}

@media only screen and (max-width: 769px) {
  .Detail_box {
    width: 100% !important;
  }
}
@media only screen and (max-width: 606px) {
  #BankDetailsContainer form {
    padding: 32px 0px;
    border: none;
    box-shadow: none;
    border-radius: none;
  }
}
@media only screen and (max-width: 498px) {
  #BankDetailsContainer h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }
  #BankDetailsContainer h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
  }
  #BankDetailsContainer input {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  #BankDetailsContainer form button {
    width: 140px !important;
    height: 48px !important;
    margin: 0 auto !important;
    font-size: 12px;
  }
  #BankDetails {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  #BankDetailsContainer {
    padding: 0 24px;
  }
}
