/* Css without media query is written for mobile */
.main-about-us-container {
  padding: 12px;
}
.about-heading {
  padding-top: 0.5rem;
}
.about-heading p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  color: #08090a;
}
.about-para-container p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #6d747a;
}
.about-page-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.3rem;
}
.about-page-image img {
  display: block;
  width: 274px;
  height: 386px;
  object-fit: cover;
  border-radius: 8px;
}
.future-prospect-container {
  padding: 1.5rem 0.2rem;
}
.future-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 40px;
  text-align: center;
  color: #283891;
}
.future-prospect-card-holder {
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.testimonial-container {
  height: 20rem;
  background: #f9fafb;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonial-swiper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.team-heading p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 40px;
  /* identical to box height, or 182% */

  text-align: center;

  /* Primary/100 */

  color: #283891;
  margin-bottom: -5rem;
}
#team {
  margin-bottom: -7rem;
}
@media only screen and (width >=1000px) {
  .main-about-us-container {
    padding: 2rem 5rem;
  }
  .about-data-container-holder {
    display: flex;
    gap: 4rem;
    /* align-items: center; */
    justify-content: center;
    height: 35rem;
    margin-top: 50px;
    /* background-color: red; */
  }
  .about-data-container {
    width: 50%;
    margin-top: 20px;
  }
  .about-page-image {
    width: 50%;
    height: 100%;
  }
  .about-page-image img {
    display: block;
    width: 615px;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
  .about-heading {
    padding-top: 0rem;
  }
  .about-heading p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 46px;
    text-align: left;
    margin-bottom: 12px;
    color: #08090a;
  }
  .about-para-container p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    text-align: left;
    opacity: 0.6;
  }
  .future-heading p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    color: #283891;
    margin-top: 4rem;
  }
  .team-heading p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 62px;
    /* identical to box height, or 172% */

    text-align: center;

    /* Primary/100 */

    color: #283891;
    margin-bottom: -4rem;
  }
  #margin-none {
    margin-bottom: -2rem !important;
  }
  .team-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 6rem;
  }
  .founder-container {
    margin-bottom: 6rem;
  }
  .team-swiper {
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
  }
  .mentor-swiper {
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
  }
  .founder-swiper {
    width: 51.4rem;
    margin: auto;
  }
  .swiper-slide {
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .testimonial-container {
    width: 100%;
    box-shadow: 0 0 0 100vmax #f9fafb;
    clip-path: inset(0 -100vmax);
    height: 380px;
    background: #f9fafb;
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .testimonial-swiper {
    width: 65rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
