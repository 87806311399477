#footer {
  background: #e9ebf4;
  padding: 40px 112px;
  height: fit-content;
}
.desc {
  margin: 24px 0;
}
.desc p {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #6d747a;
}
#footer a {
  color: #6d747a;
  /* margin-right: 24px; */
}
.padding-0 {
  padding: 0px 5px;
}
#social img {
  width: 24px;
}

#social a {
  /* margin-right: 16px; */
}
.social_icons {
  display: flex;
  gap: 16px;
}
#footer input {
  padding: 8px 12px;
}

#footer button {
  background: #283891;
  border: 0;
  color: white;
  border-radius: 6px;
  padding: 8px 20px;
}
#footerText button {
  background: #ffffff;
  border-radius: 24px;
  color: #283891;
  font-weight: 500;
  font-size: 14px;
  margin: 0px !important;
}
#social_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 5rem; */
}
#footer_input_style_mobile div {
  width: fit-content;
}
#footer_input_style_mobile input {
  width: 312px;
}
@media only screen and (width > 700px) {
  #footer section:first-child {
    width: 50%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  #footerText {
    margin-top: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
    gap: 1.4rem;
  }
  .policy {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  #footer section {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    width: 481px;
  }
  #footer section h5 {
    display: inline-block;
    width: fit-content;
    align-self: flex-start;
    margin-left: 1.5rem;
  }
  #footer_upper_container {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center !important;
    justify-content: space-between !important;
  }
}

@media only screen and (width <=1000px) {
  .desc {
    display: none;
  }
  #footer {
    padding: 10px 5px;
    width: 100%;
    height: fit-content;
    margin: auto !important;
    overflow: hidden;
  }
  #footer section:first-child {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 1rem;
  }
  #footer section:first-child > img {
    width: 7rem;
    margin-left: -1rem;
  }
  #footerText {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 3rem;
    margin-top: 0px;
    word-break: normal;
  }
  #footerText a {
    margin: 0px;
    font-size: 16px;
  }
  #footer section:last-child {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: nowrap;
    /* align-items: flex-start; */
    justify-content: center;
    gap: 1rem;
    padding: 0px 1rem;
    position: relative;
  }
  #footer section h5 {
    display: inline-block !important;
    text-align: left;
    font-size: 14px;
    position: absolute;
    left: 15px;
    top: -24px;
    /* margin: 0px; */
  }
  #footer_input_style_mobile {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    padding: 0px;
  }
  #footer_input_style_mobile div {
    width: 100%;
    padding: 0px;
  }
  #footer_input_style_mobile input {
    width: 100%;
  }
  #footer_input_style_mobile button {
    float: right;
  }
  #social {
    margin: 2rem 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  #social_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .policy {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .policy a {
    font-size: 14px;
  }
}
