.itemHeader {
  font-weight: 500;
  font-size: 28px;
  line-height: 36.4px;
  color: #08090a;
}

.itemPara {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(147, 156, 163, 1);
}

.why-holder {
  
}

#howItWorks h1 {
  font-weight: 500;
  font-size: 40px;
  /* display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px; */
}
.how-it-works-icon {
  display: block;
  width: 64px;
  height: 64px;
  align-items: center;
}
#howItWorks p {
  color: #939ca3;
  font-size: 16px;
  font-weight: 500;
}

#HowItWorksHead {
  margin-bottom: 30px;
}

.hr {
  background-color: #000;
  width: 63px;
  height: 2px;
  background: #7e88bd;
  opacity: 0.6;
  margin: 16px 0;
}

.serial {
  font-size: 64px !important;
  font-weight: 700 !important;
  margin: 0px !important;
  height: fit-content !important;
}

#counts {
  background-color: #39b54a;
  min-height: 164px;
  max-height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 84px !important;
  margin-bottom: 84px !important;
}

#counts h1 {
  font-size: 48px;
  font-weight: 500;
}

#counts h6 {
  font-size: 18px;
  font-weight: 500;
}

.rowes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 88px;
}
@media only screen and (max-width: 600px) {
  .images_wrap {
    margin-left: 0 !important;
    margin-top: 5%;
  }
  .images_wrap_box {
    display: flex;
    justify-content: center;
  }
  #howItWorks {
    background: url('./../../Assets/images/organisations-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
  }
  #HowItWorks {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }
  #getStart {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #6D747A;
    /* identical to box height, or 171% */

    text-align: center;
  }
  .serial {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 32px !important;
    line-height: 3.5rem;
    text-align: center !important;
  }
  .itemHeader {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    /* identical to box height, or 26px */

    text-align: center;
  }
  .itemPara {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    text-align: center;
  }
  .rowes {
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
  }
}

@media (min-width: 600px) {
  #howItWorks {
    background: url('./../../Assets/images/organisations-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
  }
}

@media (min-width: 768px) {
  #howItWorks {
    background-size: 100%;
  }
}
@media only screen and (width <=700px) {
  .howItWorks_wrap {
    margin-top: 4rem !important;
  }
  #howItWorks h1 {
    font-weight: 500;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  #howItWorks p {
    /* color: #6d747a; */
    color: #939ca3;
    font-size: 14px;
    font-weight: 500;
  }
  .serial {
    font-size: 32px !important;
    font-weight: 700 !important;
  }
  #mobile_style_row_item {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    gap: 2rem;
    text-align: center;
  }
  #mobile_style_row_item h1 {
    font-size: 36px;
  }
  #mobile_style_row_item h6 {
    font-size: 18px;
  }
  .rowes {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-direction: column;
    padding: 28px 0;
  }
}

@media only screen and (width < 605px) {
  .serial {
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 5px !important;
  }
  .itemHeader {
    font-size: 18px;
    line-height: 130%;
    font-weight: 500;
  }
  .hr {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .why_text_container {
    padding-left: 0px !important;
  }
}

@media only screen and (width < 361px) {
  .itemPara {
    width: 270px;
    height: 72px;
  }
  .images_wrap{
    margin-top: 30%;
  }
}
