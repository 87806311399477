.options {
  margin-left: 68px;
}
@media only screen and (max-width: 816px) {
  .options {
    margin-left: 53px;
  }
}
@media only screen and (max-width: 600px) {
  .options {
    margin-left: 13px;
  }
}
@media only screen and (max-width: 445px) {
  /* .options {
    margin: 0 7px;
  } */
}
