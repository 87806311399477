.mobile_view_wrapper{
    width: 100%;
    background: linear-gradient(to right, #FFFFFF 0%, #EBF8ED 50%, #EBF8ED 50%, #FFFFFF 100%);
    padding-top: 0px;
    padding-bottom: 40px;
}
.clients_logos{
    width: 100%;
    display: flex;
    gap: 30px;
    padding-left: 20px;
    padding-top: 20px;
    overflow-x: scroll;
}
.mobile_view_main{
    padding-top: 40px;
}
.mobile_view_main h1{
    text-align: center;
    color: #283891;
    font-size: 24px;
    font-weight: 600;
}