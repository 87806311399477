.loading-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#DealsContainer {
  font-family: "Poppins", sans-serif;
  transition: all 0.5s;
  /* padding: 0px 40px 0px 40px; */
  width: 100%;
  padding-bottom: 40px;
}

#DealsContainer h1 {
  font-size: 48px;
  font-weight: 600;
  margin-top: 29px;
  margin-bottom: 4px;
}
#DealsContainer h6 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 24px;
  color: #6d747a;
}

.collapsed {
  /*padding-left: 145px;*/
}

.expanded {
  /*padding-left: 361px;*/
}

#cardContainer {
  margin-top: 24px;
  margin-left: 0;
}

#dealsHeading {
  margin-top: 32px;
}

#dealsHeading h1 {
  font-size: 32px;
  font-weight: 600;
}

#dealsHeading p {
  font-size: 18px;
  font-weight: 500;
  color: #6d747a;
}

.deals-container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

@media only screen and (max-width: 1000px) {
  #DealsContainer {
    width: 91vw;
    margin: 0 auto;
  }
  #DealsContainer h6 {
    width: 68% !important;
  }
}
@media only screen and (max-width: 600px) {
  #DealsContainer h6 {
    width: 90% !important;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
  }
  #DealsContainer h1 {
    font-weight: 600;
    font-size: 24px;
  }
}
