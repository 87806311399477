.contact_info {
  background-color: #3e4c9c;
  color: white;
  padding: 3rem;
  border-radius: 8px;
  flex: 1;
}
.contact_info h1 {
  text-align: center;
  font-size: 27px;
  letter-spacing: -0.02em;
  line-height: 36px;
  margin-bottom: 3.5rem;
  font-weight: 600;
}

.info_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-bottom: 57px;
}
.info_container .img_holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
}
.info_container .img_holder img {
  max-width: 100%;
}
.info_container p {
  margin-bottom: 0;
  font-weight: 500;
  flex: 1;
}
.info_container p a {
  color: white;
}

@media only screen and (max-width: 500px) {
  .contact_info {
    padding: 1.5rem;
  }
  .contact_info h1 {
    font-size: 24px;
  }
  .info_container p {
    font-size: 12px;
  }
  .info_container .img_holder {
    padding: 0.3rem;
  }
}

@media only screen and (max-width: 310px) {
  .info_container {
    flex-direction: column;
  }
  .info_container p {
    text-align: center;
  }
}
