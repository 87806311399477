.main1 {
  background-color: white;
}

.pitch_hero {
  background: rgba(116, 203, 128, 0.1);
  width: 100%;
  height: 100%;
  position: relative;
}
.pitch_hero img {
  width: 100%;
  /* margin-top: 70px; */
}
@media (width<=600px) {
  .pitch_hero img {
    margin-left: 1%;
    /* margin-top: 20px !important; */
  }
  .main2 {
    margin-top: 100px !important;
  }
}

/* .bg_green{
 
    position: absolute;
    width: 100vw;
    height: 50%;
    z-index: 1;
} */
.detail_hero {
  position: absolute;
  top: 100px;
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 2;
}
.detail {
  width: 100%;
  /* background-color: red; */
}
.detail p {
  text-align: center;
  color: #6d747a;
  font-size: 24px;
  font-weight: 400;
  padding-top: 10px;
}
.hero_text {
  font-size: 48px;
  color: #08090a;
  font-weight: 500;
  text-align: center;
}

.pitch_image_1 {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 70px;
}
.pitch_image {
  width: 500px;
  height: 500px;
}

@media (min-width: 1540px) {
  .pitch_image {
    width: 500px;
    height: 500px;
    margin-top: 40px;
  }
}

.download_btn {
  display: flex;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: 70px;
}
.download_btn button {
  width: 264px;
  height: 48px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #e9ebf4;
  border: none;
  outline: none;
  border-radius: 8px;
  color: #283891;
  font-size: 16px;
  font-weight: 600;
}

.white_bg {
  background-color: white;
  position: absolute;
  top: 50%;
  width: 100vw;
  height: 50%;
}

.main2 {
  width: 100%;
  height: 100%;
  /* background: linear-gradient(to top, #EBF8ED 100%, #EBF8ED 100%, #FFFFFF 100%); */
  background: linear-gradient(180deg, #ffffff 0%, #ebf8ed 27.4%, #ebf8ed 74.79%, #ffffff 100%);
  background-size: 100% 104%;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 200px;
}

.wrapper_pitch {
  background: linear-gradient(180deg, #ffffff 0%, #ebf8ed 27.4%, #ebf8ed 74.79%, #ffffff 100%);
  background-size: 100% 104%;
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 100px;
}

.pitch_deck_detail1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.deatil_2 {
  width: 45%;
  padding-top: 80px;
}

.deatil_2 h1 {
  font-size: 40px;
  color: #283891;
  font-weight: 600;
  text-align: center;
  padding-top: 10px;
}
.small_detail {
  width: 90%;
  margin-left: 34px;
}
.deatil_2 p {
  font-size: 16px;
  color: #6d747a;
  font-weight: 500;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.detail_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pitch_deatil_card {
  width: 412px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pitch_deatil_card h2 {
  font-size: 20px;
  color: #08090a;
  font-weight: 600;
}
.pitch_deatil_card h6 {
  padding-top: 10px;
  font-size: 14px;
  color: #6d747a;
  text-align: center;
  list-style: 24px;
}
.detail_03 {
  width: 78%;
  padding-left: 10px;
  padding-right: 10px;
  list-style: 24px;
}

.main3 {
  width: 100%;
  height: 100%;
  margin-top: 100px;
}
.pitch_deck_everyone_wrapper {
  display: flex;
  justify-content: center;
}
.pitch_deck_everyone {
  width: 90%;
  background-color: #e9ebf4;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 70px;
  padding-bottom: 60px;
}
.pitch_deck_everyone h1 {
  text-align: 40px;
  color: #283891;
  font-weight: 600;
  text-align: center;
}

.everyone_detail {
  width: 60%;
}
.everyone_detail p {
  font-size: 16px;
  color: #5360a7;
  font-weight: 400;
  text-align: center;
}

.pitch_deck_everyone button {
  width: 127px;
  height: 46px;
  background-color: #283891;
  color: white;
  font-weight: 500;
  border-radius: 8px;
  border: none;
  outline: none;
}
.main4 {
  margin-top: 110px;
}

.main5 {
  width: 100%;
  height: 100%;
  margin-top: 120px;
}

.main6 {
  margin-top: 100px;
}

.company_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.company_main {
  display: flex;
  justify-content: space-around;
  gap: 32px;
  margin-top: 30px;
}

.main6 {
  width: 100%;
  height: 100%;
  margin-top: 190px;
}

.client_about_detail h1 {
  text-align: center;
  font-size: 40px;
  color: #283891;
  font-weight: 600;
}
.client_about_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 80px;
  padding-left: 50px;
  padding-right: 50px;
}
.clients_profile {
  width: 40%;
  height: 90%;
  max-height: 90%;
}

@media (min-width: 1440px) {
  .clients_profile {
    width: 40%;
    height: 90%;
    max-height: 90%;
    padding-left: 5%;
    padding-right: 8%;
    margin-right: 9%;
  }
}
.border_card {
  box-shadow: 0px 0px 40px 0px rgba(40, 56, 145, 0.2);
  background-color: white;
}
.clients_review {
  width: 60%;
}
.client_card {
  width: 413px;
  height: 120px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* background-color: red; */
  border-radius: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.image img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
.client_name {
  display: flex;
  justify-content: center;
}
.client_name h1 {
  font-size: 20px;
  color: #08090a;
  font-weight: 600;
  padding-right: 10px;
}
.client_status {
  border-left: 3px solid #283891;
  padding-left: 8px;
  height: 19px;
  margin-top: 2px;
}
.client_status2 {
  font-size: 14px !important;
  color: #283891 !important;
  font-weight: 600 !important;
  padding-top: 2px !important;
}
.card_detail h5 {
  font-size: 16px;
  color: #6d747a;
  font-weight: 500;
}

.clients_review {
  padding-left: 2vw;
}

.clients_review h1 {
  font-size: 24px;
  color: #000000;
  font-weight: 600;
}
.review_start {
  display: flex;
  justify-content: space-around;
  width: 15%;
  margin-bottom: 10px;
}

.clients_review h6 {
  font-size: 20px;
  color: #6d747a;
  font-weight: 500;
  line-height: 32px;
  padding-top: 10px;
}

.main7 {
  width: 100%;
  height: 100%;
  margin-top: 120px;
}

.main8 {
  width: 100%;
  height: 100%;
}

.main9 {
  width: 100%;
  height: 100%;
  margin-top: 20px;
}
.hero_text2 {
  display: none;
}

.main05 {
  display: none;
}
.main07 {
  display: none;
}

.client_about_detail {
  display: none;
}

@media (max-width: 769px) {
  .hero_text {
    display: none;
  }
  .hero_text2 {
    display: block;
    font-size: 28px;
    color: #08090a;
    font-weight: 500;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .detail_hero {
    top: 40px;
  }
  .detail p {
    font-size: 14px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .pitch_image_1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 70px;
  }
  .pitch_image {
    width: 200px;
    height: 200px;
  }
  .main2 {
    padding-top: 180px;
  }
  .detail_list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 70px;
    align-items: center;
  }
  .pitch_deatil_card {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .pitch_deatil_card h2 {
    text-align: center;
    font-size: 16px;
  }
  .pitch_deatil_card h6 {
    font-size: 16px;
  }
  .deatil_2 {
    width: 100%;
    padding-top: 200px;
  }

  .deatil_2 h1 {
    font-size: 24px;
    color: #283891;
    font-weight: 600;
    text-align: center;
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .small_detail {
    width: 90%;
    padding-right: 10px;
  }
  .deatil_2 p {
    font-size: 16px;
    color: #6d747a;
    font-weight: 500;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .main3 {
    margin-top: -30px;
  }
  .main4 {
    margin-top: 40px;
  }
  .main9 {
    margin-top: -80px;
  }
  .pitch_deck_everyone {
    width: 100%;
    background-color: #e9ebf4;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .pitch_deck_everyone h1 {
    text-align: 24px;
    color: #283891;
    font-weight: 600;
    /* text-align: center; */
    padding-left: 15px;
    padding-right: 15px;
  }

  .everyone_detail {
    width: 100%;
  }
  .everyone_detail p {
    font-size: 14px;
    color: #5360a7;
    font-weight: 400;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .pitch_deck_everyone button {
    width: 127px;
    height: 46px;
    background-color: #283891;
    color: white;
    font-weight: 500;
    border-radius: 8px;
    border: none;
    outline: none;
  }
  .client_about_wrapper {
    display: none;
  }
  .client_about_detail h1 {
    text-align: center;
    font-size: 24px;
    color: #283891;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 20px;
  }
  .client_about_detail {
    display: block;
  }
  .company_wrapper {
    width: 100% !important;
    display: flex !important;
    overflow-x: scroll !important;
  }

  .company_main {
    flex-wrap: nowrap !important;
  }
  .mobile_div {
    width: 100% !important;
  }
  .main5 {
    width: 100%;
    height: 100%;
    margin-top: 120px;
    display: none;
  }
  .main05 {
    display: block;
    overflow: hidden;
    margin-top: 80px;
  }
  .main7 {
    display: none;
  }
  .main07 {
    display: block;
  }
}
