.main-container {
  width: 100%;
}
.faq-container {
  margin-top: 40px;
}
.input-container {
  padding: 2rem 8px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main-heading {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  /* identical to box height */

  text-align: center;

  /* Blacks/Black */

  color: #08090a;
}
.complement-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  /* or 214% */

  text-align: center;

  /* Blacks/Darker grey */

  color: #6d747a;
}
.input-holder {
  width: 100%;
  margin-top: 1.4rem;
  margin-bottom: 1.5rem;
  padding: 0px 8px;
}
.input-holder label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Blacks/Black */

  color: #08090a;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  margin-bottom: 1.3rem;
}
.input-holder label:has(textarea) {
  gap: 5px;
}
.input-holder input {
  width: 100%;
  padding: 10px 16px;
  border-radius: 8px;
  border: none;
  outline: 1px solid #939ca3;
}
.input-holder textarea {
  width: 100%;
  height: 8rem;
  padding: 10px 16px;
  border-radius: 8px;
  outline: 1px solid #939ca3;
  border: none;
  margin: 0px;
}
.input-holder input::placeholder,
.input-holder textarea::placeholder {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  /* Blacks/Darker grey */

  color: #6d747a;
}
.file-upload-container {
  width: 100%;
  height: 4rem;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23939CA3FF' stroke-width='4' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
}
.file-placeholder {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  /* Blacks/Darker grey */

  color: #6d747a;
  margin-bottom: 0px;
}
.addIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 10px;

  width: 40px;
  height: 40px;

  /* Primary/100 */

  background: #283891;
  object-fit: contain;
  cursor: pointer;
}
.file-name {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
}
.name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  /* Primary/100 */

  color: #283891;
}
.submit-btn button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;

  width: 107px;
  height: 48px;

  /* Primary/100 */

  background: #283891;
  border-radius: 8px;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  /* Blacks/White */
  outline: 2px solid #283891;
  transition: all 200ms;

  color: #ffffff;
}
.submit-btn button:disabled {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;

  width: 107px;
  height: 48px;

  /* Primary/50 */

  background: #939bc8;
  border-radius: 8px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  /* Blacks/White */
  outline: none;
  color: #ffffff;
  border: none;
}
.submit-btn > button:hover {
  background-color: transparent;
  outline: 2px solid #283891;
  color: #283891;
}
.submit-btn > button:disabled:hover {
  background: #939bc8;
  color: #ffffff;
  outline: none;
}
.benifit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0rem;
  padding: 8px;
}
.benifit-heading {
  width: 100%;
}
.benifit-heading > p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  text-align: center;

  /* Blacks/Black */

  color: #08090a;
}
.benifit-options {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem 0px;
}
.benifit-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  gap: 20px;

  width: 100%;
  height: 228px;

  /* Generic/White */

  background: #ffffff;
  /* Drop shadow/Small */

  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
  border-radius: 6px;
}
.image-holder {
  width: 48px;
  height: 48px;

  /* Primary/50 */

  background: #f5f5ff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Primary/50 */
  border-radius: 50%;
}
.image-holder > img {
  display: block;
  width: 18px;
  height: 18px;
  object-fit: contain;
}
.option-card-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  text-align: center;
  letter-spacing: -0.02em;

  /* Neutral/900 */

  color: #111827;
  margin-bottom: 0px;
}
.option-card-des {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* or 200% */

  text-align: center;
  font-feature-settings: 'salt' on;

  /* Neutral/500 */
  margin-bottom: 0px;
  color: #6b7280;
}
.faq-heading > p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  text-align: center;
  letter-spacing: -0.01em;

  color: #111827;
}
.file-name {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 10px;
  /* identical to box height, or 125% */

  /* Primary/100 */

  color: #283891;
  position: relative;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
}
.loader:hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  gap: 10px;

  width: 107px;
  height: 48px;

  /* Primary/100 */

  background: #939bc8 !important;
  border-radius: 8px;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  /* Blacks/White */
  outline: 2px solid #283891;
  transition: all 200ms;

  color: #ffffff;
}
.loader span {
  border-bottom: 2px solid white;
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-right: 2px solid transparent;
  display: block;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
  animation: loader-rotate 500ms infinite ease-out;
}
.error {
  font-size: 14px;
  font-weight: 600;
  color: red;
}
@keyframes loader-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (width >= 1000px) {
  .faq-container {
    margin-top: 96px;
  }
  .main-container {
    width: 100%;
  }
  .input-container {
    padding: 3rem 0px;
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .main-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    /* identical to box height, or 125% */

    /* Blacks/Black */

    color: #08090a;
    text-align: center;
    margin-bottom: 10px;
  }
  .complement-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    /* or 167% */

    text-align: center;

    /* Blacks/Darker grey */

    color: #6d747a;
  }
  .input-holder {
    width: 100%;
    margin-top: 1.4rem;
    margin-bottom: 0px;
    padding: 0px;
  }
  .input-holder label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    /* Blacks/Black */

    color: #08090a;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    margin-bottom: 1.3rem;
  }
  .input-holder label:has(textarea) {
    gap: 5px;
  }
  .input-holder input {
    height: fit-content;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    border: none;
    outline: 1px solid #939ca3;
    padding: 10px 16px;
    font-size: 18px;
  }
  .input-holder textarea {
    width: 100%;
    height: 8rem;
    padding: 10px 16px;
    border-radius: 8px;
    outline: 1px solid #939ca3;
    border: none;
    margin: 0px;
  }
  .input-holder input::placeholder,
  .input-holder textarea::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    /* Blacks/Darker grey */

    color: #6d747a;
  }
  .file-upload-container {
    width: 100%;
    height: 84px;
    /* outline: 1px dashed #6d747a; */
    outline: none;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23939CA3FF' stroke-width='4' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 8px;
  }
  .file-placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    /* Blacks/Darker grey */

    color: #6d747a;
    margin-bottom: 0px;
  }
  .addIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    gap: 10px;

    width: 53px;
    height: 52px;

    /* Primary/100 */

    background: #283891;
    object-fit: contain;
    cursor: pointer;
  }
  .file-name {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    /* Primary/100 */

    color: #283891;
  }
  .submit-btn button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    width: 107px;
    height: 48px;
    background: #283891;
    border-radius: 8px;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    outline: 2px solid #283891;
    color: #ffffff;
  }
  .submit-btn button:disabled {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    width: 107px;
    height: 48px;
    background: #939bc8;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    outline: none;
    color: #ffffff;
    border: none;
  }
  .benifit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .benifit-heading {
    width: 50%;
  }
  .benifit-heading > p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    text-align: center;

    /* Blacks/Black */

    color: #08090a;
  }
  .benifit-options {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 1rem 3rem;
  }
  .benifit-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;

    width: 385.33px;
    height: 228px;

    /* Generic/White */

    background: #ffffff;
    /* Drop shadow/Small */

    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
    border-radius: 6px;
  }
  .image-holder {
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    /* Primary/50 */
    border-radius: 50%;
    background: #f5f5ff;
  }
  .option-card-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    text-align: center;
    letter-spacing: -0.02em;

    /* Neutral/900 */

    color: #111827;
    margin-bottom: 0px;
  }
  .option-card-des {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    text-align: center;
    font-feature-settings: 'salt' on;

    /* Neutral/500 */
    margin-bottom: 0px;
    color: #6b7280;
  }
}
