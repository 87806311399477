/* Desktop First Css */
.main-blog-container {
  padding: 44px 112px;
  --margin-bottom: 18px;
}
.heading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.heading > h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 46px;
  /* identical to box height, or 115% */
  margin: 0px;
  color: #283891;
}
.blog-container {
  width: 100%;
}
.blog-image {
  width: 100%;
  display: flex;
  justify-content: center;
}
.blog-image > img {
  display: block;
  width: 100%;
  height: 484px;
  object-fit: cover;
  box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1);
  border-radius: 6px;
}
.blog-content {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.blog-title-and-content {
  width: 70%;
}
.blog-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* Light / 02 Greys fill/ Dark */
  margin: var(--margin-bottom) 0px;
  margin-bottom: 12px;
  color: #323338;
}
.blog-des {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  font-feature-settings: 'salt' on;

  /* Neutral/500 */

  color: #6b7280;
  margin: 0px;
}
.blog-author {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: var(--margin-bottom);
}
.author-image {
  display: block;
  width: 40px;
  height: 40px;
  object-fit: contain;
  border: 1.5px solid #ffffff;
  border-radius: 50%;
}
.author-name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: right;
  font-feature-settings: 'salt' on;

  /* Neutral/900 */

  color: #111827;
}
.date-time {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: right;
  font-feature-settings: 'salt' on;

  /* Neutral/500 */

  color: #6b7280;
}
.add-comment-container {
  width: 70%;
  display: flex;
  margin-top: 24px;
  gap: 12px;
 
}
.add-comment-container_2 > input[type='text'] {
  border: none;
  outline: none;
  
  width: 100%;
}
.add-comment-container_2{
  width: 90%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #939ca3;
}
.add-comment-container > input::placeholder {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  font-feature-settings: 'salt' on;

  /* Grey/Dark */

  color: #939ca3;
}
.time-required-to-read {
  display: none;
}
.author-name-wrapper{
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
.main-comment-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.all-comment-container{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 70%;
}
@media (min-width:1440px) {
  .container_maxWidth_1440{
    width: 100%;
    padding: 0 calc(50vw - 720px);
  }}
@media only screen and (width <= 800px) {
  .blog-image img{
    width: 100% !important;
  }
  .add-comment-container {
    width: 100%;
    display: flex;
    margin-top: 24px;
    gap: 12px;
  }
  .all-comment-container{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100% !important;
  }
  .main-blog-container {
    padding: 31px 12px;
    --margin-bottom: 18px;
  }
  .heading {
    width: 100%;
    justify-content: flex-start;
  }
  .heading h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 46px;
    /* identical to box height, or 192% */
    color: #283891;
  }
  .blog-image img {
    width: 100%;
    height: 300px;
    box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1);
    border-radius: 6px;
    object-fit: cover;
  }
  .blog-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    /* or 178% */

    /* Light / 02 Greys fill/ Dark */
    margin-bottom: 5rem;
    color: #323338;
  }
  .blog-title-and-content {
    width: 100%;
  }
  .blog-content {
    position: relative;
  }
  .blog-author {
    position: absolute;
    top: 5rem;
    flex-direction: row;
  }
  .blog-author .author-name-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0.8rem;
  }
  .author-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    font-feature-settings: 'salt' on;

    /* Neutral/900 */

    color: #111827;
  }
  .date-time {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    font-feature-settings: 'salt' on;

    /* Neutral/500 */

    color: #6b7280;
  }
  .time-required-to-read {
    --color: #6b7280;
    display: inline-block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    font-feature-settings: 'salt' on;

    /* Neutral/500 */

    color: var(--color);
    margin-left: 16px;
    position: relative;
  }
  .time-required-to-read::before {
    content: '';
    width: 3px;
    height: 3px;
    position: absolute;
    background-color: var(--color);
    top: 50%;
    transform: translateY(-50%);
    left: -9px;
  }
  .blog-des {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    font-feature-settings: 'salt' on;

    /* Neutral/500 */

    color: #6b7280;
  }
  .add-comment-container {
    width: 100%;
  }
  .add-comment-container > input::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    font-feature-settings: 'salt' on;

    /* Grey/Dark */

    color: #939ca3;
  }
}
