.header-wrapper section:has(.startup-logo) {
  padding: 20px 16px;
  display: flex;
  gap: 15px;
  align-items: center;
}
/* this style are related to the fake data container that we show when user is logged out */
#fake-data-container{
  flex: none !important;
  min-width: 50% !important;
  width: fit-content !important;
}
.startup-logo {
  width: 45px !important;
  /* width: auto !important; */
  min-height: auto !important;
  height: 45px !important;
  background-color: grey;
  border-radius: 8px;
  object-fit: cover;
}

.startup-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  margin: 0;
  color: #000000;
}

.startup-video-main {
  cursor: pointer;
  border-radius: 11.687px;
  overflow: hidden;
  height: 100%;
  object-fit: cover;
  background-color: #bbbebe;
  /* animation: skeletonLoading 1.5s linear infinite alternate; */
}

.play-btn-bg {
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;

  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.7s;
  background-color: rgba(0, 0, 0, 0.319);
}

/* ---------------------------------------------- */

.header-wrapper main {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.content-box {
  padding: 15px;
  background-color: rgba(233, 235, 244, 1);
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.details-box {
  padding: 24px;
}

/* Content Box */
.media {
  max-width: 680px;
  position: relative;
}
.media img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 10px;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  background-color: #ced4da;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 12px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  color: #08090a;
}

.short-desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6d747a;
}

/* Details Box  */
.details-box--top {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.commited-amount-graphic {
  flex: 100%;
  border-radius: 10px;
  position: relative;
}
.commited-amount-graphic .svg-line {
  height: 25px;
}

.commited-amount-graphic svg {
  transition: all 1s 0.5s ease;
  width: 100%;
  border-radius: 15px;
}
.commited-amount-graphic rect {
  transition: all 1s 0.5s ease;
}

.commited-amount-graphic .rocket {
  width: 35px !important;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-45%, -50%);
}

.commited-amount .value {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #283891;
  margin: 0;
}

.total-investors .value {
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #08090a;
  margin: 0;
}
.commited-amount .text,
.total-investors .text {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #6d747a;
  margin: 0;
}

/* ------------------------------------------- */

.min-amount,
.deadline {
  margin-top: 42px;
  position: relative;
}
.min-amount::before,
.deadline::before {
  content: "";
  position: absolute;
  top: -20px;
  width: 100%;
  height: 2.1px;
  background: #ced4da;
  border-radius: 21.0224px;
}

.min-amount .value,
.deadline .value {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;

  color: #08090a;
}
.min-amount .text,
.deadline .text {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #6d747a;
}

.invest-now-btn {
  margin-top: 24px;
  background: #283891;
  border-radius: 8px;
  height: 50px;
  width: 100%;
  padding: 0% 20%;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

.invest-now-btn:disabled {
  opacity: 0.7;
  pointer-events: none;
}
.addtional-info {
  display: none;
}

@media screen and (min-width: 700px) {
}

@media screen and (min-width: 1000px) {
  .header-wrapper section:has(.startup-logo) {
    padding: 20px 0px;
    display: flex;
    gap: 15px;
    align-items: center;
  }
  .startup-name {
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
    color: #000000;
  }
  .startup-logo {
    display: block;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  .media {
    max-width: 700px;
  }
  .short-desc {
    font-size: 18px;
    line-height: 26px;
    color: #6d747a;
    font-weight: 500;
  }
  .tag {
    font-size: 12px;
    font-weight: 500;
    color: #08090a;
    line-height: 16px;
    margin-right: 0px;
  }
  .header-wrapper main {
    display: flex;
    flex-direction: row;
    gap: 60px;
  }
  .content-box {
    flex: 55%;
    background-color: transparent;
    display: flex;
    gap: 25px;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 0;
  }
  .details-box {
    flex: 45%;
    padding: 0;
    height: fit-content;
    align-self: flex-end;
  }
  .value {
    font-size: 32px;
    font-weight: 600;
    color: #283891;
  }
  .text > span {
    font-size: 18px;
    font-weight: 500;
    color: #6d747a;
  }
  .details-box--top {
    flex-direction: column;
  }
  .commited-amount-graphic {
    order: 2;
  }
  .commited-amount-graphic .rocket {
    width: 30px !important;
  }
  .commited-amount {
    order: 1;
    display: flex;
    flex-direction: row;
    gap: 14px;
  }
  .total-investors {
    order: 3;
    margin-top: 35px;
    position: relative;
  }
  .total-investors::before {
    content: "";
    position: absolute;
    top: -20px;
    width: 100%;
    height: 2.1px;
    background: #ced4da;
    border-radius: 21.0224px;
  }

  .commited-amount-graphic .svg-line {
    height: 18px;
  }
  .commited-amount-graphic svg {
    border-radius: 10px;
  }

  .min-amount {
    display: none;
  }

  .invest-now-btn {
    height: 55px;
    width: 100%;
  }
  .addtional-info {
    display: block;
    margin-top: 10px;
    font-family: "Hind";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #6d747a;
  }
}
