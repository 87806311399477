.main-faq-container {
  width: 100%;
  margin: auto;
  height: fit-content;
  background: #ffffff;
  padding: 1rem 10px;
}
.main-faq-container p {
  transition: all 200ms;
}
.faq-dropdown {
  height: 4rem;
  overflow: hidden;
  padding: 10px;
  transition: all 200ms;
}
.faq-dropdown_open {
  transition: all 200ms;
  padding: 10px;
  min-height: 2rem;
}
.faq-dropdown_open > .dropdown-btn {
  color: #283891;
  border-bottom: none;
}
.dropdown-btn {
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  /* Text/100 */

  color: #49516a;

  padding-bottom: 1.4rem;
  cursor: pointer;
  border-bottom: 1px solid #e5e7eb;
}
.dropdown-btn img {
  transition: all 200ms;
}
.img-rotate {
  transform: rotate(180deg);
}
.faq-des {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  /* Text/100 */

  color: #49516a;
}
@media only screen and (width >= 1000px) {
  .main-faq-container {
    width: 70%;
    margin: auto;
    height: fit-content;
    background: #ffffff;
    /* Neutral/200 */

    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding: 3rem;
  }
  .main-faq-container p {
    transition: all 200ms;
  }
  .faq-dropdown {
    height: 4rem;
    overflow: hidden;
    padding: 10px;
    transition: all 200ms;
  }
  .faq-dropdown_open {
    transition: all 200ms;
    padding: 10px;
    overflow: visible;
    background: #f9fafb;
  }
  .faq-dropdown_open > .dropdown-btn {
    color: #111827;
    border-bottom: none;
  }
  .dropdown-btn {
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    font-feature-settings: "salt" on;

    /* Neutral/900 */

    color: #111827;
    padding-bottom: 1.4rem;
    cursor: pointer;
    border-bottom: 1px solid #e5e7eb;
  }
  .dropdown-btn img {
    transition: all 200ms;
  }
  .img-rotate {
    transform: rotate(180deg);
  }
  .faq-des {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    font-feature-settings: "salt" on;

    /* Neutral/500 */

    color: #6b7280;
  }
}
