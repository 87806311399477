#liveDealsContainer {
  font-family: "Poppins", sans-serif;
  transition: all 0.5s;
  /* padding: 0px 40px 0px 40px; */
  width: 100%;
  padding-bottom: 40px;
  padding-left: 12px;
  padding-right: 12px;
}

#liveDealsContainer h1 {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 4px;
  margin-top: -20px;
}
#liveDealsContainer h6 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 24px;
  color: #6d747a;
}

.collapsed {
  /*padding-left: 145px;*/
}

.expanded {
  /*padding-left: 361px;*/
}

#cardContainer {
  margin-top: 24px;
  margin-left: 0px;
  /* position: relative; */
  /* z-index: -1; */
}

#dealsHeading {
  margin-top: 32px;
}

#dealsHeading h1 {
  font-size: 32px;
  font-weight: 600;
  color: #000000;
}

#dealsHeading p {
  font-size: 18px;
  font-weight: 500;
  color: #6d747a;
}

.deals-container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
@media only screen and (max-width: 1000px) {
  #liveDealsContainer {
    width: 91vw;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
  #liveDealsContainer h6 {
    width: 68% !important;
  }
  /* .title {
    font-weight: 600;
    font-size: 24px;
  }
  .sub {
    font-weight: 500;
    font-size: 16px;
  } */
  .deals-container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }
}
/* Add these new styles at the end of your existing CSS file */

@media only screen and (max-width: 600px) {
  #liveDealsContainer {
    padding-top: 60px; /* Adjust this value to move the content down */
    margin-top: -40px; /* This will make part of the content go above the navbar */
  }

  #liveDealsContainer h1 {
    font-size: 28px; /* Slightly smaller font for mobile */
    margin-top: 0;
  }

  #liveDealsContainer h6 {
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 20px;
  }
}
