.tooltip-container {
  min-width: 1rem;
  width: 1rem;
  min-height: 1rem;
  height: 1rem;
  /* padding: 1px; */
  display: inline-block;
  text-align: center;
  /* margin: 5px 10px;*/
}
.tootlip-text-container {
  width: 100%;
  height: 100%;
  background: url('../../Assets/info.svg');
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}
.tooltip-text {
  position: absolute;
  padding: 8px 10px;
  color: #08090a;
  font-weight: 400;
  text-align: left;
  width: max-content;
  max-width: 250px;
  background: #ebedf0;
  box-shadow: 0px 1px 2px rgba(8, 9, 10, 0.08);
  border-radius: 8px;
  font-size: 12px;
  opacity: 0;
  pointer-events: none;
  transition: all 200ms;
  z-index: 1000;
}
.tootlip-text-container:hover .tooltip-text {
  display: block;
  opacity: 1;
  pointer-events: all;
}
