.nameBtn {
  background-color: #fff;
  border: 0;
  width: 100%;
}

.check {
  position: relative;
}
.mobile_testimonial_container{
  margin-top: 0% !important;
  margin-bottom: 2% !important;
}

.names .active {
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(40, 56, 145, 0.2) !important;
  border-radius: 16px !important;
}

#testimonial .active {
  color: #000 !important;
}
.testimonila-button-container {
  max-height: 29.5rem;
  overflow-y: auto;
  position: relative;
}
.testimonila-button-container::-webkit-scrollbar {
  display: none;
}

#sectionHeader {
  font-size: 36px;
  font-weight: 600;
  /* padding-top: 177px; */
  color: #283891;
  margin-bottom: 3rem;
}
.commentName p {
  text-transform: capitalize;
  margin-bottom: 1.3px;
}
.comment {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #6d747a;
  text-align: justify;
  /* width: 45rem; */
}
.flex_grow_100 {
  margin-right: 1rem;
}

.commentHeader {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.commentdest {
  text-transform: capitalize;
}
.commentName {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  display: flex;
  color: black;
  gap: 3.5px;
}

.commentName span {
  font-size: 14px;
  text-transform: uppercase;
}

.designation {
  color: #283891;
  margin: 0;
  text-transform: uppercase;
  font-size: 14px;
  display: inline-block;
}

.para {
  text-align: left;
}

.testimonial-image {
  width: 60px;
  height: 60px;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 100%;
}

button:has(.testimonial-image) {
  max-width: 500px;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  button:has(.testimonial-image) {
    max-width: 600px;
    width: 100%;
  }
  .comment {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    text-align: justify;
  }
}
.mobile_testimonial_container {
  display: none;
}
#desktop_testimonial_container {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 0px 10px;
  flex-wrap: wrap;
}
.flex_grow_100 {
  flex-grow: 1 !important;
}
@media only screen and (width <= 700px) {
  .testimonial_container {
    padding: 0;
  }
  #sectionHeader {
    font-size: 24px;
    width: 70%;
    padding-top: 0px;
    font-weight: 500;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #283891;
  }
  .main-testimonial-container {
    position: relative;
  }
  .mobile_testimonial_container {
    display: block;
    width: 100%;
    min-height: 26rem;
    overflow-x: auto;
    position: relative;
  }
  .mobile_testimonial_container > div {
    width: fit-content;
    display: flex;
    gap: 15px;
    padding: 0 20px;
  }
  #desktop_testimonial_container {
    display: none;
  }
  .testimonial-navigator {
    position: absolute;
    width: 12rem !important;
    height: 3rem;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .circle {
    width: 9px;
    height: 9px;
    background-color: rgba(217, 217, 217, 1);
    border-radius: 50%;
  }
  .active-circle {
    width: 12px;
    height: 12px;
    background: #3e4c9c;
    border-radius: 50%;
  }
}
