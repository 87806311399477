.otp {
  width: 54px;
  height: 52px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #d9d9d9;
}
.not_r {
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 23px !important;
}
#changeText {
  cursor: pointer;
  font-weight: 700;
}

#loginRight {
  padding: 40px;
}
.otp_box {
}
.usename_p {
  margin-top: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.usename_otp {
  font-weight: 600 !important;
}
@media only screen and (max-width: 475px) {
  #loginRight {
    padding: 10px;
  }
  .otp_box {
    justify-content: start !important;
    gap: 24px;
  }
  .tittle {
    margin-top: 0px !important;
  }
  .otp {
    width: 44px;
    height: 44px;
  }
}
