.main2 {
    margin-top: 235px;
    overflow: hidden;
    padding-bottom: 200px;
    min-height: auto;
}

@media (min-width: 1539px) {
    .main2 {
        margin-top: -170px;
        overflow: hidden;
        padding-bottom: 200px;
    }
    .main3 {
        margin-top: 1000px;
    }
    .main4 {
        margin-top: -30px;
    }
}

.main {
    background-color: white;
}

.main4 {
    margin-top: -1000px;
}

.back_image {
    width: 100vw;
    object-fit: cover;
    margin-top: 80px;
}

.here_scrope_heading {
    width: 100%;
}

.here_scrope_heading h1 {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #0F1F78;
}

.here_scrope_heading h2 {
    font-weight: 400;
    color: #6D747A;
    text-align: center;
    font-size: 16px;
}

.here_scrope_wrapper {
    width: 100%;
    height: 100%;
}

.hero_scope_wrapper_main {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: relative;
}

.main_here_1 {
    width: 80%;
    border-radius: 24px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    height: 704px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
}

.main_here_left {
    height: 40%;
    margin-right: 590px;
}

.green_with_text1 {
    display: flex;
    background-color: white;
}

.green_dot {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #D7F0DB;
}

.green_with_text1 p {
    margin-left: -12px;
    padding-top: 3px;
    color: #283891;
    font-size: 20px;
    font-weight: 400;
}

.green_with_text1:nth-child(1) {
    position: absolute;
    left: 32%;
    top: 6%;
}

.green_with_text1:nth-child(2) {
    position: absolute;
    left: 38%;
    top: 15%;
}

.green_with_text1:nth-child(3) {
    position: absolute;
    left: 43%;
    top: 27%;
}

.green_with_text1:nth-child(4) {
    position: absolute;
    left: 50%;
    top: 35%;
}

.green_with_text1:nth-child(5) {
    position: absolute;
    left: 55%;
    top: 44%;
}

.green_with_text1:nth-child(6) {
    position: absolute;
    left: 52%;
    top: 54%;
}

.green_with_text1:nth-child(7) {
    position: absolute;
    left: 47%;
    top: 64%;
}

.green_with_text1:nth-child(8) {
    position: absolute;
    left: 44%;
    top: 74%;
}

.green_with_text1:nth-child(9) {
    position: absolute;
    left: 40%;
    top: 84%;
}

.main3 {
    margin-top: 280px;
}

.industry_heaing {
    width: 100%;
}

.industry_heaing h1 {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    color: #0F1F78;
}

.industry_card {
    width: 297.67px;
    height: 300px;
    position: relative;
}

.industry_card img {
    width: 100%;
    height: 100%;
}

.all_industry {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-left: 60px;
    max-width: 1440px;
}

.inudstry_wrapper_main {
    width: 100%;
    max-width: 1440px;
    display: flex;
    justify-content: center;
    padding-left: 2.1vw;
}

.card_title {
    position: absolute;
    bottom: 14%;
    align-items: center;
    left: 16%;
}

.card_title p {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 28px;
}

.card_underline {
    width: 50%;
    height: 2.79px;
    background-color: white;
}

.card_underline_wrapper {
    display: flex;
    justify-content: center;
}

.mian_package_image_green {
    transform: translateX(-950px);
    position: absolute;
    z-index: -2;
    top: 10%;
}

.company_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.company_main {
    display: flex;
    justify-content: space-around;
    gap: 32px;
    margin-top: 40px;
}

.main07 {
    display: none;
}

.mobile_view_client {
    display: none;
}

.main6 {
    display: block;
}

.main06 {
    display: none;
}

.hero_text2 {
    display: none;
}
.brt{
    display: none;
}

@media (max-width: 769px) {
    .all_industry {
        display: grid;
        /* flex-wrap: wrap; */
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        margin-left: 0px;
        width: 100%;
    }
    .card_title p {
        color: #FFFFFF;
        font-weight: 700;
        font-size: 14px;
    }
    
    .industry_card{
        width: fit-content;
        height: fit-content;
    }
    .card_underline {
        width: 50%;
        height: 1.5px;
        margin-top: -10% !important;
        background-color: white;
    }
    .hero_text2 {
        display: block;
    }
    .main06 {
        display: block;
    }
    .main6 {
        display: none;
    }
    .main2 {
        margin-top: 100%;
        overflow: hidden;
        padding-bottom: 200px;
    }
    .here_scrope_heading {
        width: 100%;
        padding-top: 155px;
    }
    .here_scrope_heading h1 {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        color: #0F1F78;
    }
    .here_scrope_heading h2 {
        font-weight: 400;
        color: #6D747A;
        text-align: center;
        padding-top: 15px;
        font-size: 16px;
    }
    .main_here_1 {
        width: 95%;
        border-radius: 24px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        height: 850px;
        display: block;
        justify-content: center;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .green_with_text1 {
        display: flex;
        align-items: center;
        margin: auto;
    }
    .green_dot span {
        padding: 10px;
    }
    .green_with_text1 p {
        display: flex;
    }
    .green_dot {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #D7F0DB;
    }
    .green_with_text1 p {
        margin-left: 1px;
        padding-top: 3px;
        color: #283891;
        font-size: 20px;
        font-weight: 400;
    }

    .green_with_text1:nth-child(1) {
        position: absolute;
        left: 7%;
        top: 20%;
    }
    .green_with_text1:nth-child(2) {
        position: absolute;
        left: 8%;
        top: 26%;
    }
    .green_with_text1:nth-child(3) {
        position: absolute;
        left: 8%;
        top: 44%;
    }
    .green_with_text1:nth-child(4) {
        position: absolute;
        left: 8%;
        top: 54%;
    }
    .green_with_text1:nth-child(5) {
        position: absolute;
        left: 8%;
        top: 60%;
    }
    .green_with_text1:nth-child(6) {
        position: absolute;
        left: 8%;
        top: 69%;
    }
    .green_with_text1:nth-child(7) {
        position: absolute;
        left: 9%;
        top: 75%;
    }
    .green_with_text1:nth-child(8) {
        position: absolute;
        left: 8%;
        top: 85%;
    }
    .green_with_text1:nth-child(9) {
        position: absolute;
        left: 8%;
        top: 91%;
    }
    .main_here_left {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .main_here_left img {
        width: 200px;
        height: 101px;
    }
    .mian_package_image_green {
        transform: translateX(-200px);
        position: absolute;
        z-index: -2;
        top: 0%;
    }
    .industry_heaing h1 {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        color: #0F1F78;
    }
    .main3 {
        margin-top: 100px;
    }
    .main7 {
        display: none;
    }
    .main07 {
        overflow: hidden;
        display: block;
    }
    .company_wrapper {
        width: 100%;
        display: flex;
        overflow-x: scroll;
    }
    .company_main {
        flex-wrap: nowrap;
    }
    .mobile_view_client {
        display: block;
    }
    .pitch_image_1 {
        margin-top: 25px;
    }
    
}
/* Adjustments for .detail_hero */
@media (max-width: 768px) {
    .detail_hero {
        padding: 20px;
    }
    .detail_hero .detail {
        padding-top: 20px;
    }
    .detail_hero .hero_text {
        font-size: 24px;
        text-align: center;
    }
    .detail_hero .hero_text2 {
        padding: 0 10px;
    }
    .detail_hero .pitch_image_1 {
        margin-top: 25px;
    }
    .detail_hero .download_btn button {
        width: 100px;
        
    }
    
}

/* Adjustments for .main2 and .green_with_text1 */
@media (max-width: 768px) {
    .main2 {
        padding: 10px;
        margin-top: 50% !important;
    }
    .here_scrope_heading h1, .here_scrope_heading h2 {
        font-size: 20px;
        text-align: center;
    }
    .main_here_left img {
        width: 100%;
        /* height: 50px; */
    }
    .green_with_text1 {
        display: flex;
        align-items: center;
        padding: 10px;
        font-size: 14px;
        width: fit-content;
        background: transparent;
        /* gap: 10px; */
        /* margin-bottom: 2%; */
    }
    .green_dot {
        display: none;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        /* right: 10%; */
        /* margin-right: 10px; */
    }
    .brt{
        display: block;
    }
    .main_here_1 {
        flex-direction: column;
    }
}

/* General responsive adjustments */
@media (max-width: 768px) {
    .main1, .main2, .main3, .main4, .main5, .main6, .main7, .main8, .main9, .main07, .main06 {
        padding: 10px;
    }
    .wrapper_pitch, .here_scrope_wrapper, .detail_list {
        flex-direction: column;
        align-items: center;
    }
    .pitch_deatil_card img {
        width: 100%;
        height: auto;
    }
    .industry_card img {
        width: 100%;
        height: auto;
    }
    .main_here_right{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}
.download_btn{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.download_btn button{
    width: 264px;
    height: 48px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #E9EBF4;
    border: none;
    outline: none;
    border-radius: 8px;
    color: #283891;
    font-size: 16px;
    font-weight: 600;
}