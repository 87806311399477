.joinHeader {
  font-weight: 500;
  font-size: 40px;
  line-height: 56px;
  padding: 0px;
  margin: 0px;
}

#container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.joinPara {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #5360a7;
}

#container {
  padding-top: 57px;
  padding-bottom: 70px;
}

#join {
  color: #283891;
}

.get-started-btn {
  background-color: #283891;
  padding: 16px 24px;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  border: none;
  outline: 2px solid #283891 !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  margin-top: 1.5rem;
  transition: all 200ms;
}
.get-started-btn:hover {
  background-color: transparent;
  color: #283891;
}
.get-started-btn:focus {
  outline: 2px solid #283891;
  border: none;
}
@media only screen and (max-width: 600px) {
  .joinHeader {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #283891;
    margin-bottom: 0 !important;
  }
  .joinPara {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */
    margin-bottom: 10px !important;
    text-align: center;
    margin-top: 10px !important;
  }
}

#mainContainer {
  margin: auto !important;
}

@media (max-width: 678px) {
  #mainContainer {
    margin-left: -25px !important;
    margin-right: -25px !important;
    border-radius: 0 !important;
  }
}
@media only screen and (width <=700px) {
  #mobile_style {
    width: 100% !important;
    height: fit-content;
    border-radius: 0px !important;
    margin: 48px 0 !important;
  }
  #mobile_style_container {
    padding: 0px !important;
  }
  .mobile_style_btn {
    background: #283891;
    border-radius: 6px;
    height: 2rem;
    padding: 0.8em 1.4em !important;
  }
  #container {
    padding: 40px 15px !important;
  }
  .get-started-btn {
    width: 110px;
    height: 36px;
    padding: 12px 20px;
    background: #283891;
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;
  }
  .get-started-btn:hover {
    background-color: #283891;
    color: #ffffff;
  }
}
